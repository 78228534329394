import React, { FC, ReactNode, useMemo } from 'react';
import { Box, Button, Card, Modal, Stack, Typography, ClickAwayListener, Divider } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  outline: 0,
  transform: 'translate(-50%, -50%)',
  zIndex: 800,
};

export interface WuiModalProps {
  title?: string;
  /** Opens the modal
   * @default false
   */
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  onDone?: () => void;
  /**
   * Overrides the existing Done, Cancel buttons
   */
  actionButtons?: ReactNode;
  /**
   * Done Label
   * @default "global.done"
   */
  doneLabel?: string;
  /**
   * Cancel Label
   * @default "global.cancel"
   */
  cancelLabel?: string;
  /** Shows the button footer row
   * @default true
   */
  showButtons?: boolean;
  /** Shows close icon
   * @default true
   */
  showCloseIcon?: boolean;
  /**
   * Sets the modal size
   * @default "medium"
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Sets the Done button target form
   * @default "form"
   */
  formId?: string;
  /**
   * Closes the modal if clicked outside
   */
  closeOnClickAway?: boolean;
}

export const WuiModal: FC<WuiModalProps> = ({
  title,
  open,
  showButtons = true,
  showCloseIcon = true,
  closeOnClickAway = true,
  doneLabel = 'global.done',
  cancelLabel = 'global.cancel',
  onClose,
  onDone,
  formId = 'form',
  size = 'medium',
  actionButtons,
  children,
}) => {
  const width = useMemo(() => {
    switch (size) {
      case 'small':
        return '450px';
      case 'medium':
        return '650px';
      case 'large':
        return '800px';
      default:
        return '650px';
    }
  }, [size]);

  const intl = useIntl();
  return (
    <Modal
      open={open}
      sx={{ ...modalStyle, width }}
      onClose={onClose}
      keepMounted={false}
      hideBackdrop
      disableEnforceFocus
    >
      <ClickAwayListener
        onClickAway={() => {
          closeOnClickAway ? onClose() : () => {};
        }}
      >
        <Card
          elevation={5}
          sx={{
            padding: '12px',
            paddingTop: showCloseIcon && !title ? '32px' : '12px',
          }}
        >
          {showCloseIcon && (
            <Box
              onClick={onClose}
              sx={(theme) => ({
                position: 'absolute',
                cursor: 'pointer',
                top: '12px',
                right: '12px',
                color: theme.palette.grey[500],
                '&:hover': {
                  color: theme.palette.common.black,
                },
              })}
            >
              <CloseIcon fontSize="small" />
            </Box>
          )}
          {title && (
            <Typography variant="h6" mb={2}>
              {intl.formatMessage({ id: title })}
            </Typography>
          )}
          <Box
            sx={{
              maxHeight: '50vh',
              overflow: 'auto',
              p: 1,
            }}
          >
            {children}
          </Box>
          {showButtons && (
            <>
              <Divider sx={{ marginTop: 1 }} />
              <Stack direction="row" justifyContent="right" spacing="8px" sx={{ marginTop: '12px' }}>
                {actionButtons || (
                  <>
                    <Button onClick={onClose} startIcon={<CloseIcon />}>
                      {intl.formatMessage({ id: cancelLabel })}
                    </Button>
                    <Button variant="contained" onClick={onDone} startIcon={<CheckIcon />} form={formId} type="submit">
                      {intl.formatMessage({ id: doneLabel })}
                    </Button>
                  </>
                )}
              </Stack>
            </>
          )}
        </Card>
      </ClickAwayListener>
    </Modal>
  );
};
