import { UserGET } from '@workerbase/types/api/users';
import { WorkinstructionGET } from '@workerbase/types/api/workinstructions';
import { CategoryOption, TabActiveColorType } from '@workerbase/types/ListConfig';
import { StepConfigTypes } from '@workerbase/types/project/Project';
import { TaskCategoryKeys } from '@workerbase/types/TaskCategoryKeys';
import { User } from 'services/types/User';
import { Workinstruction } from 'services/types/Workinstruction';

export enum TaskStatus {
  OPEN = 'open',
  INPROGRESS = 'inprogress',
  DONE = 'done',
  SUSPENDED = 'suspended',
}

// From API
export interface TaskGET {
  _id: string;
  meta: {
    createdAt?: Date;
    updatedAt?: Date;
  };
  priority: number;
  status: TaskStatus;
  source: 'rule' | 'app' | 'api' | 'newTask' | null;
  title: string;
  project: string;
  description: string;
  done: boolean;
  externalId: string;
  headline: string;
  delegationLevel: number;
  payload: {
    workinstructions?: Partial<WorkinstructionGET>;
    device?; // TODO: put type here. String ?
    user?: UserGET;
    rule?; // TODO: write role object
    // there are more fields here
  };
  ruleId?: string;
  user?: string | Partial<UserGET>;
}

export interface TaskMQTT {
  _id: string;
  meta: {
    createdAt: Date;
    updatedAt: Date;
  };
  priority: number;
  status: TaskStatus;
  source: 'rule' | 'app' | 'api' | 'newTask' | null;
  title: string;
  project: string;
  description: string;
  done: boolean;
  externalId: string;
  headline: string;
  delegationLevel: number;
  payload: {
    workinstructions?: WorkinstructionGET;
    device?;
    user?: UserGET;
    rule?;
  };
  user: string;
  deleted: boolean;
}

export interface Task {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  projectId: string;
  priority: number;
  status: TaskStatus;
  title: string;
  description: string;
  done: boolean;
  headline: string;
  delegationLevel: number;
  payload: {
    workinstruction?: Partial<Workinstruction>;
  };
  userId?: string;
  user?: Partial<User>;
  additionalProperties: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [path: string]: any;
  };
  justChanged?: boolean;
  ruleId?: string;
}

export const CategoryOptions: Record<TaskCategoryKeys, CategoryOption[]> = {
  [TaskCategoryKeys.STATUS]: [
    {
      key: TaskStatus.SUSPENDED,
      titleTranslationKey: 'tasks.status.suspended',
      count: 0,
      activeColor: TabActiveColorType.DANGER,
    },
    {
      key: TaskStatus.OPEN,
      titleTranslationKey: 'tasks.status.open',
      count: 0,
      activeColor: TabActiveColorType.DEFAULT,
    },
    {
      key: TaskStatus.INPROGRESS,
      titleTranslationKey: 'tasks.status.inprogress',
      count: 0,
      activeColor: TabActiveColorType.DEFAULT,
    },
    {
      key: TaskStatus.DONE,
      titleTranslationKey: 'tasks.status.done',
      count: 0,
      activeColor: TabActiveColorType.GREEN,
    },
  ],
  [TaskCategoryKeys.WORKINSTRUCTION]: [],
  [TaskCategoryKeys.NO_KEY]: [],
};

export interface TaskField {
  name: string;
  path?: string;
  sortable: boolean;
  type?: StepConfigTypes;
}

export interface TaskHistory {
  meta: {
    date: string;
    user: string;
  };
  payload: Record<string, unknown>;
  status?: TaskStatus;
  delegationLevel?;
  ruleId?: string;
  reason?;
  user?: string;
  step?: {
    id: string;
    value;
    title: string;
  };
}
