import React from 'react';

import styles from './PageLoader.module.scss';

const PageLoader: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.loader}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default PageLoader;
