import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { getSkills, getSkillById, deleteSkillById, createSkill, updateSkillById } from 'services/networking/skills';
import { PaginationMeta } from '@workerbase/types/Response';
import { Skill } from 'services/types/Skill';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';

import { Routes } from '../../routes';
import { handleRequestError } from '../common';

import { getListConfigs } from './selectors';
import {
  getSkillsSuccess,
  getSkillsError,
  getSkillByIdSuccess,
  getSkillByIdError,
  SkillsActions,
  deleteSkillByIdSuccess,
  deleteSkillByIdError,
  CreateSkillRequestAction,
  UpdateSkillByIdRequestAction,
  DeleteSkillByIdRequestAction,
} from './actions';

export function* getSkillsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const response = yield call(
      getSkills,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
      listConfigs.filtering.searchTerms,
    );

    const skills: Skill[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getSkillsSuccess(skills, meta));
  } catch (error) {
    yield put(getSkillsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getSkillByIdRequestSaga(action) {
  try {
    const skill: Skill = yield call(getSkillById, action.payload.skillId);

    yield put(getSkillByIdSuccess(skill));
  } catch (error) {
    yield put(getSkillByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createSkillRequestSaga(action: CreateSkillRequestAction) {
  try {
    const skill: Skill = yield call(createSkill, action.payload.skill);
    yield put(getSkillByIdSuccess(skill));
    yield put(push(Routes.Skills));
    yield call(toast.success, 'Skill created');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateSkillByIdRequestSaga(action: UpdateSkillByIdRequestAction) {
  try {
    const skill: Skill = yield call(updateSkillById, action.payload.skillId, action.payload.skill);
    yield put(getSkillByIdSuccess(skill));
    yield put(push(Routes.Skills));
    yield call(toast.success, 'Skill updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* deleteSkillByIdRequestSaga(action: DeleteSkillByIdRequestAction) {
  try {
    yield call(deleteSkillById, action.payload.skillId);
    yield call(toast.success, 'Skill deleted');
    yield put(deleteSkillByIdSuccess(action.payload.skillId));
  } catch (error) {
    yield put(deleteSkillByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export default function* skillsSagas() {
  yield takeLatest(SkillsActions.GET_SKILLS_REQUEST, getSkillsRequestSaga);
  yield takeEvery(SkillsActions.GET_SKILL_BY_ID_REQUEST, getSkillByIdRequestSaga);
  yield takeLatest(SkillsActions.CREATE_SKILL_REQUEST, createSkillRequestSaga);
  yield takeLatest(SkillsActions.DELETE_SKILL_BY_ID_REQUEST, deleteSkillByIdRequestSaga);
  yield takeLatest(SkillsActions.UPDATE_SKILL_BY_ID_REQUEST, updateSkillByIdRequestSaga);
  yield takeLatest(SkillsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(SkillsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
