import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsOptional, IsString, ValidateNested } from 'class-validator';
import { TextStyles } from './Styles';

@Schema({ _id: false })
export class StepStyles {
  @Prop()
  @IsString()
  @IsOptional()
  backgroundColor?: string;

  @Prop({ type: SchemaFactory.createForClass(TextStyles) })
  @IsOptional()
  @ValidateNested()
  @Type(() => TextStyles)
  title?: TextStyles;
}
