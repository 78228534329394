import { call, put, takeLatest, select, takeEvery, debounce } from 'redux-saga/effects';

import {
  getUser as getUserById,
  updateTestRunDataByFunction,
  getTestRunDataByFunction,
} from 'services/networking/users';
import { LoggedUser, User } from 'services/types/User';
import { getListConfigSaga, updateListConfigSaga } from '@redux/common/ListConfig/sagas';

import { getUser } from '@redux/Login/selectors';
import { handleRequestError } from '../common';

import {
  getUserByIdSuccess,
  getUserByIdError,
  UsersActions,
  getFunctionTestRunDataSuccess,
  updateFunctionTestRunDataSuccess,
} from './actions';

export function* getUserByIdRequestSaga(action) {
  try {
    const user: User = yield call(getUserById, action.payload.userId);
    yield put(getUserByIdSuccess(user));
  } catch (error) {
    yield put(getUserByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getFunctionTestRunData(action) {
  const loggedUser: LoggedUser | null = yield select(getUser);
  if (!loggedUser) {
    return;
  }
  try {
    const result = yield call(getTestRunDataByFunction, loggedUser.id, action.payload.functionId);
    yield put(getFunctionTestRunDataSuccess(action.payload.functionId, result.testRunData));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateFunctionTestRunData(action) {
  const loggedUser: LoggedUser | null = yield select(getUser);
  if (!loggedUser) {
    return;
  }
  try {
    yield call(updateTestRunDataByFunction, loggedUser.id, action.payload.functionId, action.payload.testRunData);
    yield put(updateFunctionTestRunDataSuccess(action.payload.functionId, action.payload.testRunData));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* usersSagas() {
  yield takeEvery(UsersActions.GET_USER_BY_ID_REQUEST, getUserByIdRequestSaga);
  yield takeLatest(UsersActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(UsersActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
  yield takeLatest(UsersActions.GET_FUNCTION_TEST_RUN_DATA_REQUEST, getFunctionTestRunData);
  yield debounce(2000, UsersActions.UPDATE_FUNCTION_TEST_RUN_DATA_REQUEST, updateFunctionTestRunData);
  yield takeLatest(UsersActions.GET_LIST_CONFIG_REQUEST, getListConfigSaga);
}
