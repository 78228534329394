import { Prop, Schema } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsNumber, ValidateIf, ValidateNested } from 'class-validator';
import { StepAction, StepActionBase, StepActionBaseSchema, StepActionDiscriminator } from './StepAction';

@Schema({ _id: false })
export class StepTimeout {
  @Prop()
  @IsNumber()
  seconds: number;

  @Prop({ type: StepActionBaseSchema, discriminators: StepActionDiscriminator })
  @ValidateNested()
  @Type(() => StepActionBase)
  @ValidateIf((object, value) => value !== null)
  action: StepAction | null;
}
