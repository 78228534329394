import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Drawer, Skeleton, Stack, Typography } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { NoteBody, NoteResponse } from '@workerbase/types/api/notes';
import { WuiModal } from '@uiKit';

import { useNotesContext } from 'providers/NotesProvider';
import { NoteCard, NoteForm } from './components';
import { useNotes } from './useNotes';

const DRAWER_WIDTH = 320;

export const Notes: FC = () => {
  const { resource, setResource } = useNotesContext();

  useEffect(
    () => () => {
      setResource(undefined);
    },
    [],
  );
  if (!resource) {
    throw new Error('Notes should not be called without a connectedResource');
  }

  const { notes, setNotes, getNotes, createNote, updateNote, deleteNote } = useNotes(resource);

  const intl = useIntl();

  const [openNotesDrawer, setOpenNotesDrawer] = useState(false);
  const [openNoteForm, setOpenNoteForm] = useState(false);
  const [activeNote, setActiveNote] = useState<NoteResponse>();

  const handleOpenNotes = () => {
    setOpenNotesDrawer(true);
    getNotes();
  };

  const handleCloseNotes = () => {
    setOpenNotesDrawer(false);
    setNotes(undefined);
  };

  const drawerWidth = useMemo(() => (openNotesDrawer ? DRAWER_WIDTH : 0), [openNotesDrawer]);

  const handleOpenNoteForm = (note?: NoteResponse) => {
    if (note) {
      setActiveNote(note);
    }
    setOpenNoteForm(true);
  };

  const handleCloseNoteForm = () => {
    setActiveNote(undefined);
    setOpenNoteForm(false);
  };

  // Create + Update Note
  const saveNoteRequest = (note: Pick<NoteBody, 'media' | 'text'>) => {
    if (activeNote) {
      updateNote(activeNote._id, { ...note, ...resource });
    } else {
      createNote({ ...note, ...resource });
    }
    setActiveNote(undefined);
    setOpenNoteForm(false);
  };

  const deleteNoteRequest = (noteId: string) => {
    deleteNote(noteId);
  };

  return (
    <>
      <Button
        onClick={handleOpenNotes}
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: '24px',
          left: '24px',
          borderRadius: '24px',
          zIndex: 100,
          boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
        }}
      >
        {intl.formatMessage({ id: 'header.notes' })}
      </Button>
      {/* TODO: Use WuiDrawer here to have SSoT and also have both entering and leaving animation  */}
      <Drawer
        open={openNotesDrawer}
        keepMounted={false}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            position: 'relative',
            zIndex: 150,
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        variant="persistent"
      >
        <Box sx={{ padding: '12px', backgroundColor: '#FFFFFF', position: 'sticky', top: 0, zIndex: 50 }}>
          <Typography variant="h6">{intl.formatMessage({ id: 'header.notes' })}</Typography>
          <Box
            onClick={handleCloseNotes}
            sx={(theme) => ({
              position: 'absolute',
              cursor: 'pointer',
              top: '12px',
              right: '12px',
              color: theme.palette.grey[500],
              '&:hover': {
                color: theme.palette.common.black,
              },
            })}
          >
            <CloseIcon fontSize="medium" />
          </Box>
        </Box>
        <Stack direction="column" sx={{ padding: '0 16px 12px' }} spacing="12px">
          {notes ? (
            notes.map((note) => (
              <NoteCard
                note={note}
                onEdit={() => handleOpenNoteForm(note)}
                onDelete={() => deleteNoteRequest(note._id)}
                key={note._id}
              />
            ))
          ) : (
            <>
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenNoteForm(undefined)}
            startIcon={<AddIcon />}
          >
            {intl.formatMessage({ id: 'notes.add' })}
          </Button>
        </Stack>
        <WuiModal
          open={openNoteForm}
          onClose={handleCloseNoteForm}
          size="small"
          title={activeNote ? 'note.edit' : 'note.add'}
          showButtons={false}
          closeOnClickAway={false}
        >
          <NoteForm onCancel={handleCloseNoteForm} onSave={saveNoteRequest} note={activeNote} />
        </WuiModal>
      </Drawer>
    </>
  );
};
