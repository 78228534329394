import { Prop, Schema } from '@nestjs/mongoose';
import { IsNumber, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputableStep } from './base/InputableStep';

@Schema()
export class InputLocation extends InputableStep {
  type: StepTypes.INPUT_LOCATION;

  @Prop()
  @IsString()
  description: string;

  @Prop({ trim: true, required: true })
  @IsNumber()
  minAccuracyInMeters: number;
}
