import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { ImageStyles } from '../../Styles';
import { StepStyles } from '../../StepStyles';

@Schema({ _id: false })
export class InfoPhotoStyles extends StepStyles {
  @Prop({ type: SchemaFactory.createForClass(ImageStyles) })
  @ValidateNested()
  @Type(() => ImageStyles)
  image: ImageStyles;
}
