import { Prop, Schema } from '@nestjs/mongoose';
import { IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputNumberBase } from './base/InputNumberBase';

@Schema()
export class InputNumberPicker extends InputNumberBase {
  type: StepTypes.INPUT_NUMBER_PICKER;

  @Prop()
  @IsString()
  description: string;
}
