import { Prop, Schema } from '@nestjs/mongoose';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { InputableStep } from './InputableStep';

@Schema()
export class InputNumberBase extends InputableStep {
  @Prop()
  @IsNumber()
  integerDigits: number;

  @Prop()
  @IsNumber()
  decimalDigits: number;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  /**
   * string representation of number value (eg '12.35') or a variable name (eg. '$varName)
   * @example '12.35'
   * @example '$numberVariable'
   */
  defaultValueDecimal?: string;
}
