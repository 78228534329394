import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsString, ValidateNested } from 'class-validator';
import { LayoutOrientation } from '../../../LayoutOrientation';
import { ClassValidatorOptions } from '../../utils/ClassValidatorOptions';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InfoPhotoStyles } from './base/InfoPhotoStyles';
import { StepBase } from './base/StepBase';

@Schema()
export class LayoutAssemblyPicture extends StepBase {
  type: StepTypes.LAYOUT_ASSEMBLY_PICTURE;

  @Prop()
  @IsString()
  description: string;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop({ type: String, enum: Object.values(LayoutOrientation) })
  @IsEnum(LayoutOrientation, ClassValidatorOptions.getEnumErrorMessageOptions(LayoutOrientation))
  orientation: LayoutOrientation;
}
