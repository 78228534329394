import { Languages } from '@workerbase/types/Settings';
import { AnyAction } from 'redux';
import { SettingsActions } from './actions';

export type SettingsState = Readonly<{
  languages: Languages[];
}>;

export const initialState: SettingsState = {
  languages: [],
};

const reducer = (state: SettingsState = initialState, action: AnyAction): SettingsState => {
  switch (action.type) {
    case SettingsActions.GET_LANGUAGES_SUCCESS:
    case SettingsActions.UPDATE_LANGUAGES_SUCCESS: {
      const languages = action.payload.languages;
      return {
        ...state,
        languages,
      };
    }
    default:
      return state;
  }
};

export default reducer;
