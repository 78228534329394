import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import createRootReducer from './reducers';
import rootSaga from './sagas';

export const history = createBrowserHistory<{ fromUri?: string }>();
const sagaMiddleware = createSagaMiddleware();

export const GLOBAL_STATE_LOCAL_STORAGE_KEY = 'state';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(GLOBAL_STATE_LOCAL_STORAGE_KEY, serializedState);
  } catch (e) {
    console.error(`Could not save redux state to local storage: ${e}`);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(GLOBAL_STATE_LOCAL_STORAGE_KEY);
    if (!serializedState || serializedState === null) {
      return undefined;
    }
    const loadedState = JSON.parse(serializedState);
    // don't store mqtt state in local storage
    return { ...loadedState, mqtt: undefined };
  } catch (e) {
    console.error(`Could not load redux state from local storage: ${e}`);
    return undefined;
  }
}

// Initial state here is used to inject custom state for testing
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code to be refactored
export default function configureStore(initialState: any = undefined) {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const persistedState = initialState || loadFromLocalStorage();
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    persistedState, // initial state
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
      sentryReduxEnhancer,
    ),
  );

  sagaMiddleware.run(rootSaga);

  store.subscribe(() => saveToLocalStorage(store.getState()));

  return store;
}
