import React, { ReactNode } from 'react';

import styles from './Sidebar.module.scss';

export enum SidebarPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface Props {
  position?: SidebarPosition;
  hasBigPadding?: boolean;
  children?: ReactNode;
}

/**
 * Simple UI Component used for creating sidebars. Should be used in a flex div.
 */
const Sidebar: React.FC<Props> = ({ position = SidebarPosition.LEFT, children, hasBigPadding = false }) => (
  <div
    className={`${styles.sidebar} ${hasBigPadding ? styles.bigPadding : ''} ${
      position === SidebarPosition.RIGHT ? styles.right : ''
    }`}
  >
    {children}
  </div>
);

export default Sidebar;
