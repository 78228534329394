import { PaginationMeta } from '@workerbase/types/Response';
import { ConnectorLog } from 'services/types/Connector';

import { BaseAction } from '../common/actions';

import { updateSortingFactory, updatePaginationFactory, updateListConfigFactory } from '../common/ListConfig/actions';

export enum ConnectorLogsActions {
  GET_CONNECTORLOGS_REQUEST = '@@connectorLogs/GET_CONNECTORLOGS_REQUEST',
  GET_CONNECTORLOGS_SUCCESS = '@@connectorLogs/GET_CONNECTORLOGS_SUCCESS',
  GET_CONNECTORLOGS_FAILURE = '@@connectorLogs/GET_CONNECTORLOGS_FAILURE',
  UPDATE_PAGINATION = '@@connectorLogs/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@connectorLogs/UPDATE_SORTING',
  UPDATE_LIST_CONFIG = '@@connectorLogs/UPDATE_LIST_CONFIG',
}

export const getConnectorLogsRequestAction = (connectorId: string, forceLoading = false): BaseAction => ({
  type: ConnectorLogsActions.GET_CONNECTORLOGS_REQUEST,
  payload: { connectorId, forceLoading },
});

export const getConnectorLogsSuccess = (data: ConnectorLog[], meta: PaginationMeta): BaseAction => ({
  type: ConnectorLogsActions.GET_CONNECTORLOGS_SUCCESS,
  payload: {
    connectorLogs: data,
    meta,
  },
});

export const getConnectorLogsError = (errorMessage: string): BaseAction => ({
  type: ConnectorLogsActions.GET_CONNECTORLOGS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const updatePagination = updatePaginationFactory(ConnectorLogsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(ConnectorLogsActions.UPDATE_SORTING);
export const updateListConfig = updateListConfigFactory(ConnectorLogsActions.UPDATE_LIST_CONFIG);
