/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import mongoose from 'mongoose';
import { Step } from '../Step';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InfoAudio } from './InfoAudio';
import { InfoEmpty } from './InfoEmpty';
import { InfoLoading } from './InfoLoading';
import { InfoLocationDirection } from './InfoLocationDirection';
import { InfoPdf } from './InfoPdf';
import { InfoPhoto } from './InfoPhoto';
import { InfoQrCode } from './InfoQrCode';
import { InfoStatus } from './InfoStatus';
import { InfoTask } from './InfoTask';
import { InfoText } from './InfoText';
import { InfoTextLarge } from './InfoTextLarge';
import { InfoVideo } from './InfoVideo';
import { InfoWebview } from './InfoWebview';
import { InputAudio } from './InputAudio';
import { InputBarcode } from './InputBarcode';
import { InputButtonList } from './InputButtonList';
import { InputCheckboxList } from './InputCheckboxList';
import { InputIndexList } from './InputIndexList';
import { InputLocation } from './InputLocation';
import { InputNoiseLevel } from './InputNoiseLevel';
import { InputNumber } from './InputNumber';
import { InputNumberPicker } from './InputNumberPicker';
import { InputPhoto } from './InputPhoto';
import { InputStepMenu } from './InputStepMenu';
import { InputText } from './InputText';
import { InputTileList } from './InputTileList';
import { InputValueList } from './InputValueList';
import { InputVideo } from './InputVideo';
import { StepBase } from './base/StepBase';
import { InputableStep } from './base';
import { FlexDirection } from './FlexDirection.enum';
import { FlexChildType } from './FlexChildType.enum';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

@Schema({ _id: false, discriminatorKey: 'type', overwriteModels: true })
class FlexChildBase {
  @Prop()
  flex: number;

  @Prop({ type: String, enum: Object.values(FlexChildType), required: true, $skipDiscriminatorCheck: true })
  type: FlexChildType;
}
// Should include only info and input steps
const FlexStepDiscriminators: {
  [stepType in Exclude<
    StepTypes,
    | StepTypes.LAYOUT_FLEX
    | StepTypes.LAYOUT_ASSEMBLY_PICTURE
    | StepTypes.LAYOUT_ASSEMBLY_VIDEO
    | StepTypes.LAYOUT_ASSEMBLY_DETAIL
    | StepTypes.LAYOUT_SCREW_FITTING
    | StepTypes.MODULE
  >]: mongoose.Schema;
} = {
  [StepTypes.INFO_WEBVIEW]: SchemaFactory.createForClass(InfoWebview),
  [StepTypes.INFO_TEXT]: SchemaFactory.createForClass(InfoText),
  [StepTypes.INFO_TEXT_LARGE]: SchemaFactory.createForClass(InfoTextLarge),
  [StepTypes.INFO_AUDIO]: SchemaFactory.createForClass(InfoAudio),
  [StepTypes.INFO_VIDEO]: SchemaFactory.createForClass(InfoVideo),
  [StepTypes.INFO_PHOTO]: SchemaFactory.createForClass(InfoPhoto),
  [StepTypes.INFO_PDF]: SchemaFactory.createForClass(InfoPdf),
  [StepTypes.INFO_LOADING]: SchemaFactory.createForClass(InfoLoading),
  [StepTypes.INFO_STATUS]: SchemaFactory.createForClass(InfoStatus),
  [StepTypes.INFO_QRCODE]: SchemaFactory.createForClass(InfoQrCode),
  [StepTypes.INFO_EMPTY]: SchemaFactory.createForClass(InfoEmpty),
  [StepTypes.INFO_TASK]: SchemaFactory.createForClass(InfoTask),
  [StepTypes.INPUT_AUDIO]: SchemaFactory.createForClass(InputAudio),
  [StepTypes.INPUT_TEXT]: SchemaFactory.createForClass(InputText),
  [StepTypes.INPUT_LOCATION]: SchemaFactory.createForClass(InputLocation),
  [StepTypes.INFO_LOCATION_DIRECTION]: SchemaFactory.createForClass(InfoLocationDirection),
  [StepTypes.INPUT_BARCODE]: SchemaFactory.createForClass(InputBarcode),
  [StepTypes.INPUT_NUMBER]: SchemaFactory.createForClass(InputNumber),
  [StepTypes.INPUT_NUMBER_PICKER]: SchemaFactory.createForClass(InputNumberPicker),
  [StepTypes.INPUT_PHOTO]: SchemaFactory.createForClass(InputPhoto),
  [StepTypes.INPUT_VIDEO]: SchemaFactory.createForClass(InputVideo),
  [StepTypes.INPUT_VALUE_LIST]: SchemaFactory.createForClass(InputValueList),
  [StepTypes.INPUT_CHECKBOX_LIST]: SchemaFactory.createForClass(InputCheckboxList),
  [StepTypes.INPUT_INDEX_LIST]: SchemaFactory.createForClass(InputIndexList),
  [StepTypes.INPUT_TILE_LIST]: SchemaFactory.createForClass(InputTileList),
  [StepTypes.INPUT_BUTTON_LIST]: SchemaFactory.createForClass(InputButtonList),
  [StepTypes.INPUT_STEP_MENU]: SchemaFactory.createForClass(InputStepMenu),
  [StepTypes.INPUT_NOISE_LEVEL]: SchemaFactory.createForClass(InputNoiseLevel),
};

@Schema({ _id: false })
export class FlexChildStep extends FlexChildBase {
  type: FlexChildType.STEP;

  @Prop({
    type: StepBase,
    discriminators: FlexStepDiscriminators,
  })
  step: Exclude<Step, LayoutFlexStep>; // Only info and input steps
}

@Schema({ _id: false })
export class FlexChildFlex extends FlexChildBase {
  type: FlexChildType.FLEX;

  @Prop({ type: String, enum: Object.values(FlexDirection) })
  direction: FlexDirection;

  @Prop([
    { type: Object }, // FlexChild discriminators won't work here because of recursive dependency
  ])
  children: FlexChild[];
}

export type FlexChild = FlexChildStep | FlexChildFlex;

@Schema()
export class LayoutFlexStep extends InputableStep {
  type: StepTypes.LAYOUT_FLEX;

  @Prop({ type: String, enum: Object.values(FlexDirection) })
  direction: FlexDirection;

  @Prop([
    { type: Object }, // FlexChild discriminators won't work here because of recursive dependency
  ])
  children: FlexChild[];
}
