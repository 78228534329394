import { ProjectLog } from 'services/types/ProjectLog';

import { RootState } from '../types';

export const getProjectLogsOnCurrentPage = (store: RootState) =>
  store.projectLogs.currentListItemsIds.map((id) => store.projectLogs.logsById[id]);

export const getProjectLogWithId = (store: RootState, logId: string): ProjectLog | undefined =>
  store.projectLogs.logsById[logId];

export const getListConfigs = (store: RootState) => store.projectLogs.listConfigs;
