import { Tooltip } from '@mui/material';
import { WuiMenuList, WuiMenuListCustomProps } from '@uiKit/WuiMenuList/WuiMenuList';
import React, { FC, ReactNode, useState } from 'react';

interface WuiMenuOnHoverProps extends WuiMenuListCustomProps {
  children: ReactNode;
}

/** 
 TODO: Component is not stable if items are not links (working example in HeaderNavigation.tsx), find another more stable solutions that doesn't ignore clicks on the menu items randomly.
 @deprecated
*/
export const WuiMenuOnHover: FC<WuiMenuOnHoverProps> = ({ children, items, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!children) {
    return null;
  }

  return (
    <Tooltip
      open={open}
      title={<WuiMenuList onItemClicked={handleClose} items={items} {...props} />}
      onClose={handleClose}
      onOpen={handleOpen}
      slotProps={{
        tooltip: { sx: { display: 'contents' } },
        popper: {
          keepMounted: true,
        },
      }}
      placement="bottom-start"
    >
      {React.isValidElement(children) ? children : <span>{children}</span>}
    </Tooltip>
  );
};
