import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DateFormatter {
  export enum Formats {
    DATETIME = 'YYYY-MM-DD HH:mm:ss',
    DATE = 'YYYY-MM-DD',
    TIME = 'HH:mm',
    MONTH_OF_YEAR = 'YYYY MMM',
  }

  export const format = (value: string | number | Date, format: DateFormatter.Formats) => moment(value).format(format);
}
