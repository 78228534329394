import { ListArgs, GraphqlArgsTypeNames } from 'services/types/GraphQL';

const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1);

type GraphqlQueryArgs = 'page' | 'perpage' | 'sort' | 'order' | 'group' | 'textSearch' | 'filter' | 'preciseCount';

const graphQLArgsToTypeName = (modelname: string, argName: string): string => {
  const qraphQlQueryArgs: Record<GraphqlQueryArgs, string> = {
    page: GraphqlArgsTypeNames.INT,
    perpage: GraphqlArgsTypeNames.INT,
    sort: GraphqlArgsTypeNames.STRING,
    order: GraphqlArgsTypeNames.SORT_ORDER,
    group: GraphqlArgsTypeNames.GROUP,
    textSearch: GraphqlArgsTypeNames.STRING,
    filter: `Workerbase${capitalize(modelname)}${GraphqlArgsTypeNames.FILTER}`,
    preciseCount: GraphqlArgsTypeNames.BOOLEAN,
  };

  return qraphQlQueryArgs[argName];
};

export const argsObjectToGraphqlQuery = (args: ListArgs) =>
  Object.keys(args)
    .filter((key) => args[key] != null)
    .map((key) => `${key}:$${key}`)
    .join(', ');

export const argsObjectToVartiableDefinition = (modelname: string, args: ListArgs) =>
  Object.keys(args)
    .filter((key) => args[key] != null)
    .map((key) => `$${key}:${graphQLArgsToTypeName(modelname, key)}`)
    .join(', ');
