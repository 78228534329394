import { cloneDeep } from 'lodash';

import { StepButton } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';
import { NextStepPosition } from '@workerbase/types/NextStepPosition';
import { StepActionNextStep } from '@workerbase/types/MongoDB/Workinstruction/StepAction';

import {
  ButtonType,
  getButtonByType,
  isDefaultSpecificStepButtonIcon,
} from 'components/WorkinstructionForm/utils/steps';
import { WorkinstructionStep } from 'services/types/Workinstruction';

export const calculateNextStepPosition = (
  currentStepId: string,
  referencedStepId: string,
  allSteps: WorkinstructionStep[],
): NextStepPosition => {
  const referencedStepIndex = allSteps.findIndex((step) => referencedStepId === step.id);
  const currentStepIndex = allSteps.findIndex((step) => currentStepId === step.id);
  return currentStepIndex > referencedStepIndex ? NextStepPosition.BEHIND : NextStepPosition.AHEAD;
};

export const updateSpecificStepButtonIconAndAction = (
  specificButtonToUpdate: StepButton,
  parentStepId: string,
  steps: WorkinstructionStep[],
) => {
  const buttonCopy = cloneDeep(specificButtonToUpdate);
  const {
    action: { editorContext },
    icon,
  } = getButtonByType(ButtonType.SPECIFIC_STEP, (buttonCopy.action as StepActionNextStep).stepId, parentStepId, steps);

  (buttonCopy.action as StepActionNextStep).editorContext = editorContext;

  if (isDefaultSpecificStepButtonIcon(buttonCopy.icon)) {
    buttonCopy.icon = icon;
  }

  return buttonCopy;
};
