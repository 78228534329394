import { cloneDeep, isArray, isNull, isNumber, isString } from 'lodash';
import { toast } from 'react-toastify';
import { StepActionTypes } from '@workerbase/types/StepActionTypes';
import { generateButtonStylesForIconType, StepButton } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';
import { StepsIconTypes } from '@workerbase/types/WorkinstructionStep/StepsIconTypes';
import { NavigationBehaviour } from '@workerbase/types/NavigationBehaviour';
import { StepActionNextStep } from '@workerbase/types/MongoDB/Workinstruction/StepAction';
import {
  calculateNextStepPosition,
  updateSpecificStepButtonIconAndAction,
} from 'components/WorkinstructionForm/utils/specificStepHelpers';
import { NextStepPosition } from '@workerbase/types/NextStepPosition';
import { WorkinstructionStep } from 'services/types/Workinstruction';
import { isRendered } from '@workerbase/types/domain/workinstruction/step/guards';
import { WiWorkflowType } from '../types';

interface BasicUpdateWiStepButtonMetadata {
  steps: WorkinstructionStep[];
  workflowType: WiWorkflowType;
  reorderStepMetadata: {
    fromIndex: number | null; // null in case step was created
    toIndex: number;
  };
}

interface UpdateWiStepSpecificButtonMetadata extends BasicUpdateWiStepButtonMetadata {
  buttonToUpdate: StepButton;
  indexOfStepToUpdate: number;
}

interface UpdateWiStepButtonsMetadata extends BasicUpdateWiStepButtonMetadata {
  stepIdToUpdate: string;
}

export const getStepIdWithDirection =
  (direction: number) =>
  (stepId: string, steps: WorkinstructionStep[]): string | undefined => {
    const currentStepIndex = steps.findIndex((step) => step.id === stepId);

    if (currentStepIndex === -1) {
      return undefined;
    }

    return steps[currentStepIndex + direction]?.id;
  };

export enum ButtonType {
  FINISH = 'finish',
  SUSPEND = 'suspend',
  CLOSE = 'close',
  NEXT_STEP = 'continue',
  BACK = 'back',
  SPECIFIC_STEP = 'specific_step',
}

export function getButtonByType(
  buttonType: ButtonType.FINISH | ButtonType.SUSPEND | ButtonType.CLOSE,
): Omit<StepButton, 'index'>;
export function getButtonByType(
  buttonType: ButtonType.NEXT_STEP | ButtonType.BACK,
  id: string,
): Omit<StepButton, 'index'>;
export function getButtonByType(
  buttonType: ButtonType.SPECIFIC_STEP,
  id: string,
  currentStepId?: string,
  steps?: WorkinstructionStep[],
): Omit<StepButton & { action: StepActionNextStep }, 'index'>;
export function getButtonByType(
  buttonType: ButtonType,
  id?: string,
  currentStepId?: string,
  steps?: WorkinstructionStep[],
): Omit<StepButton, 'index'> | Omit<StepButton & { action: StepActionNextStep }, 'index'> {
  if (buttonType === ButtonType.FINISH) {
    return {
      text: 'Finish',
      action: { type: StepActionTypes.FINISH },
      icon: StepsIconTypes.CONFIRM,
      styles: generateButtonStylesForIconType(StepsIconTypes.CONFIRM),
    };
  }
  if (buttonType === ButtonType.SUSPEND) {
    return {
      text: 'Suspend',
      action: { type: StepActionTypes.SUSPEND },
      icon: StepsIconTypes.CLOSE,
      styles: generateButtonStylesForIconType(StepsIconTypes.CLOSE),
    };
  }
  if (buttonType === ButtonType.CLOSE) {
    return {
      text: 'Close',
      action: { type: StepActionTypes.CLOSE },
      icon: StepsIconTypes.CLOSE,
      styles: generateButtonStylesForIconType(StepsIconTypes.CLOSE),
    };
  }

  if (isString(id)) {
    if (buttonType === ButtonType.NEXT_STEP) {
      return {
        text: 'Continue',
        action: {
          type: StepActionTypes.STEP,
          stepId: id,
          editorContext: { navigationBehaviour: NavigationBehaviour.NEXT_STEP },
        },
        icon: StepsIconTypes.NEXT,
        styles: generateButtonStylesForIconType(StepsIconTypes.NEXT),
      };
    }
    if (buttonType === ButtonType.BACK) {
      return {
        text: 'Back',
        action: {
          type: StepActionTypes.STEP,
          stepId: id,
          editorContext: { navigationBehaviour: NavigationBehaviour.PREVIOUS_STEP },
        },
        icon: StepsIconTypes.BACK,
        styles: generateButtonStylesForIconType(StepsIconTypes.BACK),
      };
    }

    if (buttonType === ButtonType.SPECIFIC_STEP) {
      let nextStepPosition;
      let icon = StepsIconTypes.NEXT;

      if (isString(currentStepId) && isArray(steps)) {
        nextStepPosition = calculateNextStepPosition(currentStepId, id, steps);
        if (nextStepPosition === NextStepPosition.BEHIND) {
          icon = StepsIconTypes.BACK;
        }
      }

      return {
        text: 'Continue',
        action: {
          type: StepActionTypes.STEP,
          stepId: id,
          editorContext: {
            navigationBehaviour: NavigationBehaviour.SPECIFIC_STEP,
            nextStepPosition,
          },
        } as StepActionNextStep,
        icon,
        styles: generateButtonStylesForIconType(icon),
      };
    }
  }

  throw new Error(`Payload is invalid or case does not yet exist. Payload: ${JSON.stringify({ buttonType, id })}`);
}

const isDefaultButtonText = (text: string, buttonType: ButtonType): boolean =>
  // @ts-ignore -- as we only require text prop of getButtonByType return value, we don't care about id
  getButtonByType(buttonType, '').text === text;

export const isDefaultSpecificStepButtonIcon = (icon?: StepsIconTypes): boolean =>
  !!icon && [StepsIconTypes.BACK, StepsIconTypes.NEXT].includes(icon);

export const getNextStepIdForStepWithId = getStepIdWithDirection(1);
export const getPreviousStepIdForStepWithId = getStepIdWithDirection(-1);

const isFinishButton = (button: StepButton) => button.action?.type === StepActionTypes.FINISH;

const isCloseButton = (button: StepButton) => button.action?.type === StepActionTypes.CLOSE;

const isSuspendButton = (button: StepButton) => button.action?.type === StepActionTypes.SUSPEND;

const isNextStepButton = (button: StepButton) =>
  button.action?.type === StepActionTypes.STEP &&
  button.action?.editorContext?.navigationBehaviour === NavigationBehaviour.NEXT_STEP;

export const isNextSpecificStepButton = (button: StepButton) =>
  button.action?.type === StepActionTypes.STEP &&
  button.action?.editorContext?.navigationBehaviour === NavigationBehaviour.SPECIFIC_STEP;

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- temporary
const isNextStepIdStepButton = (button: StepButton) =>
  button.action?.type === StepActionTypes.STEP &&
  button.action?.editorContext?.navigationBehaviour === NavigationBehaviour.VARIABLE;

const isBackButton = (button: StepButton) =>
  button.action?.type === StepActionTypes.STEP &&
  button.action?.editorContext?.navigationBehaviour === NavigationBehaviour.PREVIOUS_STEP;

const updateNextStepButton = (
  steps: WorkinstructionStep[],
  buttonToUpdate: StepButton,
  indexOfStepToUpdate: number,
): StepButton => {
  const lastStepIndex = steps.length - 1;
  let updatedButton: StepButton = cloneDeep(buttonToUpdate);
  const newNextStepId = indexOfStepToUpdate < lastStepIndex ? steps[indexOfStepToUpdate + 1].id : null;
  const newPrevStepId = indexOfStepToUpdate > 0 ? steps[indexOfStepToUpdate - 1].id : null;

  if (newNextStepId) {
    updatedButton.action = {
      type: StepActionTypes.STEP,
      stepId: newNextStepId,
      editorContext: { navigationBehaviour: NavigationBehaviour.NEXT_STEP },
    };
  } else if (indexOfStepToUpdate === lastStepIndex && newPrevStepId) {
    const { icon, action, text: backStepButtonText } = getButtonByType(ButtonType.BACK, newPrevStepId);
    updatedButton = {
      ...updatedButton,
      icon,
      action,
    };

    if (isDefaultButtonText(updatedButton.text, ButtonType.NEXT_STEP)) {
      updatedButton.text = backStepButtonText;
    }
  }
  return updatedButton;
};

const updateBackButton = (steps: WorkinstructionStep[], buttonToUpdate: StepButton, indexOfStepToUpdate: number) => {
  let updatedButton: StepButton = cloneDeep(buttonToUpdate);
  const newNextStepId = indexOfStepToUpdate < steps.length - 1 ? steps[indexOfStepToUpdate + 1].id : null;
  const newPrevStepId = indexOfStepToUpdate > 0 ? steps[indexOfStepToUpdate - 1].id : null;

  if (indexOfStepToUpdate === 0) {
    if (newNextStepId) {
      const { icon, action, text: nextStepButtonText } = getButtonByType(ButtonType.NEXT_STEP, newNextStepId);
      updatedButton = {
        ...updatedButton,
        icon,
        action,
      };

      if (isDefaultButtonText(updatedButton.text, ButtonType.BACK)) {
        updatedButton.text = nextStepButtonText;
      }
    } else {
      // if it's the first and last step at the same time then change to finish
      const { icon, action, text } = getButtonByType(ButtonType.FINISH);
      updatedButton = {
        ...updatedButton,
        icon,
        action,
        text,
      };
    }
  } else if (indexOfStepToUpdate > 0 && newPrevStepId) {
    updatedButton.action = {
      type: StepActionTypes.STEP,
      stepId: newPrevStepId,
      editorContext: { navigationBehaviour: NavigationBehaviour.PREVIOUS_STEP },
    };
  }
  return updatedButton;
};

const updateWiStepFirstButton = ({
  steps,
  buttonToUpdate,
  indexOfStepToUpdate,
  workflowType,
  reorderStepMetadata,
}: UpdateWiStepSpecificButtonMetadata): StepButton => {
  if (isFinishButton(buttonToUpdate)) {
    return cloneDeep(buttonToUpdate);
  }
  const newPrevStepId = indexOfStepToUpdate > 0 ? steps[indexOfStepToUpdate - 1].id : null;
  let updatedButton: StepButton = cloneDeep(buttonToUpdate);

  // If it's not a first step and top button has action “Suspend” or “Close”, change action to "Back”
  if (
    newPrevStepId &&
    (isCloseButton(buttonToUpdate) || isSuspendButton(buttonToUpdate)) &&
    [0, null].includes(reorderStepMetadata.fromIndex) // when it was moved from 0 index, or it's a new one button
  ) {
    const { icon, action, text: backButtonText } = getButtonByType(ButtonType.BACK, newPrevStepId);
    updatedButton = {
      ...updatedButton,
      icon,
      action,
    };
    // if default Finish or Close button text was not changed by user, then change to 'Back', otherwise keep it as it is
    if (
      isDefaultButtonText(buttonToUpdate.text, ButtonType.SUSPEND) ||
      isDefaultButtonText(buttonToUpdate.text, ButtonType.CLOSE)
    ) {
      updatedButton.text = backButtonText;
    }
  } else if (indexOfStepToUpdate === 0 && isBackButton(buttonToUpdate)) {
    // If some (not first) step is reordered to be the first step and top button has action “Back”, change action to "Suspend" or “Close” (depending on workflow type)

    if (workflowType === WiWorkflowType.PRIMARY) {
      const { icon, action, text: suspendButtonText } = getButtonByType(ButtonType.SUSPEND);

      updatedButton = {
        ...updatedButton,
        icon,
        action,
      };

      if (isDefaultButtonText(buttonToUpdate.text, ButtonType.BACK)) {
        updatedButton.text = suspendButtonText;
      }
    } else if (workflowType === WiWorkflowType.SECONDARY) {
      const { icon, action, text: closeButtonText } = getButtonByType(ButtonType.CLOSE);

      updatedButton = {
        ...updatedButton,
        icon,
        action,
      };

      if (isDefaultButtonText(buttonToUpdate.text, ButtonType.BACK)) {
        updatedButton.text = closeButtonText;
      }
    } else {
      console.warn(
        `button[0] for step ${steps[indexOfStepToUpdate].id} was not updated due to the unknown workflowType`,
      );
    }
  } else if (isBackButton(buttonToUpdate) && newPrevStepId) {
    // update any back button to have valid stepId
    (updatedButton.action as StepActionNextStep).stepId = newPrevStepId;
  } else if (isNextStepButton(buttonToUpdate)) {
    updatedButton = updateNextStepButton(steps, buttonToUpdate, indexOfStepToUpdate);
  }

  return updatedButton;
};

const updateWiStepSecondButton = ({
  steps,
  buttonToUpdate,
  indexOfStepToUpdate,
  workflowType,
  reorderStepMetadata,
}: UpdateWiStepSpecificButtonMetadata): StepButton => {
  const lastStepIndex = steps.length - 1;

  if (isSuspendButton(buttonToUpdate) || isCloseButton(buttonToUpdate)) {
    return cloneDeep(buttonToUpdate);
  }

  // if stepToUpdate is not the last then pass next step id otherwise null
  const newNextStepId = indexOfStepToUpdate < lastStepIndex ? steps[indexOfStepToUpdate + 1].id : null;

  let updatedButton = cloneDeep(buttonToUpdate);

  // If some (not last) step is reordered to be the last step and bottom button has action “Next step”, change action to "Finish"
  if (isNextStepButton(buttonToUpdate)) {
    if (isNull(newNextStepId)) {
      // null defines that it's last step now and there is no next step
      const { icon, action, text: finishButtonText } = getButtonByType(ButtonType.FINISH);
      updatedButton = {
        ...updatedButton,
        icon,
        action,
      };

      if (isDefaultButtonText(buttonToUpdate.text, ButtonType.NEXT_STEP)) {
        updatedButton.text = finishButtonText;
      }
    } else {
      (updatedButton.action as StepActionNextStep).stepId = newNextStepId;
    }
  } else if (newNextStepId && isFinishButton(buttonToUpdate) && reorderStepMetadata.fromIndex === lastStepIndex) {
    const { icon, action, text: nextStepButtonText } = getButtonByType(ButtonType.NEXT_STEP, newNextStepId);
    updatedButton = {
      ...updatedButton,
      icon,
      action,
    };

    // if default Finish button text was not changed by user, then change to 'Continue', otherwise keep it as it is
    if (isDefaultButtonText(buttonToUpdate.text, ButtonType.FINISH)) {
      updatedButton.text = nextStepButtonText;
    }
  } else if (isBackButton(updatedButton)) {
    // update any back button to have valid stepId
    updatedButton = updateBackButton(steps, buttonToUpdate, indexOfStepToUpdate);
  }

  return updatedButton;
};

const isReorderStepMetadataValid = (
  { fromIndex, toIndex }: BasicUpdateWiStepButtonMetadata['reorderStepMetadata'],
  stepsLength: number,
): boolean =>
  (isNull(fromIndex) || (fromIndex >= 0 && fromIndex < stepsLength)) && toIndex >= 0 && toIndex < stepsLength;

/**
 * When adding a new step in the list, deleting or reordering steps, we want to update the action of
 * some steps primary action (usally the step before or after the moved, deleted, added step).
 * This helper function handles the update by preserving
 * the attributes of the button and by changing the action of the button only if needed.
 */
export const updateWiStepButtons = ({
  steps,
  stepIdToUpdate,
  workflowType,
  reorderStepMetadata,
}: UpdateWiStepButtonsMetadata): WorkinstructionStep[] => {
  if (!isReorderStepMetadataValid(reorderStepMetadata, steps.length)) {
    throw new Error('reorderStepMetadata is invalid');
  }

  const indexOfStepToUpdate = steps.findIndex((step) => step.id === stepIdToUpdate);
  if (indexOfStepToUpdate === -1) {
    return steps;
  }

  const stepToUpdate = cloneDeep(steps[indexOfStepToUpdate]);
  if (!isRendered(stepToUpdate) || !stepToUpdate.buttons?.length) {
    return steps;
  } // if there are no buttons, then there is nothing to update

  const firstButtonIndex = 0;
  const secondButtonIndex = 1;
  const firstButton = stepToUpdate.buttons[firstButtonIndex];
  const secondButton = stepToUpdate.buttons[secondButtonIndex];
  stepToUpdate.buttons[firstButtonIndex] = updateWiStepFirstButton({
    steps,
    buttonToUpdate: firstButton,
    indexOfStepToUpdate,
    workflowType,
    reorderStepMetadata,
  });

  if (secondButton) {
    stepToUpdate.buttons[secondButtonIndex] = updateWiStepSecondButton({
      steps,
      buttonToUpdate: secondButton,
      indexOfStepToUpdate,
      workflowType,
      reorderStepMetadata,
    });
  }
  stepToUpdate.buttons = stepToUpdate.buttons.map((button) => {
    if (isNextSpecificStepButton(button)) {
      return updateSpecificStepButtonIconAndAction(button, stepToUpdate.id, steps);
    }
    return button;
  });

  const updatedSteps = cloneDeep(steps);
  updatedSteps[indexOfStepToUpdate] = stepToUpdate;

  return updatedSteps;
};

interface ReorderStepsMetadata {
  steps: WorkinstructionStep[];
  fromIndex: number;
  toIndex: number;
  workflowType: WiWorkflowType;
}

const areReorderedArgsValid = (length: number, fromIndex: number, toIndex: number): boolean =>
  toIndex < length && fromIndex < length && toIndex >= 0 && fromIndex >= 0 && toIndex !== fromIndex;

export const reorderStepsHandler = ({
  steps,
  fromIndex,
  toIndex,
  workflowType,
}: ReorderStepsMetadata): WorkinstructionStep[] => {
  const updatedStepsIds: string[] = []; // array of ids of steps that have been updated during this function exec
  if (!areReorderedArgsValid(steps.length, fromIndex, toIndex)) {
    return steps;
  }
  const lastStepIndex = steps.length - 1;

  let reorderedSteps = cloneDeep(steps);
  const stepToMove = reorderedSteps.splice(fromIndex, 1)[0];
  reorderedSteps.splice(toIndex, 0, stepToMove);

  try {
    // Update step that has been moved
    reorderedSteps = updateWiStepButtons({
      steps: reorderedSteps,
      stepIdToUpdate: reorderedSteps[toIndex].id,
      workflowType,
      reorderStepMetadata: { fromIndex, toIndex },
    });
    updatedStepsIds.push(reorderedSteps[toIndex].id);

    // Update step that has been shifted due to steps being moved
    reorderedSteps = updateWiStepButtons({
      steps: reorderedSteps,
      stepIdToUpdate: reorderedSteps[fromIndex].id,
      workflowType,
      reorderStepMetadata: {
        fromIndex: fromIndex < toIndex ? fromIndex + 1 : fromIndex - 1,
        toIndex: fromIndex,
      },
    });
    updatedStepsIds.push(reorderedSteps[fromIndex].id);

    // Update step before toIndex if step has not been moved to first position
    if (toIndex > 0) {
      const stepIdToUpdate = reorderedSteps[toIndex - 1].id;
      reorderedSteps = updateWiStepButtons({
        steps: reorderedSteps,
        stepIdToUpdate,
        workflowType,
        reorderStepMetadata: {
          fromIndex: fromIndex < toIndex ? toIndex : toIndex - 1,
          toIndex: toIndex - 1,
        },
      });
      updatedStepsIds.push(stepIdToUpdate);
    }

    // Update step next to toIndex if step has not been moved to last position
    if (toIndex < lastStepIndex) {
      const stepIdToUpdate = reorderedSteps[toIndex + 1].id;
      reorderedSteps = updateWiStepButtons({
        steps: reorderedSteps,
        stepIdToUpdate,
        workflowType,
        reorderStepMetadata: {
          fromIndex: fromIndex < toIndex ? toIndex + 1 : toIndex,
          toIndex: toIndex + 1,
        },
      });
      updatedStepsIds.push(stepIdToUpdate);
    }

    // Update step before fromIndex if step has not been moved from the first position
    if (fromIndex > 0 && fromIndex < toIndex) {
      const stepIdToUpdate = reorderedSteps[fromIndex - 1].id;
      reorderedSteps = updateWiStepButtons({
        steps: reorderedSteps,
        stepIdToUpdate,
        workflowType,
        reorderStepMetadata: {
          fromIndex: fromIndex - 1,
          toIndex: fromIndex - 1,
        },
      });
      updatedStepsIds.push(stepIdToUpdate);
    }

    // Update step next to fromIndex if step has not been moved from the last index
    if (fromIndex < lastStepIndex && fromIndex > toIndex) {
      const stepIdToUpdate = reorderedSteps[fromIndex + 1].id;
      reorderedSteps = updateWiStepButtons({
        steps: reorderedSteps,
        stepIdToUpdate,
        workflowType,
        reorderStepMetadata: {
          fromIndex: fromIndex + 1,
          toIndex: fromIndex + 1,
        },
      });
      updatedStepsIds.push(stepIdToUpdate);
    }

    const notUpdatedSteps = steps.filter(({ id }) => !updatedStepsIds.includes(id));
    const notUpdatedStepsIdsWithSpecificButtons = notUpdatedSteps
      .filter((step) => isRendered(step) && step.buttons.some((button) => isNextSpecificStepButton(button)))
      .map(({ id }) => id);

    return reorderedSteps.map((step) => {
      if (notUpdatedStepsIdsWithSpecificButtons.includes(step.id)) {
        if (isRendered(step)) {
          step.buttons = step.buttons.map((button) => {
            if (isNextSpecificStepButton(button)) {
              return updateSpecificStepButtonIconAndAction(button, step.id, reorderedSteps);
            }

            return button;
          });
        }
      }
      return step;
    });
  } catch (e) {
    toast.error('Something went wrong while reordering steps');
    return steps;
  }
};

interface DeleteStepHelperMetadata {
  steps: WorkinstructionStep[];
  stepIdToDelete: string;
  workflowType: WiWorkflowType;
}

export const deleteStepHandler = ({
  steps,
  stepIdToDelete,
  workflowType,
}: DeleteStepHelperMetadata): WorkinstructionStep[] => {
  const indexOfStepToDelete = steps.findIndex((step) => step.id === stepIdToDelete);
  if (indexOfStepToDelete === -1) {
    return steps;
  }

  const lastStepIndexBeforeDelete = steps.length - 1;
  const nextStepIndexBeforeDelete: number | null =
    indexOfStepToDelete < steps.length - 1 ? indexOfStepToDelete + 1 : null;
  const prevStepIndexBeforeDelete: number | null = indexOfStepToDelete > 0 ? indexOfStepToDelete - 1 : null;

  const nextStepId: string | null = isNumber(nextStepIndexBeforeDelete) ? steps[nextStepIndexBeforeDelete].id : null; // null if doesn't exist because it's last step
  const prevStepId: string | null = isNumber(prevStepIndexBeforeDelete) ? steps[prevStepIndexBeforeDelete].id : null; // null if doesn't exist because it's first step

  let updatedSteps = steps.filter((step) => step.id !== stepIdToDelete);

  try {
    if (isNumber(prevStepIndexBeforeDelete) && prevStepId) {
      updatedSteps = updateWiStepButtons({
        steps: updatedSteps,
        stepIdToUpdate: prevStepId,
        workflowType,
        reorderStepMetadata: {
          fromIndex: prevStepIndexBeforeDelete,
          toIndex: prevStepIndexBeforeDelete,
        },
      });
    }

    if (isNumber(nextStepIndexBeforeDelete) && nextStepId) {
      updatedSteps = updateWiStepButtons({
        steps: updatedSteps,
        stepIdToUpdate: nextStepId,
        workflowType,
        reorderStepMetadata: {
          // if nextStepId was the last step id, then we pass the same value for fromIndex and toIndex, as nextStep index was and remained the last index
          fromIndex:
            lastStepIndexBeforeDelete === nextStepIndexBeforeDelete ? indexOfStepToDelete : nextStepIndexBeforeDelete,
          toIndex: indexOfStepToDelete,
        },
      });
    }

    return updatedSteps;
  } catch (e) {
    toast.error('Something went wrong when deleting the step');
    return steps;
  }
};
