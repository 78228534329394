// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserActions-module__print___UkGHa{overflow:visible !important}.UserActions-module__container___fRVN2{margin-left:-12px;margin-right:-12px}.UserActions-module__userActionsMenu___au6ih a{font-family:Proxima Nova;font-style:normal;font-weight:600;font-size:14px;line-height:20px;letter-spacing:0px;color:#252a37;margin-right:20px;text-decoration:none;display:inline-flex}.UserActions-module__userActionsMenu___au6ih a:hover{color:#252a37}.UserActions-module__icon___r5wfi{color:#040e20 !important;min-width:22px !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `UserActions-module__print___UkGHa`,
	"container": `UserActions-module__container___fRVN2`,
	"userActionsMenu": `UserActions-module__userActionsMenu___au6ih`,
	"icon": `UserActions-module__icon___r5wfi`
};
export default ___CSS_LOADER_EXPORT___;
