import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/types/global';
import { Sorting, ListConfigProperties, ListConfigSorting } from '@workerbase/types/ListConfig';

import { DevicesActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updateSorting, updateListConfig } from '../common/ListConfig/reducers';

export type DevicesState = Readonly<{
  listConfigs: ListConfigProperties & ListConfigSorting;
}>;

export const initialState: DevicesState = {
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: false,
      },
      {
        selector: 'deviceBattery',
        omit: false,
      },
      {
        selector: 'userName',
        omit: false,
      },
      {
        selector: 'deviceMemory',
        omit: true,
      },
      {
        selector: 'locationName',
        omit: false,
      },
      {
        selector: 'lastContact',
        omit: false,
      },
      {
        selector: 'lastDeviceData',
        omit: true,
      },
      {
        selector: 'mqtt.connectedAt',
        omit: true,
      },
      {
        selector: 'lastAvailability',
        omit: true,
      },
      {
        selector: 'serialNumber',
        omit: true,
      },
      {
        selector: 'lastLoggedInUserName',
        omit: true,
      },
      {
        selector: 'systemOSVersion',
        omit: true,
      },
      {
        selector: 'systemBuild',
        omit: false,
      },
      {
        selector: 'systemVersionCode',
        omit: false,
      },
      {
        selector: 'wifi.mac',
        omit: true,
      },
      {
        selector: 'wifi.ip',
        omit: true,
      },
      {
        selector: 'wifi.hostname',
        omit: true,
      },
      {
        selector: 'bluetooth.mac',
        omit: true,
      },
      {
        omit: false,
        selector: 'connected',
      },
    ],
    sorting: { selector: 'lastContact', sortDirection: SortOrder.ASC },
  },
};

const reducer = (state: DevicesState = initialState, action: AnyAction): DevicesState => {
  switch (action.type) {
    case DevicesActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case DevicesActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case DevicesActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    default:
      return state;
  }
};

export default reducer;
