import { ConditionOperator } from '../../../../RuleCondition/ConditionOperator';
import { IFilterCondition, IFilterBase, IFilterGroup } from '../Filter.interface';
import { FilterTypes } from '../FilterTypes.enum';

export function isFilterGroup<T extends ConditionOperator>(filter: IFilterBase): filter is IFilterGroup<T> {
  return filter.type === FilterTypes.GROUP;
}

export function isFilterCondition<T extends ConditionOperator>(filter: IFilterBase): filter is IFilterCondition<T> {
  return filter.type === FilterTypes.CONDITION;
}

export function isFilterConditionOperator<T extends ConditionOperator>(
  FilterConditionOperators: Record<string, T>,
  operator: unknown,
): operator is T {
  return Object.values(FilterConditionOperators).includes(operator as T);
}
