export enum ResourceTypes {
  Asset = 'asset',
  AssetType = 'asset-type',
  Connector = 'connector',
  Database = 'database',
  Device = 'device',
  Document = 'document',
  DocumentFormResult = 'document-form-result',
  Function = 'function',
  Location = 'location',
  Machine = 'machine',
  MachineType = 'machine-type',
  MaterialType = 'material-type',
  Media = 'media',
  ProductionLine = 'production-line',
  ProductType = 'product-type',
  Project = 'project',
  Role = 'role',
  Rule = 'rule',
  Skill = 'skill',
  Task = 'task',
  User = 'user',
  Workinstruction = 'workinstruction',
  WorkOrder = 'work-order',
  Workstation = 'workstation',
}
