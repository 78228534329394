type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export type Primitive = string | boolean | number | bigint | symbol;

export class Utils {
  public static isObject(value: any): boolean {
    return value !== undefined && value !== null && value.constructor === Object;
  }

  public static isJSON(value: any): boolean {
    try {
      JSON.parse(value);
    } catch (error) {
      return false;
    }
    return true;
  }

  public static getEnumLength<E extends Enum<E>>(enumParameter: E): number {
    return Object.keys(enumParameter).filter((key) => Number(Number.isNaN(Number(key)))).length;
  }

  public static unescapeNewLines(text: string): string {
    return text.replaceAll(/\\n/g, '\n');
  }

  public static escapeNewLines(text: string): string {
    return text.replaceAll(/\n/g, '\\n');
  }

  public static unique<Primitive>(input: Primitive[]): Primitive[] {
    return [...new Set(input)];
  }

  // Ref: https://stackoverflow.com/questions/31538010/test-if-a-variable-is-a-primitive-rather-than-an-object
  public static isPrimitive = (val: unknown): boolean => val !== Object(val);
}
