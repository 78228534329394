type ObjectQuery = {
  [key: string]: false | ObjectQuery;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code to be refactored
const insertValueToPath = (obj: ObjectQuery, path: string[], value: any): ObjectQuery => {
  const firstKey = path[0];
  if (!firstKey) {
    return obj;
  }
  if (path.length === 1) {
    return { ...obj, [firstKey]: value };
  }

  const recursiveObjectToInsert = obj[firstKey] || {};
  const recursiveInsertedObject = insertValueToPath(recursiveObjectToInsert, path.slice(1), value);
  return { ...obj, [firstKey]: recursiveInsertedObject };
};

const fieldsListToObject = (fields: string[]) =>
  fields.reduce((returnObject, field) => insertValueToPath(returnObject, field.split('.'), false), {});

const objectToGraphqlQuery = (fieldsObject: ObjectQuery): string =>
  Object.entries(fieldsObject).reduce((graphqlQuery, [field, value]) => {
    if (!value) {
      return `${graphqlQuery} ${field}`;
    }
    return `${graphqlQuery} ${field} {${objectToGraphqlQuery(value)} }`;
  }, '');

export const fieldListToGraphqlQuery = (fields: string[]): string => objectToGraphqlQuery(fieldsListToObject(fields));
