// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarItem-module__print___y8JRx{overflow:visible !important}.SidebarItem-module__sidebarItem___YV1nE{border:none;list-style:none;user-select:none}.SidebarItem-module__sidebarItem___YV1nE a{font-family:Proxima Nova;font-style:normal;font-weight:bold;font-size:16px;line-height:20px;letter-spacing:0px;text-decoration:none;color:#252a37;display:flex;align-items:center;padding:14px 0 14px 16px;border-radius:4px;width:100%;box-sizing:border-box}.SidebarItem-module__sidebarItem___YV1nE a svg{margin-right:8px}.SidebarItem-module__sidebarItem___YV1nE a:hover{cursor:pointer}.SidebarItem-module__sidebarItem___YV1nE .SidebarItem-module__active___nmTfc{background-color:#fff;width:290px;padding-right:26px}.SidebarItem-module__sidebarItem___YV1nE .SidebarItem-module__disabled___TkBB4{background-color:rgba(0,0,0,0);color:#cdd2e0;cursor:not-allowed !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `SidebarItem-module__print___y8JRx`,
	"sidebarItem": `SidebarItem-module__sidebarItem___YV1nE`,
	"active": `SidebarItem-module__active___nmTfc`,
	"disabled": `SidebarItem-module__disabled___TkBB4`
};
export default ___CSS_LOADER_EXPORT___;
