import React, { FC } from 'react';
import { UploadFile as UploadFileIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { formatFileSize } from 'utils/formatFileSize';
import { FileUploadStatus } from './FileUploadStatus.enum';

export interface FileUploadDisplayProps {
  status: FileUploadStatus;
  file: { name: string; size?: number; type: string };
  onActionClick: () => void;
}

const FILE_STATUS_CONFIG = {
  [FileUploadStatus.Loading]: {
    label: 'Loading',
    actionIcon: <CircularProgress size={24} />,
    color: 'primary.main',
  },
  [FileUploadStatus.Success]: {
    label: 'Success',
    actionIcon: <DeleteIcon fontSize="small" />,
    color: 'success.main',
  },
  [FileUploadStatus.Error]: {
    label: 'Error',
    actionIcon: <DeleteIcon fontSize="small" />,
    color: 'error.main',
  },
} as const;

export const FileUploadDisplay: FC<FileUploadDisplayProps> = ({ file, status, onActionClick }) => (
  <Stack
    direction="row"
    sx={{
      alignItems: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: '100px',
      px: 2,
      borderColor: 'divider',
    }}
    key={file.name}
  >
    <UploadFileIcon sx={{ color: status === FileUploadStatus.Error ? 'error.main' : 'primary.main', ml: 1, mr: 2 }} />
    <Stack>
      <Typography lineHeight="21px">{file.name}</Typography>
      <Stack direction="row" gap={1}>
        {file.size && (
          <Typography color="grey.500" variant="body2">
            {formatFileSize(file.size)}
          </Typography>
        )}
        <Typography color="grey.500" variant="body2">
          •
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography color="grey.500" variant="body2" mr="3px">
            {FILE_STATUS_CONFIG[status].label}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <IconButton
      sx={{
        ml: 'auto',
        backgroundColor: status === FileUploadStatus.Loading ? 'transparent' : 'grey.50',
        pointerEvents: status === FileUploadStatus.Loading ? 'none' : 'auto',
      }}
      onClick={onActionClick}
    >
      {FILE_STATUS_CONFIG[status].actionIcon}
    </IconButton>
  </Stack>
);
