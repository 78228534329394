import { VariableDataTypes } from '../Variable';
import { DatabaseColumnTypes } from '../Database';

export enum ConditionValueTypes {
  OBJECT_ID = 'objectId',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ENUM_STRING = 'enum_string',
  ENUM_NUMBER = 'enum_number',
  ENUM_OBJECT = 'enum_object',
  ENUM_OPTION = 'enum_option',
  ARRAY = 'array',
  CHECKLIST_ITEM = 'checklist_item',
  RESOURCE = 'resource',
}

export const ColumnTypeToVariableDataTypeMapping = {
  [DatabaseColumnTypes.STRING]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.DATETIME]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.DATE]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.TIME]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.DOUBLE]: VariableDataTypes.NUMBER,
  [DatabaseColumnTypes.INTEGER]: VariableDataTypes.NUMBER,
  [DatabaseColumnTypes.BOOLEAN]: VariableDataTypes.BOOLEAN,
  [DatabaseColumnTypes.LOCATION]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.ROLE]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.SKILL]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.REFERENCE]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.USER]: VariableDataTypes.STRING,
  [DatabaseColumnTypes.MEDIA]: VariableDataTypes.STRING,
};

export const VariableDataTypesToConditionValueTypesMapping = {
  [VariableDataTypes.STRING]: ConditionValueTypes.STRING,
  [VariableDataTypes.NUMBER]: ConditionValueTypes.NUMBER,
  [VariableDataTypes.BOOLEAN]: ConditionValueTypes.BOOLEAN,
  [VariableDataTypes.ARRAY]: ConditionValueTypes.ENUM_OBJECT,
  [VariableDataTypes.OBJECT]: ConditionValueTypes.STRING,
  [VariableDataTypes.UNKNOWN]: ConditionValueTypes.STRING,
};
