import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/types/global';
import { Sorting, ListConfigs, PropertySelectors } from '@workerbase/types/ListConfig';

import { FunctionType } from '@workerbase/types/api/functions';
import { PaginationMeta } from '@workerbase/types/Response';

import { FunctionsActions } from './actions';
import {
  UpdatePaginationActionPayload,
  UpdateFilteringActionPayload,
  UpdateListConfigActionPayload,
} from '../common/ListConfig/actions';
import { updatePagination, updateSorting, updateFiltering, updateListConfig } from '../common/ListConfig/reducers';

export type FunctionsState = Readonly<{
  functionsById: { [key: string]: FunctionType };
  isDeploying: boolean;
  listConfigs: ListConfigs;
  currentListItemsIds: string[];
  errorMessage: string | null;
}>;

export const initialState: FunctionsState = {
  functionsById: {},
  isDeploying: false,
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'description',
        omit: true,
      },
      {
        selector: 'triggeredAt',
        omit: false,
      },
      {
        selector: PropertySelectors.LINKS,
        omit: false,
      },
    ],
    pagination: { currentPage: 1, itemsPerPage: 20 },
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
};

interface GetFunctionsSuccessActionPayload {
  functions: FunctionType[];
  meta: PaginationMeta;
}

interface GetFunctionSuccessActionPayload {
  functionData: FunctionType;
}

interface GetFunctionsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: FunctionsState = initialState, action: AnyAction): FunctionsState => {
  switch (action.type) {
    case FunctionsActions.GET_FUNCTIONS_SUCCESS: {
      const payload = action.payload as GetFunctionsSuccessActionPayload;

      const functionsById = payload.functions.reduce((prev, func) => {
        const updatedConnectors = prev;
        updatedConnectors[func.id] = func;
        return prev;
      }, {});

      return {
        ...state,
        functionsById: {
          ...state.functionsById,
          ...functionsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.functions.map((func) => func.id),
      };
    }
    case FunctionsActions.GET_FUNCTION_BY_ID_SUCCESS: {
      const payload = action.payload as GetFunctionSuccessActionPayload;
      const fetchedConnector = payload.functionData;
      const func = {
        [fetchedConnector.id]: fetchedConnector,
      };

      return {
        ...state,
        functionsById: {
          ...state.functionsById,
          ...func,
        },
      };
    }
    case FunctionsActions.GET_FUNCTION_BY_ID_FAILURE: {
      const payload = action.payload as GetFunctionsFailureActionPayload;
      return {
        ...initialState,
        errorMessage: payload.errorMessage,
      };
    }
    case FunctionsActions.DELETE_FUNCTION_BY_ID_SUCCESS: {
      const functionId: string = action.payload.functionId;

      const functionsById = { ...state.functionsById };
      if (functionsById[functionId]) {
        delete functionsById[functionId];
      }

      return {
        ...state,
        functionsById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== functionId),
      };
    }
    case FunctionsActions.DELETE_FUNCTION_BY_ID_FAILURE: {
      const payload = action.payload as GetFunctionsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case FunctionsActions.UPDATE_PAGINATION: {
      const payload = action.payload as UpdatePaginationActionPayload;
      return updatePagination(state, payload);
    }
    case FunctionsActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case FunctionsActions.UPDATE_FILTERING: {
      const payload = action.payload as UpdateFilteringActionPayload;
      return updateFiltering(state, payload);
    }
    case FunctionsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case FunctionsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case FunctionsActions.DEPLOY_FUNCTION_SUCCESS: {
      const { functionId } = action.payload;
      return {
        ...state,
        isDeploying: false,
        functionsById: {
          ...state.functionsById,
          [functionId]: { ...state.functionsById[functionId], deployed: true },
        },
      };
    }
    case FunctionsActions.DEPLOY_FUNCTION_FAILURE:
      return { ...state, isDeploying: false };
    case FunctionsActions.DEPLOY_FUNCTION_REQUEST:
      return { ...state, isDeploying: true };
    case FunctionsActions.SET_IS_DEPLOYING:
      return { ...state, isDeploying: action.payload.isDeploying };
    default:
      return state;
  }
};

export default reducer;
