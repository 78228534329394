import { Prop, Schema } from '@nestjs/mongoose';
import { ResourceTypes, IConnectedTo } from '../../domain/common';

@Schema({ _id: false })
export class ConnectedTo implements IConnectedTo {
  @Prop()
  resourceId: string;

  @Prop({ type: String, enum: Object.values(ResourceTypes) })
  resourceType: ResourceTypes;
}
