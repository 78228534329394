import { all, fork } from 'redux-saga/effects';

import { sagas as commonSagas } from './common';
import { sagas as loginSagas } from './Login';
import { sagas as rolesSagas } from './Roles';
import { sagas as usersSagas } from './Users';
import { sagas as devicesSagas } from './Devices';
import { sagas as projectsSagas } from './Projects';
import { sagas as tasksSagas } from './Tasks';
import { sagas as rulesSagas } from './Rules';
import { sagas as workinstructionsSagas } from './Workinstructions';
import { sagas as connectorsSagas } from './Connectors';
import { sagas as connectorLogsSagas } from './ConnectorLogs';
import { sagas as databasesSagas } from './Databases';
import { sagas as databaseItemsSagas } from './DatabaseItems';
import { sagas as mqttSagas } from './Mqtt';
import { sagas as skillsSagas } from './Skills';
import { sagas as shiftPlansSagas } from './ShiftPlans';
import { sagas as functionsSagas } from './Functions';
import { sagas as functionLogsSagas } from './FunctionLogs';
import { sagas as locationsSagas } from './Locations';
import { sagas as translationsSagas } from './Translations';
import { sagas as settingsSagas } from './Settings';
import { sagas as projectLogsSagas } from './ProjectLogs';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all(
    [
      commonSagas,
      loginSagas,
      rolesSagas,
      usersSagas,
      devicesSagas,
      projectsSagas,
      tasksSagas,
      rulesSagas,
      workinstructionsSagas,
      connectorsSagas,
      connectorLogsSagas,
      databasesSagas,
      databaseItemsSagas,
      mqttSagas,
      shiftPlansSagas,
      skillsSagas,
      functionsSagas,
      functionLogsSagas,
      locationsSagas,
      translationsSagas,
      settingsSagas,
      projectLogsSagas,
    ].map(fork),
  );
}
