import React, { ReactNode, useEffect } from 'react';
import { createIntl, createIntlCache, IntlShape, RawIntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { RootState } from '@redux/types';
import { getUserLanguage } from '@redux/Login';
import { Languages } from '@workerbase/types/Settings';

import { useForceUpdate } from 'hooks/useForceUpdate';
import messagesEn from '../../translations/en.json';
import messagesCs from '../../translations/cs.json';
import messagesDe from '../../translations/de.json';
import messagesZh from '../../translations/zh.json';
import messagesEs from '../../translations/es.json';

const messages: { [lang in Languages]?: Record<string, string> } = {
  [Languages.EN]: messagesEn,
  [Languages.CS]: messagesCs,
  [Languages.DE]: messagesDe,
  [Languages.ZH]: messagesZh,
  [Languages.ES]: messagesEs,
};

const initialLocale = Languages.EN;

const cache = createIntlCache();

// eslint-disable-next-line import/no-mutable-exports
let intl = createIntl(
  {
    locale: initialLocale,
    messages: messages[initialLocale],
  },
  cache,
);

export const getIntl = (): IntlShape => intl;

interface IntlProviderProps {
  children: ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const userLocale = useSelector<RootState, Languages>(getUserLanguage);

  const getIntlLocale = (userLanguage: Languages) => {
    const availableLanguages = Object.keys(messages);

    if (availableLanguages.includes(userLanguage)) {
      return userLanguage;
    }

    // User language coming from backend is not a supported frontend language
    // Trying to set language to browser language
    const browserLanguage = window.navigator.language.split(/[-_]/)[0];
    if (availableLanguages.includes(browserLanguage as Languages)) {
      return browserLanguage;
    }

    return initialLocale;
  };

  useEffect(() => {
    const newLocale = getIntlLocale(userLocale);
    intl = createIntl({ locale: newLocale, messages: messages[newLocale] }, cache);

    // trigger rerender to update RawIntlProvider with new intl
    forceUpdate();
  }, [userLocale]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
