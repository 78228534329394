import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsEnum, IsString } from 'class-validator';
import { LayoutOrientation } from '../../../LayoutOrientation';
import { ClassValidatorOptions } from '../../utils/ClassValidatorOptions';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class LayoutAssemblyVideo extends StepBase {
  type: StepTypes.LAYOUT_ASSEMBLY_VIDEO;

  @Prop({ type: String, enum: Object.values(LayoutOrientation) })
  @IsEnum(LayoutOrientation, ClassValidatorOptions.getEnumErrorMessageOptions(LayoutOrientation))
  orientation: LayoutOrientation;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;
}
