import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// Types
import { History } from 'history';
import { Store } from 'redux';

import { Box, Stack } from '@mui/material';
import { DrawerProvider } from '@uiKit/WuiDrawer/WuiDrawerContext';

import { IntlProvider } from './providers/IntlProvider';
import { AuthProvider } from './providers/AuthProvider';
import { NotesProvider } from './providers/NotesProvider';
import ErrorBoundary from './components/ErrorBoundary';
import './app.global.scss';
import Routes from './routes';
import './scoped-elastic.scss';
import { muiTheme } from './stylesheet';

interface Props {
  history: History;
  store: Store;
}

const App: React.FC<Props> = ({ history, store }) => (
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider>
        <AuthProvider>
          <NotesProvider>
            <ConnectedRouter history={history}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                  <ErrorBoundary>
                    <DrawerProvider>
                      <Stack direction="row" height="100%">
                        <Box sx={{ width: '100%' }}>
                          <Route path="/" component={Routes} />
                          <ToastContainer position="bottom-left" />
                        </Box>
                      </Stack>
                    </DrawerProvider>
                  </ErrorBoundary>
                </ThemeProvider>
              </StyledEngineProvider>
            </ConnectedRouter>
          </NotesProvider>
        </AuthProvider>
      </IntlProvider>
    </Provider>
  </React.StrictMode>
);

export default hot(module)(App);
