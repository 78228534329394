import { Rule } from 'services/types/Rule';

import { RootState } from '../types';

export const getRules = (store: RootState) => store.rules.rulesById;
export const getRulesOnCurrentPage = (store: RootState) =>
  store.rules.currentListItemsIds.map((id) => store.rules.rulesById[id]);

export const getListConfigs = (store: RootState) => store.rules.listConfigs;

export const getRuleWithId = (store: RootState, ruleId: string): Rule | undefined => store.rules.rulesById[ruleId];
