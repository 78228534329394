// eslint-disable-next-line max-classes-per-file
import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputListBaseOption, InputListBaseStep, ListOptions } from './base';

@Schema({ _id: false })
export class InputTileListOption extends InputListBaseOption {}

@Schema()
export class InputTileList extends InputListBaseStep {
  type: StepTypes.INPUT_TILE_LIST;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputTileListOption>;

  @Prop({ default: true })
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  customNextStep: boolean;
}
