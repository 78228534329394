import { Property } from '@workerbase/types/ListConfig';
import { updateSortingFactory } from '../common/ListConfig/actions';

export enum DevicesActions {
  UPDATE_SORTING = '@@devices/UPDATE_SORTING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@devices/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@devices/UPDATE_LIST_CONFIG',
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateSorting = updateSortingFactory(DevicesActions.UPDATE_SORTING);

export const updateListConfigProperties = (newProperties: Property[]) => ({
  type: DevicesActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});
