import { Property } from '@workerbase/types/ListConfig';

import { Rule, RulePOST, RulePUT } from 'services/types/Rule';
import { PaginationMeta } from '@workerbase/types/Response';

import { BaseAction } from '../common/actions';
import {
  updateFilteringFactory,
  updateSortingFactory,
  updatePaginationFactory,
  updateSelectedTabKeyFactory,
} from '../common/ListConfig/actions';

export enum RulesActions {
  GET_RULES_REQUEST = '@@rules/GET_RULES_REQUEST',
  GET_RULES_SUCCESS = '@@rules/GET_RULES_SUCCESS',
  GET_RULES_FAILURE = '@@rules/GET_RULES_FAILURE',
  DELETE_RULE_BY_ID_REQUEST = '@@rules/DELETE_RULE_BY_ID_REQUEST',
  DELETE_RULE_BY_ID_SUCCESS = '@@rules/DELETE_RULE_BY_ID_SUCCESS',
  DELETE_RULE_BY_ID_FAILURE = '@@rules/DELETE_RULE_BY_ID_FAILURE',
  GET_RULE_BY_ID_REQUEST = '@@rules/GET_RULE_BY_ID_REQUEST',
  GET_RULE_BY_ID_SUCCESS = '@@rules/GET_RULE_BY_ID_SUCCESS',
  GET_RULE_BY_ID_FAILURE = '@@rules/GET_RULE_BY_ID_FAILURE',
  CREATE_RULE_REQUEST = '@@rules/CREATE_RULE_REQUEST',
  UPDATE_RULE_BY_ID_REQUEST = '@@rules/UPDATE_RULE_BY_ID_REQUEST',
  ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_REQUEST = '@@rules/ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_REQUEST',
  ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_SUCCESS = '@@rules/ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_SUCCESS',
  UPDATE_PAGINATION = '@@rules/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@rules/UPDATE_SORTING',
  UPDATE_FILTERING = '@@rules/UPDATE_FILTERING',
  UPDATE_SELECTED_TAB_KEY = '@@rules/UPDATE_SELECTED_TAB_KEY',
  UPDATE_LISTCONFIG_PROPERTIES = '@@rules/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@rules/UPDATE_LIST_CONFIG',
}

// GET_RULES
export const getRulesRequestAction = (projectId: string): BaseAction => ({
  type: RulesActions.GET_RULES_REQUEST,
  payload: { projectId },
});

export const getRulesSuccess = (data: Rule[], meta: PaginationMeta): BaseAction => ({
  type: RulesActions.GET_RULES_SUCCESS,
  payload: {
    rules: data,
    meta,
  },
});

export const getRulesError = (errorMessage: string): BaseAction => ({
  type: RulesActions.GET_RULES_FAILURE,
  payload: {
    errorMessage,
  },
});

// DELETE_RULE_BY_ID
export interface DeleteRuleByIdRequestAction {
  type: string;
  payload: {
    ruleId: string;
  };
}
export const deleteRuleByIdRequest = (ruleId: string): DeleteRuleByIdRequestAction => ({
  type: RulesActions.DELETE_RULE_BY_ID_REQUEST,
  payload: { ruleId },
});

export const deleteRuleByIdSuccess = (ruleId: string): DeleteRuleByIdRequestAction => ({
  type: RulesActions.DELETE_RULE_BY_ID_SUCCESS,
  payload: {
    ruleId,
  },
});

export const deleteRuleByIdError = (errorMessage: string): BaseAction => ({
  type: RulesActions.DELETE_RULE_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// GET_RULE_BY_ID
export const getRuleByIdRequestAction = (ruleId: string): BaseAction => ({
  type: RulesActions.GET_RULE_BY_ID_REQUEST,
  payload: { ruleId },
});

export const getRuleByIdSuccess = (data: Rule): BaseAction => ({
  type: RulesActions.GET_RULE_BY_ID_SUCCESS,
  payload: {
    rule: data,
  },
});

export const getRuleByIdError = (errorMessage: string): BaseAction => ({
  type: RulesActions.GET_RULE_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_RULE_BY_ID
export interface UpdateRuleByIdRequestAction {
  type: string;
  payload: {
    rule: RulePUT;
    ruleId: string;
  };
}
export const updateRuleByIdRequest = (ruleId: string, rule: RulePUT): UpdateRuleByIdRequestAction => ({
  type: RulesActions.UPDATE_RULE_BY_ID_REQUEST,
  payload: { rule, ruleId },
});

export interface CreateRuleRequestAction {
  type: string;
  payload: {
    rule: RulePOST;
  };
}
export const createRuleRequest = (rule: RulePOST): CreateRuleRequestAction => ({
  type: RulesActions.CREATE_RULE_REQUEST,
  payload: { rule },
});
export interface ActivateOrDeactivateRuleByIdAction {
  type: string;
  payload: {
    ruleId: string;
    activate: boolean;
    projectId?: string; // used to trigger a table refresh
  };
}
export const activateOrDeactivateRuleByIdRequest = (
  ruleId: string,
  activate: boolean,
  projectId: string,
): ActivateOrDeactivateRuleByIdAction => ({
  type: RulesActions.ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_REQUEST,
  payload: { ruleId, activate, projectId },
});

export const activateOrDeactivateRuleByIdSuccess = (
  ruleId: string,
  activate: boolean,
): ActivateOrDeactivateRuleByIdAction => ({
  type: RulesActions.ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_SUCCESS,
  payload: { ruleId, activate },
});

export const updatePagination = updatePaginationFactory(RulesActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(RulesActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(RulesActions.UPDATE_FILTERING);
export const updateSelectedTabKey = updateSelectedTabKeyFactory(RulesActions.UPDATE_SELECTED_TAB_KEY);

export interface UpdateListConfigPropertiesAction {
  type: string;
  payload: {
    properties: Property[];
  };
}

export const updateListConfigProperties = (newProperties: Property[]): UpdateListConfigPropertiesAction => ({
  type: RulesActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});
