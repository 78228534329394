import { AnyAction } from 'redux';
import { MqttActions } from './actions';

export type MqttState = Readonly<{
  isAuthenticated: boolean;
  error: string | null;
}>;

export const initialState: MqttState = {
  isAuthenticated: false,
  error: null,
};

const reducer = (state: MqttState = initialState, action: AnyAction): MqttState => {
  switch (action.type) {
    case MqttActions.MQTT_CONNECTED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case MqttActions.MQTT_DISCONNECTED:
      return {
        ...state,
        isAuthenticated: false,
      };

    case MqttActions.MQTT_ERROR:
      return {
        ...state,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default reducer;
