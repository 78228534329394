import {
  ConnectorGET,
  ConnectorLogGET,
  ConnectorPUT,
  HttpWebhookPUT,
  MqttConnectorGET,
  MqttConnectorPUT,
} from '@workerbase/types/api/connectors';
import { ConnectionStatus } from '@workerbase/types/ConnectionStatus';
import { ConnectorType } from '@workerbase/types/ConnectorType';
import { BaseConnector, Connector, ConnectorLog, HttpWebhook, MqttConnector } from 'services/types/Connector';

// eslint-disable-next-line consistent-return
export function normalizeConnector(connector: ConnectorGET): Connector {
  const baseConnector: BaseConnector = {
    id: connector._id,
    description: connector.description || '',
    name: connector.name,
    type: connector.type,
    projectId: connector.project,
    createdAt: connector.meta.createdAt,
    updatedAt: connector.meta.updatedAt,
    triggeredAt: connector.triggeredAt,
    variables: connector.variables || [],
    links: connector.links,
  };

  switch (connector.type) {
    case ConnectorType.HTTP_WEBHOOK: {
      const httpWebhook: HttpWebhook = {
        ...baseConnector,
        type: ConnectorType.HTTP_WEBHOOK,
        payload: connector.payload || {},
      };
      return httpWebhook;
    }
    case ConnectorType.MQTT_CONNECTOR: {
      const mqttConnectorGET = connector as MqttConnectorGET;
      const mqttConnector: MqttConnector = {
        ...baseConnector,
        type: ConnectorType.MQTT_CONNECTOR,
        payload: mqttConnectorGET.lastPayloadReceived || {},
        options: { ...mqttConnectorGET.options, password: '' } || {},
        status: mqttConnectorGET.status || ConnectionStatus.DISCONNECTED,
      };
      return mqttConnector;
    }
  }
}

export function convertConnectorToConnectorPUT(connector: HttpWebhook): HttpWebhookPUT;
export function convertConnectorToConnectorPUT(connector: MqttConnector): MqttConnectorPUT;
// eslint-disable-next-line consistent-return
export function convertConnectorToConnectorPUT(connector: Connector): ConnectorPUT {
  switch (connector.type) {
    case ConnectorType.HTTP_WEBHOOK: {
      return {
        name: connector.name,
        type: connector.type,
        description: connector.description,
        variables: connector.variables || [],
      };
    }
    case ConnectorType.MQTT_CONNECTOR: {
      return {
        name: connector.name,
        type: connector.type,
        description: connector.description,
        options: connector.options,
        variables: connector.variables || [],
      };
    }
  }
}

export function normalizeConnectorLog(connectorLog: ConnectorLogGET): ConnectorLog {
  return {
    id: connectorLog._id,
    type: connectorLog.type,
    status: connectorLog.status,
    connectorId: connectorLog.connector,
    createdAt: new Date(connectorLog.meta.createdAt),
    errorMessage: connectorLog.errorMessage,
    payload: connectorLog.payload,
    metadata: connectorLog.metadata,
  };
}
