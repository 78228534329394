import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsString } from 'class-validator';
import { StepBase } from './StepBase';

@Schema()
export class InfoMediaStepBase extends StepBase {
  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;
}
