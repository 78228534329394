/* eslint-disable react/jsx-props-no-spreading --- needed in this file */
import React, { FC, useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import type { FormikFieldProps } from '@uiKit/types/FormikField';
import { WuiTextField, type WuiTextFieldProps } from './WuiTextField';

type WuiFormikTextFieldProps = FormikFieldProps<WuiTextFieldProps>;

export const WuiFormikTextField: FC<WuiFormikTextFieldProps> = ({ ...props }) => {
  const component = useCallback(
    ({ field, form, meta, onBlur, onChange, ...componentProps }: WuiFormikTextFieldProps & FieldProps) => {
      const isTouched = get(form.touched, field.name);
      const errorText = get(form.errors, field.name) as string;
      const showError = !!(isTouched && errorText);

      const handleChange: WuiTextFieldProps['onChange'] = (e) => {
        form.setFieldTouched(props.name, true);
        field.onChange(e);
        onChange?.(e);
      };

      const handleBlur: WuiTextFieldProps['onBlur'] = (e) => {
        field.onChange(e);
        onBlur?.(e);
      };

      return (
        <WuiTextField
          {...componentProps}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={showError}
          helperText={showError ? errorText : componentProps.helperText}
        />
      );
    },
    [],
  );

  return <Field component={component} {...props} />;
};
