import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsOptional } from 'class-validator';
import { InputValueListOption } from '../index';
import { OptionsList } from './OptionsList';

@Schema({ _id: false })
export class MaterialsList extends OptionsList<InputValueListOption> {
  @Prop()
  @IsBoolean()
  @IsOptional()
  autoContinue?: boolean;

  @Prop()
  @IsBoolean()
  @IsOptional()
  customNextStep?: boolean;

  @Prop({ default: false })
  @IsBoolean()
  showSearchBar: boolean;
}
