import { takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { ToastUtils } from '../../utils/ToastUtils';
import { CommonActions } from './actions';

function handleRequestErrorSaga(action) {
  const errorReportingEventId = action.payload.error.response?.body?.errorReportingEventId;
  const errorMessages =
    action.payload?.error?.response?.body?.error?.additionalData?.errors || action.payload.error.message;
  if (errorReportingEventId) {
    const errorMessage = Array.isArray(errorMessages) ? errorMessages[0] : errorMessages;
    ToastUtils.showToastWithErrorReportingId(errorMessage, errorReportingEventId);
  } else {
    const errors = Array.isArray(errorMessages) ? errorMessages : [errorMessages];
    errors.forEach((error) => {
      if (action.payload.customMessage) {
        toast.error(action.payload.customMessage);
      } else {
        let errorMessage = typeof error === 'string' ? error : JSON.stringify(error);
        if (error.errorCode?.match(/openapi.requestValidation$/)) {
          errorMessage = `The request ${error.location} item ${error.path} ${error.message}`;
        }
        toast.error(errorMessage);
      }
    });
  }
}

export default function* commonSagas() {
  yield takeLatest(CommonActions.HANDLE_REQUEST_ERROR, handleRequestErrorSaga);
}
