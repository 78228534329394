export interface LocationPart {
  _id: string;
  name: string;
}

export enum LocationType {
  ALL = 'All',
  EVENT_ORIGIN = 'EventOrigin',
  DEFINED_LOCATION = 'DefinedLocation',
  VARIABLE = 'Variable',
}

interface LocationSettingBase {
  type: LocationType;
  levelId: string;
}

export interface AllLocationSetting extends LocationSettingBase {
  type: LocationType.ALL;
}

export interface EventOriginLocationSetting extends LocationSettingBase {
  type: LocationType.EVENT_ORIGIN;
}

export interface DefinedLocationSetting extends LocationSettingBase {
  type: LocationType.DEFINED_LOCATION;
  partId: string;
}

export interface VariableLocationSetting extends LocationSettingBase {
  type: LocationType.VARIABLE;
  variable: string; // should start with $
}

export type LocationSetting =
  | AllLocationSetting
  | EventOriginLocationSetting
  | DefinedLocationSetting
  | VariableLocationSetting;

interface GlobalVariableLocationSetting {
  isVariable: true;
  locationIdVariable: string;
}

interface GlobalFixedLocationSetting {
  isVariable: false;
  locationSettings: LocationSetting[];
}

export type GlobalLocationSettings = GlobalVariableLocationSetting | GlobalFixedLocationSetting;

export interface LocationStructureItemPOST {
  _id?: string;
  name: string;
}

export interface LocationStructurePOST {
  locationStructure: LocationStructureItemPOST[];
}

export interface LocationStructureCanDeleteGET {
  [key: string]: {
    canDelete: boolean;
    rules: {
      _id: string;
      project: string;
      name: string;
    }[];
  };
}
