import { takeLatest } from 'redux-saga/effects';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';

import { RolesActions } from './actions';

export default function* rolesSagas() {
  yield takeLatest(RolesActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(RolesActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
