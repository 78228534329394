export enum StepTypes {
  // INFO
  INFO_AUDIO = 'info-audio',
  INFO_PDF = 'info-pdf',
  INFO_PHOTO = 'info-photo',
  INFO_QRCODE = 'info-qrcode',
  INFO_STATUS = 'info-status',
  INFO_TEXT = 'info-text',
  INFO_TEXT_LARGE = 'info-text-large',
  INFO_LOCATION_DIRECTION = 'info-location-direction',
  INFO_WEBVIEW = 'info-webview',
  INFO_VIDEO = 'info-video',
  INFO_EMPTY = 'info-empty',
  INFO_LOADING = 'info-status-auto',
  INFO_TASK = 'info-task',

  // INPUT
  INPUT_AUDIO = 'input-audio',
  INPUT_BARCODE = 'input-barcode',
  INPUT_CHECKBOX_LIST = 'input-checkbox-list',
  INPUT_INDEX_LIST = 'input-index-list',
  INPUT_TILE_LIST = 'input-tile-list',
  INPUT_NUMBER = 'input-number',
  INPUT_NUMBER_PICKER = 'input-number-picker',
  INPUT_PHOTO = 'input-photo',
  INPUT_STEP_MENU = 'input-step-menu',
  INPUT_TEXT = 'input-text',
  INPUT_LOCATION = 'input-location',
  INPUT_NOISE_LEVEL = 'input-noise-level',
  INPUT_VALUE_LIST = 'input-value-list',
  INPUT_VIDEO = 'input-video',
  INPUT_BUTTON_LIST = 'input-button-list',
  // LAYOUT
  LAYOUT_ASSEMBLY_PICTURE = 'layout-assembly-picture',
  LAYOUT_ASSEMBLY_VIDEO = 'layout-assembly-video',
  LAYOUT_ASSEMBLY_DETAIL = 'layout-assembly-detail',
  LAYOUT_SCREW_FITTING = 'layout-screw-fitting',
  LAYOUT_FLEX = 'layout-flex',
  // MODULE
  MODULE = 'module',
}
