import React, { FC, useState } from 'react';
import { uid } from 'react-uid';
import { useApiRequest } from 'hooks/useRequest';
import { Divider } from '@mui/material';
import { deleteMedia } from 'services/networking/media';
import { WuiFileDropzone } from '../WuiFileDropzone';
import { WuiFileInputItem } from './WuiFileInputItem';
import { FileOrigin, WuiFileInputFile, WuiFileInputValue } from './types';
import { GoogleDriveFile, GoogleDrivePicker } from '../../components/UploadFiles/GoogleDrivePicker/GoogleDrivePicker';

interface WuiFileInputProps {
  multiple?: boolean;
  hardDelete?: boolean;
  accept: string[];
  medias?: WuiFileInputValue[];
  error?: boolean;
  googleDriveUploadEnabled?: boolean;
  onChange: (files: WuiFileInputValue[]) => void;
  /** Sends the mediaIds that were deleted either soft or hard */
  onDelete: (mediaId: string) => void;
}

export const WuiFileInput: FC<WuiFileInputProps> = ({
  multiple = false,
  accept,
  medias = [],
  hardDelete = false,
  error,
  googleDriveUploadEnabled = false,
  onChange,
  onDelete,
}) => {
  const [fileUploads, setFileUploads] = useState<WuiFileInputFile[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteMediaRequest = useApiRequest(deleteMedia, {
    manual: true,
    onSuccess: (_params, [mediaId]) => onDelete(mediaId),
  });

  const handleOnDropFromComputer = async (newFiles: File[]) => {
    setFileUploads([
      ...fileUploads,
      ...newFiles.map<WuiFileInputFile>((file) => ({ file, origin: FileOrigin.COMPUTER })),
    ]);
  };

  const handleOnDropFromGoogleDrive = async (options: { files: GoogleDriveFile[]; accessToken: string }) => {
    setFileUploads([
      ...fileUploads,
      ...options.files.map<WuiFileInputFile>((file) => ({
        file: { ...file },
        accessToken: options.accessToken,
        origin: FileOrigin.GOOGLE_DRIVE,
      })),
    ]);
  };

  const handleSuccessFile = (fileIndex: number) => (media: WuiFileInputValue) => {
    if (multiple) {
      onChange([...medias, media]);
    } else {
      onChange([media]);
    }
    handleDeleteFile(fileIndex)();
  };

  const handleDeleteFile = (fileIndex: number) => (mediaId?: string) => {
    if (mediaId) {
      if (hardDelete) {
        deleteMediaRequest.run(mediaId);
      } else {
        onDelete(mediaId);
      }
    }
    setFileUploads((oldFiles) => {
      const newFiles = [...oldFiles];
      newFiles.splice(fileIndex, 1);
      return newFiles;
    });
  };

  const handleDeleteMedia = (mediaIndex: number) => () => {
    const newMedias = [...medias];
    const [deletedMedia] = newMedias.splice(mediaIndex, 1);
    onChange(newMedias);
    onDelete(deletedMedia.id);
  };

  return (
    <>
      {(multiple || !medias.length) && googleDriveUploadEnabled && (
        <>
          <GoogleDrivePicker multiple={multiple} onFilesSelected={handleOnDropFromGoogleDrive} />
          <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        </>
      )}
      {(multiple || !medias.length) && (
        <WuiFileDropzone accept={accept} onDrop={handleOnDropFromComputer} multiple={multiple} error={error} />
      )}
      {medias.map((media, index) => (
        <WuiFileInputItem key={uid(media)} file={media} onSuccess={() => {}} onDelete={handleDeleteMedia(index)} />
      ))}
      {fileUploads.map((file, index) => (
        <WuiFileInputItem
          key={uid(file)}
          file={file}
          onSuccess={handleSuccessFile(index)}
          onDelete={handleDeleteFile(index)}
        />
      ))}
    </>
  );
};
