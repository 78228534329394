// eslint-disable-next-line max-classes-per-file
import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsEnum, ValidateNested } from 'class-validator';
import { ClassValidatorOptions } from '../../utils/ClassValidatorOptions';
import { ListOptionsSource } from '../../../domain/workinstruction/step/ListOptionsSource.enum';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputListOption, InputableStep } from './base';

@Schema({ _id: false })
export class InputStepMenuOption extends InputListOption {}

@Schema()
export class InputStepMenu extends InputableStep {
  type: StepTypes.INPUT_STEP_MENU;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop({
    type: String,
    enum: [ListOptionsSource.OPTIONS],
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop([{ type: InputStepMenuOption }])
  @ValidateNested()
  options: InputStepMenuOption[];
}
