import React, { ReactNode, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Collapse from '@mui/material/Collapse';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import SwitchWithLabel from 'components/Input/SwitchWithLabel';

import styles from './Field.module.scss';

export interface FieldProps {
  children?: ReactNode;
  label?: string;
  labelIsTranslationId?: boolean;
  fieldName?: string;
  error?: string | null; // can be a translationId
  successMessage?: string | null; // can be a translationId
  description?: string;
  required?: boolean;
  tooltipTranslationId?: string;
  isEnabled?: boolean;
  onToggle?: (isEnabled: boolean) => void;
  shouldCollapseOnToggle?: boolean;
  toggleTranslationId?: string;
  scrollableHorizontal?: boolean;
}

export const Field: React.FC<FieldProps> = ({
  fieldName,
  label,
  error,
  successMessage,
  description,
  required = false,
  children,
  tooltipTranslationId,
  isEnabled = true,
  onToggle,
  labelIsTranslationId = true,
  shouldCollapseOnToggle = true,
  toggleTranslationId = '',
  scrollableHorizontal = false,
}) => {
  const intl = useIntl();

  const [isFieldEnabled, setIsFieldEnabled] = useState(isEnabled);

  useEffect(() => {
    setIsFieldEnabled(isEnabled);
  }, [isEnabled]);

  const handleToggleClick = (checked: boolean) => {
    if (onToggle) {
      onToggle(checked);
      setIsFieldEnabled(checked);
    }
  };

  const getLabel = () => {
    if (label) {
      return (
        <label
          className={`${styles.label} ${onToggle ? styles.clickable : ''}`}
          htmlFor={fieldName}
          onClick={() => handleToggleClick(!isFieldEnabled)}
        >
          {labelIsTranslationId ? intl.formatMessage({ id: label }) : label}
          {required && '*'}
          {tooltipTranslationId && (
            <div className={styles.tooltip}>
              <Tooltip title={intl.formatMessage({ id: tooltipTranslationId })}>
                <IconButton aria-label="tooltip" size="small" color="inherit" edge="start">
                  <HelpOutlineIcon fontSize="inherit" color="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </label>
      );
    }
    return '';
  };

  return (
    <div className={`${styles.fieldContainer} ${scrollableHorizontal ? styles.overflowXScrollable : ''}`}>
      <div className={styles.labelContainer}>
        {getLabel()}
        {onToggle && (
          <SwitchWithLabel
            wide={!label}
            labelTranslationId={toggleTranslationId}
            checked={isFieldEnabled}
            onChange={handleToggleClick}
          />
        )}
      </div>
      {children && (
        <Collapse in={isFieldEnabled || !shouldCollapseOnToggle}>
          <div className={`${styles.childrenContainer} ${error ? styles.error : ''}`}>{children}</div>
          {error && <span className={styles.error}>{intl.formatMessage({ id: error, defaultMessage: error })}</span>}
          {!error && successMessage && (
            <span className={styles.success}>
              {intl.formatMessage({
                id: successMessage,
                defaultMessage: successMessage,
              })}
            </span>
          )}
          {description && (
            <span className={styles.description}>
              {intl.formatMessage({
                id: description,
                defaultMessage: description,
              })}
            </span>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default Field;
