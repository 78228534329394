/* eslint-disable no-unsafe-finally */
/* eslint-disable no-param-reassign */
import { Base64 } from '@workerbase/utils/Base64';
import {
  FunctionType,
  FunctionGET,
  FunctionPOST,
  FunctionWizard,
  FunctionPUT,
  FunctionLog,
  FunctionLogGET,
} from '@workerbase/types/api/functions';
import * as Sentry from '@sentry/react';
import { getIntl } from '../../providers/IntlProvider';

const intl = getIntl();

export function normalizeFunction(func: FunctionGET): FunctionType {
  return {
    id: func._id,
    description: func.description || '',
    triggeredAt: func.triggeredAt,
    name: func.name,
    databases: func.databases,
    responsibleRoleIds: func.responsibleRoleIds || [],
    projectId: func.project,
    createdAt: func.meta.createdAt,
    type: func.type,
    updatedAt: func.meta.updatedAt,
    files:
      func.files?.reduce((files, file) => {
        try {
          files[file.filename] = Base64.decode(file.content); // send files as base64
        } catch (error) {
          Sentry.captureException(
            new Error(
              intl?.formatMessage(
                { id: 'functions.file-decode-error' },
                {
                  fileName: file.filename,
                  functionName: func.name,
                },
              ),
            ),
          );
          files[file.filename] = '';
        }
        return files;
      }, {}) || {},

    slug: func.slug,
    deployed: func.deployed || false,
    links: func.links,
    overwriteAPIResponseWithEmptyObject: func.overwriteAPIResponseWithEmptyObject,
  };
}

export function normalizeFunctionLog(functionLog: FunctionLogGET): FunctionLog {
  return {
    id: functionLog._id,
    meta: functionLog.meta,
    functionId: functionLog.functionId,
    payload: functionLog.payload,
    returnValue: functionLog.returnValue,
    error: functionLog.error,
    errorMessage: functionLog.errorMessage,
    errorLogs: functionLog.errorLogs,
    executionDurationInMs: functionLog.executionDurationInMs,
  };
}

export function convertFunctionToFunctionPUT(func: FunctionWizard): FunctionPUT {
  return {
    name: func.name,
    description: func.description,
    databases: func.databases,
    type: func.type,
    responsibleRoleIds: func.responsibleRoleIds,
    files:
      Object.keys(func.files).map((filename) => ({
        filename,
        content: Base64.encode(func.files[filename]), // send files as base64
      })) || {},
    slug: func.slug,
    overwriteAPIResponseWithEmptyObject: func.overwriteAPIResponseWithEmptyObject,
  };
}

export function convertFunctionToFunctionPOST(func: FunctionWizard & { projectId: string }): FunctionPOST {
  return {
    ...convertFunctionToFunctionPUT(func),
    project: func.projectId,
  };
}
