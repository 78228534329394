import { LocationGET } from '@workerbase/types/api/locations';
import { RootState } from '../types';

export const getLocationsOnCurrentPage = (store: RootState) =>
  store.locations.currentListItemsIds.map((id) => store.locations.locationsById[id]);

export const getLocationWithId =
  (locationId?: string) =>
  (store: RootState): LocationGET | undefined =>
    locationId ? store.locations.locationsById[locationId] : undefined;

export const getListConfigs = (store: RootState) => store.locations.listConfigs;
