import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsString, ValidateNested } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { MaterialsList, InfoPhotoStyles, StepBase } from './base';

@Schema()
export class LayoutAssemblyDetail extends StepBase {
  type: StepTypes.LAYOUT_ASSEMBLY_DETAIL;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop({ type: SchemaFactory.createForClass(MaterialsList) })
  @ValidateNested()
  @Type(() => MaterialsList)
  materialsList: MaterialsList;
}
