import React, { FC, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Field from 'components/Input/Field';

import TextField from '@mui/material/TextField';
import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import moment from 'moment';

type PickerType = 'Date' | 'Time' | 'DateTime';
interface Props {
  type: PickerType;
  label: string;
  labelIsTranslationId?: boolean;
  field: {
    name: string;
    onChange: (value) => void;
    value?: string | null;
  };
}

const DataTimeInput: FC<Props> = ({ type, label, labelIsTranslationId, field }) => {
  const [error, setError] = useState<DateTimeValidationError>();

  const getPickerByType = (type: PickerType) => {
    switch (type) {
      case 'DateTime':
        return (
          <DateTimePicker
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputProps={{ ...params.inputProps, placeholder: '____-__-__T__:__:__' }}
              />
            )}
            ampm={false}
            value={field.value?.toString() || null}
            onChange={field.onChange}
            onError={(error, value) => setError(error)}
            showToolbar={false}
            inputFormat="YYYY-MM-DDTHH:mm:ss"
            mask="____-__-__T__:__:__"
          />
        );
      case 'Time':
        return (
          <TimePicker
            renderInput={(params) => (
              <TextField {...params} fullWidth inputProps={{ ...params.inputProps, placeholder: '--:--' }} />
            )}
            ampm={false}
            /* TimePicker only accepts moment object or full datetime string format */
            value={field?.value ? moment(field.value, 'HH:mm') : null}
            onChange={(value) => {
              const formattedValue = moment(value).format('HH:mm');
              return field.onChange(formattedValue);
            }}
            onError={(error, value) => setError(error)}
            showToolbar={false}
            inputFormat="HH:mm"
            mask="__:__"
          />
        );
      case 'Date':
        return (
          <DatePicker
            renderInput={(params) => <TextField {...params} fullWidth />}
            value={field.value?.toString() || null}
            onChange={(value) => {
              const formattedValue = moment(value).format('YYYY-MM-DD');
              return field.onChange(formattedValue);
            }}
            onError={(error, value) => setError(error)}
            inputFormat="DD.MM.YYYY"
            showToolbar={false}
            mask="__.__.____"
          />
        );
      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Field
        error={error ? 'errors.date-time-pickers.invalid-format' : null}
        label={label}
        labelIsTranslationId={labelIsTranslationId}
      >
        {getPickerByType(type)}
      </Field>
    </LocalizationProvider>
  );
};

export default DataTimeInput;
