import * as jwt from 'jsonwebtoken';

type AuthJwtToken = jwt.JwtPayload & { userId: string; iat: number; exp: number };

const isValidAuthJwtTokenPayload = (tokenPayload: jwt.JwtPayload): tokenPayload is AuthJwtToken => {
  const expectedPayloadFields = ['userId', 'iat', 'exp'];

  expectedPayloadFields.forEach((field) => {
    if (!(field in tokenPayload)) {
      throw new Error(`Missing field: ${field} in token`);
    }
  });

  return true;
};

/**
 * Decode and verify token has expected fields.
 */
export const getPayloadFromJwtToken = (token: string): AuthJwtToken => {
  const tokenPayload = jwt.decode(token, { json: true });

  if (!tokenPayload) {
    throw new Error('Token payload not found');
  }

  if (!isValidAuthJwtTokenPayload(tokenPayload)) {
    throw new Error('Token payload is not valid');
  }

  return tokenPayload;
};

/**
 * Decode and extract expiration date from token in milliseconds.
 */
export const getExpirationDateFromJwtToken = (token: string): number => {
  try {
    const tokenPayload = getPayloadFromJwtToken(token);

    return tokenPayload.exp * 1000;
  } catch (e) {
    console.warn('Error getting expiration date from jwt token', e);
    return 0;
  }
};
