import React, { ReactNode } from 'react';

import styles from './SidebarItem.module.scss';

interface Props {
  children: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
}

export const SidebarItem: React.FC<Props> = ({ children, onClick, active = false, disabled = false, icon }) => (
  <li className={styles.sidebarItem}>
    <a
      className={`${active ? styles.active : ''} ${disabled ? styles.disabled : ''}`}
      onClick={(e) => !disabled && onClick && onClick()}
    >
      {icon || null}
      {children}
    </a>
  </li>
);

export default SidebarItem;
