import React, { ReactNode } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

import styles from './SquareButton.module.scss';

interface SquareButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: ReactNode;
  variant: 'regular' | 'danger';
  isDisabled?: boolean;
  tabIndex?: number;
  sx?: SxProps<Theme>;
}

export const SquareButton: React.FC<SquareButtonProps> = ({
  onClick,
  tabIndex,
  icon,
  variant,
  isDisabled = false,
  sx,
}) => {
  let className = styles.button;

  switch (variant) {
    case 'regular':
      className += ` ${styles.regular}`;
      break;
    case 'danger':
      className += ` ${styles.danger}`;
      break;
    default:
      break;
  }

  return (
    <Box sx={sx}>
      <button tabIndex={tabIndex} type="button" className={className} onClick={onClick} disabled={isDisabled}>
        {icon}
      </button>
    </Box>
  );
};
