import { Property } from '@workerbase/types/ListConfig';
import { DatabaseItemsListConfig } from '@workerbase/types/api/databases';

import { RootState } from '../types';
import { getDatabaseItemsListConfigs } from './utils';

export const getListConfigs = (databaseIdOrSlug: string) => (store: RootState) => {
  const paginationConfig = getDatabaseItemsListConfigs(store.databaseItems, databaseIdOrSlug);
  const propertiesConfig = getDatabaseItemsProperties(databaseIdOrSlug)(store);
  const listConfig = getDatabaseItemsListConfig(databaseIdOrSlug)(store);

  return {
    ...paginationConfig,
    properties: propertiesConfig || [],
    filtering: listConfig?.filtering || '',
  };
};

export const getDatabaseItemsProperties =
  (databasesId: string) =>
  (store: RootState): Property[] =>
    store.databaseItems.propertiesByDatabaseId && store.databaseItems.propertiesByDatabaseId[databasesId];

export const getDatabaseItemsListConfig =
  (databasesId: string) =>
  (store: RootState): DatabaseItemsListConfig =>
    store.databaseItems.propertiesByDatabaseId && store.databaseItems.listConfigsByDatabaseId[databasesId];
