export enum AssignmentSkillTypes {
  IDS = 'ids',
  VARIABLES = 'variables',
}

export interface AssignmentSkillID {
  type: AssignmentSkillTypes.IDS;
  skills: string[];
}

export interface AssignmentSkillVariable {
  type: AssignmentSkillTypes.VARIABLES;
  variables: string;
}

export type AssignmentSkills = AssignmentSkillVariable | AssignmentSkillID;
