import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InfoMediaStepBase } from './base/InfoMediaStepBase';

@Schema()
export class InfoPdf extends InfoMediaStepBase {
  type: StepTypes.INFO_PDF;

  @Prop({
    required: true,
    default: '0',
  })
  @IsString()
  page: string;

  @Prop()
  @IsString()
  description: string;

  @Prop({
    default: false,
  })
  @IsBoolean()
  slideModeEnabled: boolean;
}
