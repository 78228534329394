import { call, put, takeLatest, select } from 'redux-saga/effects';

import { MqttTopics } from '@workerbase/types/MQTT/MqttTopics';

import { getFunctionLogs } from 'services/networking/functions';
import { FunctionLog } from '@workerbase/types/api/functions';
import { PaginationMeta } from '@workerbase/types/Response';

import { mqttSubscribe, mqttUnsubscribe } from '@redux/Mqtt';
import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { handleRequestError } from '../common';

import { getListConfigs } from './selectors';
import {
  getFunctionLogsRequestAction,
  getFunctionLogsSuccess,
  getFunctionLogsError,
  FunctionLogsActions,
  FunctionCallsSubscribeAction,
  IncomingMqttFunctionCallAction,
} from './actions';

export function* getFunctionLogsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const functionId = action.payload.functionId;

    const response = yield call(
      getFunctionLogs,
      functionId,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
      listConfigs.filtering.searchTerms,
    );
    const functionlogs: FunctionLog[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getFunctionLogsSuccess(functionlogs, meta));
  } catch (error) {
    yield put(getFunctionLogsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* subscribeToMqttSaga(action: FunctionCallsSubscribeAction) {
  try {
    yield put(
      mqttSubscribe(
        MqttTopics.FUNCTION_CALL.replace('+', action.payload.functionId),
        FunctionLogsActions.HANDLE_INCOMING_MQTT_FUNCTION_CALLS,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* unsubscribeToMqttSaga(action: FunctionCallsSubscribeAction) {
  try {
    yield put(
      mqttUnsubscribe(
        MqttTopics.FUNCTION_CALL.replace('+', action.payload.functionId),
        FunctionLogsActions.HANDLE_INCOMING_MQTT_FUNCTION_CALLS,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* handleIncomingMqttFunctionCallSaga(action: IncomingMqttFunctionCallAction) {
  try {
    yield put(getFunctionLogsRequestAction(action.payload.message.function._id));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* functionlogsSagas() {
  yield takeLatest(FunctionLogsActions.GET_FUNCTIONLOGS_REQUEST, getFunctionLogsRequestSaga);
  yield takeLatest(FunctionLogsActions.MQTT_SUBSCRIBE_FUNCTION_CALLS, subscribeToMqttSaga);
  yield takeLatest(FunctionLogsActions.MQTT_UNSUBSCRIBE_FUNCTION_CALLS, unsubscribeToMqttSaga);
  yield takeLatest(FunctionLogsActions.HANDLE_INCOMING_MQTT_FUNCTION_CALLS, handleIncomingMqttFunctionCallSaga);
  yield takeLatest(FunctionLogsActions.UPDATE_SORTING, updateListConfigSaga);
}
