import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsNumber } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputableStep } from './base/InputableStep';

@Schema()
export class InputNoiseLevel extends InputableStep {
  type: StepTypes.INPUT_NOISE_LEVEL;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  autoStartMeasurement: boolean;

  @Prop()
  @IsBoolean()
  inputRequired: boolean;

  @Prop()
  @IsNumber()
  warningThresholdInDecibel: number;

  @Prop()
  @IsNumber()
  measureDurationInSec: number;
}
