import React, { ChangeEvent, FocusEvent, useCallback, FC } from 'react';
import { isString } from 'lodash';
import { useIntl } from 'react-intl';
import { FormControl, FormHelperText, FormControlProps, Skeleton } from '@mui/material';
import { MentionsInput, Mention, MentionItem, MentionProps, MentionsInputProps } from 'react-mentions';

import { WuiFormLabel } from '@uiKit';
import { inputSizes } from '../../stylesheet';
import { convertMentionsToSuggestionDataItem, convertValueToMentionsFormat } from './utils';
import { getMultilineFieldMinHeight, MentionsInputContainer } from './styled';
import { MENTIONS_CORE_CLASSNAME, MENTIONS_REGEX } from './constants';

/**
 * Component always outputs string without the mention formatting - @[display](id).
 * We need to add mention formatting on input and to remove it on output using trigger as prefix.
 * Mention values are shown in the suggestion list with prefix in the beginning, eg 'capacity' -> '$capacity'
 *
 * Example with given prefix: "$"
 * "Hello $caller" -> "Hello @[$caller]($caller)" -> "Hello $caller"
 */

export interface WuiMentionTextFieldProps {
  name: string;
  mentions: string[];
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

  className?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  /** can be translation id */
  helperText?: string;
  id?: string;
  label?: string;
  /**
   * If `true`, the label and animated skeleton will be shown
   * @default false
   */
  loading?: boolean;
  multiline?: boolean;
  /**
   * Only applicable if multiline={true}
   * @default 4
   */
  minRows?: number;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  renderSuggestion?: MentionProps['renderSuggestion'];
  required?: boolean;
  sx?: FormControlProps['sx'];
  trigger?: string;
  value?: string;
}

export const WuiMentionTextField: FC<WuiMentionTextFieldProps> = ({
  name,
  mentions,
  onChange,

  className,
  disabled,
  error,
  fullWidth,
  helperText,
  id,
  label,
  loading,
  multiline = false,
  minRows = 4,
  onBlur,
  placeholder,
  renderSuggestion,
  required,
  sx,
  trigger = '$',
  value = '',
}) => {
  const intl = useIntl();
  const translatedHelperText = isString(helperText)
    ? intl.formatMessage({
        id: helperText,
        defaultMessage: helperText,
      })
    : helperText;

  const handleChange: MentionsInputProps['onChange'] = useCallback(
    (
      /*
       * This is the event sent: { target: { value: string } } but Formik
       * expects a "real" ChangeEvent, so I don't put any type
       */
      event,
      _newValue: string,
      _newPlainTextValue: string,
      _mentions: MentionItem[],
    ) => {
      /**
       * This is needed for Formik.
       * If the event does not have a name, Formik can't update its state.
       * MentionsInput does not send a proper Event object, so we need to manually add this value.
       */
      event.target.name = name;

      /**
       * Replaces mentions @[displayValue](idValue) with display value in format `${trigger}{value}`
       * Example, "Option @[$varName](123) test" -> "Option $varName test"
       */
      event.target.value = event.target.value.replace(MENTIONS_REGEX, '$1');

      onChange(event);
    },
    [onChange, name],
  );

  return (
    <FormControl className={className} sx={sx} fullWidth={fullWidth} style={{ maxWidth: '100%' }}>
      {label && (
        <WuiFormLabel disabled={disabled} required={required} focused={false} htmlFor={(id ?? name) as string}>
          {label}
        </WuiFormLabel>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={multiline ? getMultilineFieldMinHeight(minRows) : inputSizes.normal.height}
        />
      ) : (
        <>
          <MentionsInputContainer multiline={multiline} minRows={minRows} error={error} disabled={disabled}>
            <MentionsInput
              allowSuggestionsAboveCursor
              autoComplete="off"
              className={MENTIONS_CORE_CLASSNAME}
              disabled={disabled}
              id={id ?? name}
              name={name}
              onBlur={onBlur}
              onChange={handleChange}
              placeholder={placeholder}
              singleLine={!multiline}
              value={convertValueToMentionsFormat({ value, trigger, mentions })}
            >
              <Mention
                className="test"
                data={convertMentionsToSuggestionDataItem(mentions, trigger)}
                renderSuggestion={renderSuggestion}
                trigger={trigger}
              />
            </MentionsInput>
          </MentionsInputContainer>
          {translatedHelperText && <FormHelperText error={error}>{translatedHelperText}</FormHelperText>}
        </>
      )}
    </FormControl>
  );
};
