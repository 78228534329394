export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  TRACE = 'TRACE',
  OPTIONS = 'OPTIONS',
  CONNECT = 'CONNECT',
}

export enum ReqMethod {
  GET = HTTPMethod.GET,
  POST = HTTPMethod.POST,
  PUT = HTTPMethod.PUT,
  DELETE = HTTPMethod.DELETE,
}

export enum ReqPayloadFormat {
  JSON = 'JSON',
  XML = 'XML',
  FORM = 'FORM',
}

export interface ReqHeaders {
  name: string;
  value: string;
  type: string;
}

export enum ReqPayloadType {
  PLAIN = 'plain',
  VARIABLE = 'variable',
}
export interface ReqPayload {
  mapFrom: string;
  mapTo: string;
  type: ReqPayloadType;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortBy {
  property: string;
  order: SortOrder;
}
