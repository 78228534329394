import { RoleGET } from '@workerbase/types/api/roles';
import { Role } from '../types/Role';

export const normalizeRole = (role: RoleGET): Role => ({
  id: role._id,
  description: role.description,
  name: role.name,
  apps: role.apps,
  deviceApps: role.deviceApps,
});
