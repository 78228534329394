import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { GraphQLOperators } from '@workerbase/types/graphql/GraphQLOperators';
import { ListConfigs } from '@workerbase/types/ListConfig';

import {
  getProjects,
  createProject,
  getProjectById,
  updateProjectById,
  deleteProjectById,
  importProjectRequest,
  restoreProject,
  updateProjectConfig,
} from 'services/networking/projects';
import { Project } from 'services/types/Project';
import { PaginationMeta } from '@workerbase/types/Response';

import { getTasksRequestAction } from '@redux/Tasks';
import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { getIsRootAdministrator, getUserRoleIds } from '@redux/Login';
import { handleRequestError } from '../common';

import { getListConfigs, getProjectWithId } from './selectors';

import {
  getProjectsRequestAction,
  getProjectsSuccess,
  getProjectsError,
  getProjectByIdSuccess,
  getProjectByIdError,
  deleteProjectByIdError,
  importProjectError,
  importProjectSuccess,
  ProjectsActions,
  CreateOrUpdateProjectRequestAction,
  UpdateProjectByIdRequestAction,
} from './actions';

export function* getProjectsRequestSaga(action) {
  const isRootAdmin: boolean = yield select(getIsRootAdministrator);
  const userRoles: string[] = yield select(getUserRoleIds);

  try {
    const listConfigs: ListConfigs = yield select(getListConfigs);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterQuery: Record<string, any> = {
      hide: { [GraphQLOperators.EQ]: false },
      isSystem: { [GraphQLOperators.NE]: true },
    };

    if (!isRootAdmin) {
      filterQuery[GraphQLOperators.OR] = {
        roles: {
          [GraphQLOperators.IN]: userRoles,
        },
        adminRoles: {
          [GraphQLOperators.IN]: userRoles,
        },
      };
    }

    const fields = listConfigs.properties?.filter((prop) => !prop.omit).map((prop) => prop.selector);

    // @ts-ignore -- to be refactored, smth is wrong with the types
    const response = yield call(getProjects, {
      page: listConfigs.pagination?.currentPage,
      perPage: listConfigs.pagination?.itemsPerPage,
      fields,
      sorting: listConfigs.sorting,
      filtering: listConfigs.filtering.searchTerms,
      filterQuery,
    });

    const projects: Project[] = response?.data;
    const meta: PaginationMeta = response?.meta;
    const errors = response?.errors;

    if (errors) {
      const errorMessages = errors.map((err) => err.message);
      yield put(getProjectsError(errorMessages));
      yield put(handleRequestError({ message: errorMessages }));
    }

    yield put(getProjectsSuccess(projects, meta));
  } catch (error) {
    yield put(getProjectsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createProjectRequestSaga(action: CreateOrUpdateProjectRequestAction) {
  try {
    const project: Project = yield call(createProject, action.payload.project);
    yield call(toast.success, 'Project created');
    yield put(push(`/projects/${project.id}/tasks`));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* getProjectByIdRequestSaga(action) {
  try {
    const project: Project = yield call(getProjectById, action.payload.projectId);
    yield put(getProjectByIdSuccess(project));
  } catch (error) {
    yield put(getProjectByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* updateProjectByIdRequestSaga(action: UpdateProjectByIdRequestAction) {
  try {
    const project: Project = yield call(updateProjectById, action.payload.projectId, action.payload.project);
    yield put(getProjectByIdSuccess(project));
    yield put(push(`/projects/${project.id}/tasks`));
    yield call(toast.success, 'Project updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* deleteProjectByIdRequestSaga(action) {
  try {
    yield call(deleteProjectById, action.payload.projectId);

    yield call(toast.success, 'Project deleted');
    yield put(getProjectsRequestAction());
  } catch (error) {
    yield put(deleteProjectByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* importProjectSaga(action) {
  try {
    yield call(importProjectRequest, action.payload.file);
    yield call(toast.success, 'Project imported');
    yield put(importProjectSuccess());
  } catch (error) {
    yield put(importProjectError((error as Error).message));
    yield put(handleRequestError(error));
  } finally {
    yield put(push(`/projects`));
  }
}

export function* restoreProjectSaga(action) {
  try {
    const response = yield call(
      restoreProject,
      action.payload.projectRestoreIds,
      action.payload.userId,
      action.payload.file,
    );

    if (response.data.success) {
      yield call(toast.success, 'Project restore successful');
      yield put(push(`/projects/${action.payload.projectId}/tasks`));
    } else {
      yield put(handleRequestError('Project restore failed'));
    }
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateTaskFieldsSaga(action) {
  try {
    const project = yield select(getProjectWithId(action.payload.projectId));
    yield put(getTasksRequestAction({ projectId: action.payload.projectId }));
    yield call(updateProjectConfig, action.payload.projectId, {
      ...project.config,
      taskDetailsFilter: action.payload.fields,
    });
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* projectsSagas() {
  yield takeLatest(
    [ProjectsActions.GET_PROJECTS_REQUEST, ProjectsActions.UPDATE_LISTCONFIG_PROPERTIES],
    getProjectsRequestSaga,
  );
  yield takeLatest(ProjectsActions.GET_PROJECT_BY_ID_REQUEST, getProjectByIdRequestSaga);
  yield takeLatest(ProjectsActions.CREATE_PROJECT_REQUEST, createProjectRequestSaga);
  yield takeLatest(ProjectsActions.DELETE_PROJECT_BY_ID_REQUEST, deleteProjectByIdRequestSaga);
  yield takeLatest(ProjectsActions.UPDATE_PROJECT_BY_ID_REQUEST, updateProjectByIdRequestSaga);
  yield takeLatest(ProjectsActions.IMPORT_PROJECT, importProjectSaga);
  yield takeLatest(ProjectsActions.RESTORE_PROJECT, restoreProjectSaga);
  yield takeLatest(ProjectsActions.UPDATE_TASK_FIELDS, updateTaskFieldsSaga);
  yield takeLatest(ProjectsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(ProjectsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
