// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderNavigation-module__print___EQntQ{overflow:visible !important}.HeaderNavigation-module__header___OACmJ{height:56px;display:flex;align-items:center;justify-content:space-between;padding:0 24px}.HeaderNavigation-module__header___OACmJ nav{font-family:Proxima Nova,serif;font-weight:600;line-height:20px;letter-spacing:0px;font-size:16px;display:flex}.HeaderNavigation-module__logoLink____qQNd{display:flex}.HeaderNavigation-module__logoLink____qQNd img{height:38px}.HeaderNavigation-module__activeColor___TUu61,.HeaderNavigation-module__activeNestedLink___ipGDc,.HeaderNavigation-module__active___s9lZO{color:#174dca !important}.HeaderNavigation-module__active___s9lZO{border-bottom:2px solid #174dca !important;box-sizing:border-box}.HeaderNavigation-module__activeNestedLink___ipGDc{box-sizing:border-box;font-weight:600}.HeaderNavigation-module__activeNestedLink___ipGDc *{font-weight:inherit;color:inherit}nav a:hover{color:#174dca;border-bottom:2px solid #174dca;box-sizing:border-box}nav a,.HeaderNavigation-module__navItem___MC4rx{display:flex;align-items:center;color:#252a37;margin-right:20px;text-decoration:none;border-bottom:2px solid rgba(0,0,0,0);box-sizing:border-box;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `HeaderNavigation-module__print___EQntQ`,
	"header": `HeaderNavigation-module__header___OACmJ`,
	"logoLink": `HeaderNavigation-module__logoLink____qQNd`,
	"activeColor": `HeaderNavigation-module__activeColor___TUu61`,
	"activeNestedLink": `HeaderNavigation-module__activeNestedLink___ipGDc`,
	"active": `HeaderNavigation-module__active___s9lZO`,
	"navItem": `HeaderNavigation-module__navItem___MC4rx`
};
export default ___CSS_LOADER_EXPORT___;
