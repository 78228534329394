import { LocationSetting } from 'services/types/Location';

export enum RecipientTypes {
  ROLES = 'roles',
  USER = 'user',
  VARIABLES = 'variables',
  CALLER = 'caller',
}

export interface RecipientRoles {
  type: RecipientTypes.ROLES;
  roles: string[];
}
export interface RecipientUser {
  type: RecipientTypes.USER;
  isAssignedUserVar: false;
  assignedUser: string;
}

export interface RecipientVariables {
  type: RecipientTypes.VARIABLES;
  variables: string;
}

export interface RecipientCaller {
  type: RecipientTypes.CALLER;
  selfinstruction: true;
}

export type RecipientWithoutCaller = RecipientRoles | RecipientUser | RecipientVariables;

export type Recipient = RecipientRoles | RecipientUser | RecipientVariables | RecipientCaller;

// If isAssignedUserVar = true, the location related keys are not needed
export type RecipientPOST = {
  roles?: string[]; // roleIds
  assignedUser?: string;
  isAssignedUserVar?: boolean;
  assignedUserVar?: string[];
  isUserMessage?: boolean;
  isUserTask?: boolean;
  notifyLocations?: LocationSetting[];
  notifyLocationIsVar?: boolean;
  notifyLocationVar?: string;
  selfinstruction: boolean;
};
