import { Prop, Schema } from '@nestjs/mongoose';
import { IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoQrCode extends StepBase {
  type: StepTypes.INFO_QRCODE;

  @Prop({
    required: true,
  })
  @IsString()
  qrcode: string;
}
