import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputableStep } from './base/InputableStep';

@Schema()
export class InputText extends InputableStep {
  type: StepTypes.INPUT_TEXT;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  @IsOptional()
  placeholder?: string;

  @Prop()
  @IsString()
  @IsOptional()
  maxCharacters?: number;

  @Prop()
  @IsBoolean()
  @IsOptional()
  isOptional?: boolean;
}
