import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getConnectorLogs } from 'services/networking/connectors';
import { ConnectorLog } from 'services/types/Connector';
import { PaginationMeta } from '@workerbase/types/Response';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { handleRequestError } from '../common';

import { getListConfigs } from './selectors';

import { getConnectorLogsSuccess, getConnectorLogsError, ConnectorLogsActions } from './actions';

export function* getConnectorLogsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const connectorId = action.payload.connectorId;

    const response = yield call(
      getConnectorLogs,
      connectorId,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
    );

    const connectorlogs: ConnectorLog[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getConnectorLogsSuccess(connectorlogs, meta));
  } catch (error) {
    yield put(getConnectorLogsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export default function* connectorlogsSagas() {
  yield takeLatest(ConnectorLogsActions.GET_CONNECTORLOGS_REQUEST, getConnectorLogsRequestSaga);
  yield takeLatest(ConnectorLogsActions.UPDATE_SORTING, updateListConfigSaga);
}
