// eslint-disable-next-line max-classes-per-file
import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputListOption, InputListSearchableStep, ListOptions } from './base';

@Schema({ _id: false })
export class InputValueListOption extends InputListOption {}

@Schema()
export class InputValueList extends InputListSearchableStep {
  type: StepTypes.INPUT_VALUE_LIST;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsBoolean()
  customNextStep: boolean;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputValueListOption>;
}
