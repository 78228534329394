export interface BaseAction {
  type: string;
  payload?;
}
export enum CommonActions {
  HANDLE_REQUEST_ERROR = '@@common/HANDLE_REQUEST_ERROR',
}
export const handleRequestError = (error, customMessage?: string): BaseAction => ({
  type: CommonActions.HANDLE_REQUEST_ERROR,
  payload: { error, customMessage },
});
