import React, { ReactNode } from 'react';

import styles from './SidebarList.module.scss';

interface Props {
  children: ReactNode[] | ReactNode;
}

export const SidebarList: React.FC<Props> = ({ children }) => <ul className={styles.sidebarList}>{children}</ul>;

export default SidebarList;
