import { MessageHandler } from './mqttClient';

export enum MqttActions {
  MQTT_LOGIN = '@@mqtt/MQTT_LOGIN',
  MQTT_LOGOUT = '@@mqtt/MQTT_LOGOUT',
  MQTT_CONNECTED = '@@mqtt/MQTT_CONNECTED',
  MQTT_DISCONNECTED = '@@mqtt/MQTT_DISCONNECTED',
  MQTT_ERROR = '@@mqtt/MQTT_ERROR',
  MQTT_SUBSCRIBE = '@@mqtt/MQTT_SUBSCRIBE',
  MQTT_UPDATE_CALLBACK = '@@mqtt/MQTT_UPDATE_CALLBACK',
  MQTT_UNSUBSCRIBE = '@@mqtt/MQTT_UNSUBSCRIBE',
  MQTT_PUBLISH = '@@mqtt/MQTT_PUBLISH',
}

export const mqttSubscribe = (topic: string, actionTypeCallback: string) => ({
  type: MqttActions.MQTT_SUBSCRIBE,
  payload: { topic, actionTypeCallback },
});

export const mqttSubscribeWithCallback = (topic: string, callback: MessageHandler['callback'], keyName: string) => ({
  type: MqttActions.MQTT_SUBSCRIBE,
  payload: { topic, callback, keyName },
});

export const mqttUnsubscribe = (topic: string, actionTypeCallback: string) => ({
  type: MqttActions.MQTT_UNSUBSCRIBE,
  payload: { topic, actionTypeCallback },
});

export const mqttUpdateCallback = (topic: string, callback: MessageHandler['callback'], keyName: string) => ({
  type: MqttActions.MQTT_UPDATE_CALLBACK,
  payload: { topic, callback, keyName },
});

export const mqttUnsubscribeFromCallback = (topic: string, callback: MessageHandler['callback'], keyName: string) => ({
  type: MqttActions.MQTT_UNSUBSCRIBE,
  payload: { topic, callback, keyName },
});

export const mqttPublish = (topic: string, message: unknown) => ({
  type: MqttActions.MQTT_PUBLISH,
  payload: { topic, message },
});

export const mqttConnected = () => ({
  type: MqttActions.MQTT_CONNECTED,
});

export const mqttDisconnected = () => ({
  type: MqttActions.MQTT_DISCONNECTED,
});

export const handleMqttError = ({ error, errorLoginToken }: { error: Error; errorLoginToken?: string }) => ({
  type: MqttActions.MQTT_ERROR,
  payload: { error, errorLoginToken },
});

export const mqttLogin = () => ({
  type: MqttActions.MQTT_LOGIN,
});

export const mqttLogout = () => ({
  type: MqttActions.MQTT_LOGOUT,
});
