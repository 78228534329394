import React, { useState, MouseEvent, ReactElement, Fragment, FC } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, PopoverOrigin, SxProps, Theme, Tooltip, Typography } from '@mui/material';

export interface Action {
  label: ReactElement | string;
  id?: string;
  icon?: ReactElement;
  onClick?: () => void;
}

export interface MenuSxProps {
  root?: SxProps<Theme>;
  icon?: SxProps<Theme>;
  paper?: SxProps<Theme>;
  menu?: SxProps<Theme>;
  menuItem?: SxProps<Theme>;
  itemIcon?: SxProps<Theme>;
  label?: SxProps<Theme>;
}

export enum WuiActionMenuPosition {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}
interface WuiActionMenuProps {
  menuActions: Action[];
  sx?: MenuSxProps;
  anchorOrigin?: WuiActionMenuPosition;
  popupOrigin?: WuiActionMenuPosition;
}

const getPositionObject = (position: WuiActionMenuPosition): PopoverOrigin => {
  const [vertical, horizontal] = position.split('-');
  return {
    vertical: vertical as 'bottom' | 'top' | 'center',
    horizontal: horizontal as 'left' | 'right' | 'center',
  };
};

// TODO: Make sure this item is not depending on a row or anything and reuse it
export const WuiActionMenu: FC<WuiActionMenuProps> = ({
  menuActions,
  sx,
  anchorOrigin = WuiActionMenuPosition.BottomRight,
  popupOrigin = WuiActionMenuPosition.BottomRight,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} sx={sx?.icon}>
        <MoreVert />
      </IconButton>
      <Menu
        id="menu"
        slotProps={{
          paper: { sx: { minWidth: '220px', maxWidth: '320px', ...sx?.paper } },
          root: { sx: sx?.root },
        }}
        anchorOrigin={getPositionObject(anchorOrigin)}
        transformOrigin={getPositionObject(popupOrigin)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={sx?.menu}
      >
        {/* Tooltip showing the full label content if the label gets truncated/ellipses */}
        {menuActions.map((rowAction, i) => (
          <Tooltip
            key={rowAction.id ?? i}
            title={typeof rowAction.label === 'string' ? rowAction.label : ''}
            sx={(theme) => ({
              '& li:last-child': {
                borderBottom: 'unset',
              },
              border: `1px solid ${theme.palette.grey[400]}`,
            })}
            placement="left"
            enterDelay={1000}
          >
            <MenuItem
              onClick={() => {
                rowAction.onClick?.();
                setAnchorEl(null);
              }}
              sx={{
                height: '36px',
                ...sx?.menuItem,
              }}
            >
              {rowAction.icon ? (
                <IconButton disableRipple size="small" sx={{ mr: 1, ...sx?.itemIcon }}>
                  {rowAction.icon}
                </IconButton>
              ) : null}
              <Typography variant="inherit" noWrap sx={sx?.label}>
                {rowAction.label}
              </Typography>
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};
