import { ListConfigs } from '@workerbase/types/ListConfig';
import { Workinstruction } from 'services/types/Workinstruction';

import { RootState } from '../types';

export const getWorkinstructionsOnCurrentPage = (store: RootState) =>
  store.workinstructions.currentListItemsIds.map((id) => store.workinstructions.workinstructionsById[id]);

export const getListConfigs = (store: RootState): ListConfigs => store.workinstructions.listConfigs;

export const getWorkinstructionWithId =
  (workinstructionId?: string) =>
  (store: RootState): Workinstruction | undefined =>
    workinstructionId ? store.workinstructions.workinstructionsById[workinstructionId] : undefined;
