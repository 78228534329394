import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { TaskCategoryKeys } from '@workerbase/types/TaskCategoryKeys';
import { GraphQLOperators } from '@workerbase/types/graphql/GraphQLOperators';
import { MqttTopics } from '@workerbase/types/MQTT/MqttTopics';

import { mqttSubscribe, mqttUnsubscribe } from '@redux/Mqtt';
import { getProjectTaskFields } from '@redux/Projects/selectors';
import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';

import { getTasks, getTasksStats, deleteTaskById, assignTaskToUserId, createTask } from 'services/networking/tasks';
import { Task, TaskMQTT, TaskStatus } from 'services/types/Task';

import { isNil } from 'lodash';

import { mapFilterToGraphQLQuery } from '@workerbase/modules/filter/mapFilterToGraphQLQuery';
import { getListConfigs, getProjectSubscribed } from './selectors';
import {
  getTasksSuccess,
  getTasksError,
  TasksActions,
  deleteTaskByIdSuccess,
  deleteTaskByIdError,
  AssignTaskToUserIdRequestAction,
  CreateTaskRequestAction,
  HandleIncomingMqttTaskAction,
  getTaskByIdSuccess,
  getTasksRequestAction,
  GetTasksRequestAction,
} from './actions';
import { handleRequestError } from '../common';

const MAX_TASK_PRECISE_COUNT = 10000;

export function* getTasksRequestSaga(action: GetTasksRequestAction) {
  try {
    const listConfigs = yield select(getListConfigs);
    const projectId = action.payload.projectId;

    const additionalPropertiesPaths = yield select(getProjectTaskFields, projectId);

    const categoryKey = listConfigs.tabs?.categoryKey !== TaskCategoryKeys.NO_KEY && listConfigs.tabs?.categoryKey;

    // We only get precise count for small values, after that it does not make sense
    const selectedTabCount = listConfigs.tabs.options.find((tab) => tab.key === listConfigs.tabs.selectedTabKey)?.count;
    const getPreciseCount: boolean = !isNil(selectedTabCount) && selectedTabCount < MAX_TASK_PRECISE_COUNT;

    const filterQuery = {
      project: { [GraphQLOperators.EQ]: projectId },
      OR: [
        { isApp: { [GraphQLOperators.EQ]: false } },
        {
          AND: [
            {
              isApp: { [GraphQLOperators.EQ]: true },
            },
            { visibleWhenInProgress: { [GraphQLOperators.EQ]: true } },
          ],
        },

        { status: { [GraphQLOperators.EQ]: TaskStatus.DONE } },
      ],
      ...(action.payload.isArchiveMode
        ? { archived: { [GraphQLOperators.EQ]: true } }
        : { archived: { [GraphQLOperators.EQ]: false } }),
      ...(action.payload.filter ? mapFilterToGraphQLQuery(action.payload.filter, action.payload.conditions) : {}),
    };

    const responsePromise = call(
      getTasks,
      filterQuery,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
      listConfigs.filtering.searchTerms,
      listConfigs.tabs?.selectedTabKey,
      categoryKey,
      additionalPropertiesPaths,
      getPreciseCount,
    );
    const statsPromise = call(getTasksStats, filterQuery, categoryKey);

    const { tasks, meta, errors } = yield responsePromise;
    yield put(getTasksSuccess(tasks, meta));

    if (errors) {
      const errorMessages = errors.map((err) => err.message);
      yield put(getTasksError(errorMessages));
      yield put(handleRequestError({ message: errorMessages }));
    }
    const { stats, errors: statsErrors } = yield statsPromise;

    yield put(
      getTasksSuccess(tasks, {
        ...meta,
        stats,
      }),
    );

    if (statsErrors) {
      put(
        handleRequestError({
          message: statsErrors.map((err) => err.message),
        }),
      );
    }
  } catch (error) {
    yield put(getTasksError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deleteTaskByIdRequestSaga(action) {
  try {
    yield call(deleteTaskById, action.payload.taskId);

    yield call(toast.success, 'Task deleted');
    yield put(deleteTaskByIdSuccess(action.payload.taskId));
  } catch (error) {
    yield put(handleRequestError(error));
    yield put(deleteTaskByIdError((error as Error).message));
  }
}

export function* assignTaskToUserIdRequestSaga(action: AssignTaskToUserIdRequestAction) {
  try {
    yield call(assignTaskToUserId, action.payload.taskId, action.payload.userId);

    yield call(toast.success, 'User assigned to task');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* createTaskRequestSaga(action: CreateTaskRequestAction) {
  try {
    const task: Task = yield call(createTask, action.payload.workinstructionId, action.payload.task);
    yield put(getTaskByIdSuccess(task));
    yield put(push(`/projects/${task.projectId}/tasks`));

    yield call(toast.success, 'Task created');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* subscribeToMqttSaga() {
  try {
    yield put(mqttSubscribe(MqttTopics.TASK, TasksActions.HANDLE_INCOMING_MQTT_TASK));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* unsubscribeToMqttSaga() {
  try {
    yield put(mqttUnsubscribe(MqttTopics.TASK, TasksActions.HANDLE_INCOMING_MQTT_TASK));
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* onIncomingMqttTaskSaga(action: HandleIncomingMqttTaskAction) {
  try {
    const taskData: TaskMQTT = action.payload.message.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code to be refactored
    const taskDiff = (action.payload.message as any).diff;
    const { project: projectId } = taskData;
    const projectSubscribed = yield select(getProjectSubscribed);
    if (!(projectSubscribed === projectId)) {
      return;
    }

    if (taskDiff?.status || taskDiff?.user) {
      // we need to wait for elasticsearch sync
      yield call(
        () =>
          new Promise<void>((resolve) => {
            setTimeout(() => resolve(), 1000);
          }),
      );
      yield put(getTasksRequestAction({ projectId }));
    }
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* tasksSagas() {
  yield takeLatest(TasksActions.GET_TASKS_REQUEST, getTasksRequestSaga);
  yield takeLatest(TasksActions.DELETE_TASK_BY_ID_REQUEST, deleteTaskByIdRequestSaga);
  yield takeLatest(TasksActions.ASSIGN_TASK_TO_USER_ID, assignTaskToUserIdRequestSaga);
  yield takeLatest(TasksActions.CREATE_TASK_REQUEST, createTaskRequestSaga);
  yield takeLatest(TasksActions.MQTT_SUBSCRIBE, subscribeToMqttSaga);
  yield takeLatest(TasksActions.MQTT_UNSUBSCRIBE, unsubscribeToMqttSaga);
  yield takeLatest(TasksActions.HANDLE_INCOMING_MQTT_TASK, onIncomingMqttTaskSaga);
  yield takeLatest(TasksActions.UPDATE_SORTING, updateListConfigSaga);
}
