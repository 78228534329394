// eslint-disable-next-line max-classes-per-file
import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputListOption, InputListSearchableStep, ListOptions } from './base';

@Schema({ _id: false })
export class InputCheckboxListOption extends InputListOption {
  @Prop()
  @IsBoolean()
  @IsOptional()
  isChecked?: boolean;
}

@Schema()
export class InputCheckboxList extends InputListSearchableStep {
  type: StepTypes.INPUT_CHECKBOX_LIST;

  @Prop()
  @IsString()
  @IsOptional()
  refresh?: string;

  @Prop()
  @IsBoolean()
  selectAll: boolean;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputCheckboxListOption>;
}
