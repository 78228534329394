import mqttJS, { MqttClient } from 'mqtt';
import { eventChannel, END } from 'redux-saga';
import { mqttConnected, mqttDisconnected, handleMqttError } from './actions';
import { mqttConfig } from '../../globals';
import { topicPatternToRegex } from '../../utils/mqttRegex';

interface BaseMessageHandler {
  key: string;
  topic: string;
  regexPattern: RegExp;
}
interface ActionMessageHandler extends BaseMessageHandler {
  actionTypeCallback: string;
  callback?: never;
}
interface CallbackMessageHandler extends BaseMessageHandler {
  actionTypeCallback?: never;
  callback: (topic: string, message, regexpArray: RegExpExecArray | null) => void;
}
export type MessageHandler = ActionMessageHandler | CallbackMessageHandler;

let mqtt: MqttClient;
let messageHandlers: Array<MessageHandler> = [];

export const createMqttChannel = (auth: { userId: string; loginToken: string }) =>
  eventChannel((emit) => {
    const { userId, loginToken } = auth;
    const { port, host, protocol } = mqttConfig;

    const config = {
      username: loginToken,
      password: loginToken,
      clientId: `console|${userId || 0}|${Math.random().toString(16).substr(2, 5)}`,
    };

    mqtt = mqttJS.connect(`${protocol}://${host}:${port}/mqtt`, config);

    mqtt.on('connect', () => {
      emit(mqttConnected());
    });
    mqtt.on('error', (err: Error) => {
      emit(handleMqttError({ error: err, errorLoginToken: mqtt.options.password }));
    });

    mqtt.on('disconnect', () => {
      emit(mqttDisconnected());
      emit(END);
    });

    mqtt.on('message', (topic: string, payload: Buffer) => {
      messageHandlers
        .filter((handler) => handler.regexPattern.exec(topic))
        .forEach((handler) => {
          const message = JSON.parse(payload.toString());
          const regexpArray = handler.regexPattern.exec(topic);
          if (handler.actionTypeCallback) {
            emit({
              type: handler.actionTypeCallback,
              payload: {
                topic,
                message,
                regexpArray,
              },
            });
          }
          if (handler.callback) {
            handler.callback(topic, message, regexpArray);
          }
        });
    });

    return () => mqtt && mqtt.end();
  });

export const endConnection = () => mqtt && mqtt.end();

export const updateLoginToken = (newToken: string) => {
  mqtt.options.password = newToken;
};

export const mqttPublish = (payload: { topic: string; message: unknown }) => {
  const { topic } = payload;
  const message = JSON.stringify(payload.message);
  if (!mqtt || !mqtt.connected) {
    setTimeout(() => {
      mqttPublish(payload);
    }, 500);
    return;
  }
  mqtt.publish(topic, message);
};

export const mqttSubscribe = (
  payload: {
    topic: string;
    callback?;
    actionTypeCallback?: string;
    keyName?: string;
  },
  registerCallback = true,
) => {
  if (registerCallback) {
    messageHandlers.push({
      key: `${payload.topic}|${(payload.keyName || payload.actionTypeCallback)?.toString()}`,
      topic: payload.topic,
      regexPattern: topicPatternToRegex(payload.topic),
      actionTypeCallback: payload.actionTypeCallback,
      callback: payload.callback,
    });
  }
  if (!mqtt || !mqtt.connected) {
    setTimeout(() => {
      mqttSubscribe(payload, false);
    }, 500);
    return;
  }
  mqtt.subscribe(payload.topic);
};

export const mqttUpdateCallback = (payload: { topic: string; keyName?: string; callback }) => {
  messageHandlers = messageHandlers.map((handler) =>
    handler.key === `${payload.topic}|${payload.keyName}` ? { ...handler, callback: payload.callback } : handler,
  );
};

export const mqttUnsubscribe = (payload, registerCallback = true) => {
  if (registerCallback) {
    messageHandlers = messageHandlers.filter(
      (handler) => handler.key !== `${payload.topic}|${(payload.keyName || payload.actionTypeCallback).toString()}`,
    );
  }
  if (!mqtt || !mqtt.connected) {
    setTimeout(() => {
      mqttUnsubscribe(payload, false);
    }, 500);
    return;
  }
  mqtt.unsubscribe(payload.topic);
};
