// This is a short list of icons.
// Icons are in the services/media/assets/icons-* folder
// This little enum is used for convenience.
export enum StepsIconTypes {
  ICON_INFO = 'icon-info.png',
  ERROR = 'icon-error.png',
  SUCCESS = 'icon-success.png',
  SUPPORT = 'icon-support.png',
  QUESTION = 'question.png',
  QUESTION_YELLOW = 'question-yellow.png',
  REPEAT = 'repeat.png',
  WARNING_RED_CLEAR_BG = 'warning-red-clear-bg.png',
  WARNING_RED = 'icon-error.png',
  WARNING_YELLOW = 'warning-yellow.png',
  WARNING = 'warning.png',
  REFRESH = 'refresh.png',
  CLOSE = 'close.png',
  INFO_BLUE = 'icon-info.png',
  INFO_CLEAR = 'info-clear.png',
  BACK = 'back.png',
  NEXT = 'next.png',
  CONFIRM = 'confirm.png',
  CONFIRM_ENCIRCLED = 'message-success.png',
  CONFIRM_ENCIRCLED_GREEN = 'icon-success.png',
  INSTRUCTIONS = 'instructions.png',
  LIST = 'list.png',
  HOME = 'home.png',

  CABLE = 'cable-icon.png',
  TASK = 'ico-tasks.png',
  ALIGN_LEFT = 'icon-align-left.png',
  COINS = 'icon-coins.png',
  END = 'icon-end.png',
  FEED = 'icon-feed-in.png',
  FORK_LIFT = 'icon-fork-lift.png',
  JOG_FORWARD = 'icon-jog-forward.png',
  JOG_REWERSE = 'icon-jog-reverse.png',
  JUNCTION = 'icon-junction.png',
  LASER_BEAM = 'icon-laser-beam.png',
  MANUAL = 'icon-manual.png',
  OVERHEAD_CRANE = 'icon-overhead-crane.png',
  ICON_PLUS = 'icon-plus.png',
  POLYLINE = 'icon-polyline.png',
  RACK = 'icon-rack.png',
  REDO = 'icon-redo.png',
  ICON_REPEAT = 'icon-repeat.png',
  ROBOT = 'icon-robot.png',
  ROTATE_LEFT = 'icon-rotate-left.png',
  ROTATE_RIGHT = 'icon-rotate-right.png',
  ROTATE = 'icon-rotate.png',
  SEARCH = 'icon-search.png',
  SKIP = 'icon-skip-to-start.png',
  TICKET = 'icon-ticket.png',
  TRUCK = 'icon-truck.png',
  UNDO = 'icon-undo.png',
  WORKER = 'icon-worker.png',
  ACTIVE_DIRECTORY = 'icons8-active-directory-100.png',
  ACTIVE_DIRECTORY_FILLED = 'icons8-active-directory-filled-100.png',
  USER_GROUP = 'icons8-add-user-group-man-man-100.png',
  USER_MALE_1 = 'icons8-add-user-male-100-1.png',
  USER_MALE = 'icons8-add-user-male-100.png',
  USER_MALE_FILLED = 'icons8-add-user-male-filled-100.png',
  ALARM = 'icons8-alarm-clock-96.png',
  APP = 'icons8-app-symbol-100.png',
  APPROVAL = 'icons8-approval-100.png',
  APPROXIMATE = 'icons8-approximate-100.png',
  BARCODE = 'icons8-barcode-80.png',
  SCANNER = 'icons8-barcode-scanner-50.png',
  BUSINESS = 'icons8-business-building-100.png',
  BUSINESSMAN = 'icons8-businessman-100.png',
  CALENDAR_1 = 'icons8-calendar-100-1.png',
  CALENDAR = 'icons8-calendar-100.png',
  CALIPER = 'icons8-caliper-100.png',
  CALIPER_FILLED = 'icons8-caliper-filled-100.png',
  CHECK_1 = 'icons8-check-100-1.png',
  CHECK = 'icons8-check-100.png',
  CIRCLED_USER = 'icons8-circled-user-100.png',
  CLOSE_WINDOW = 'icons8-close-window-100.png',
  COMPACT_CAMERA_100 = 'icons8-compact-camera-100.png',
  COMPACT_CAMERA_80 = 'icons8-compact-camera-80.png',
  CONFERENCE = 'icons8-conference-100.png',
  DASHBOARD = 'icons8-dashboard-100.png',
  FIND_USER_MALE = 'icons8-find-user-male-100.png',
  FORKLIFT_1 = 'icons8-fork-lift-96-2.png',
  FORKLIFT_2 = 'icons8-fork-lift-96.png',
  GAS_STATION = 'icons8-gas-station-100.png',
  GAS_STATION_FILLED = 'icons8-gas-station-filled-100.png',
  GOOD_QUALITY = 'icons8-good-quality-100.png',
  WEB_SEARCH = 'icons8-google-web-search-100.png',
  WEB_SEARCH_FILLED = 'icons8-google-web-search-filled-100.png',
  INSTAGRAM = 'icons8-instagram-100.png',
  UPDATES = 'icons8-installing-updates-100.png',
  UPDATES_FILLED = 'icons8-installing-updates-filled-100.png',
  LOGIN_FILLED = 'icons8-login-rounded-filled-100.png',
  USER_MALE_FILLED_1 = 'icons8-male-user-filled-100.png',
  PRICE = 'icons8-price-tag-50.png',
  PRODUCT = 'icons8-product-50.png',
  EXCEPTION = 'icons8-recurring-appointment-exception-100.png',
  RECYCLE = 'icons8-recycle-100.png',
  REGISTRATION = 'icons8-registration-100.png',
  RESTORE = 'icons8-restore-filled-100.png',
  COMMAND_1 = 'icons8-run-command-100.png',
  COMMAND_2 = 'icons8-run-command-50.png',
  SCAN = 'icons8-scan-stock-50.png',
  SMS = 'icons8-sms-100.png',
  SMS_FILLED = 'icons8-sms-filled-100.png',
  SCOPE_FILLED = 'icons8-stethoscope-filled-100.png',
  SUBMIT = 'icons8-submit-for-approval-100.png',
  DIAGNOSTIC = 'icons8-system-diagnostic-100.png',
  DIAGNOSTIC_FILLED = 'icons8-system-diagnostic-filled-100.png',
  TEST_PASSED = 'icons8-test-passed-100.png',
  THUMBS_UP = 'icons8-thumbs-up-100.png',
  VERIFIED_ACCOUNT_1 = 'icons8-verified-account-100-1.png',
  VERIFIED_ACCOUNT = 'icons8-verified-account-100.png',
  VERIFIED_ACCOUNT_FILLED = 'icons8-verified-account-filled-100.png',
  WHATSAPP = 'icons8-whatsapp-100.png',
  MINUS = 'minus.png',
  PLUS = 'plus.png',
  INFO = 'info.png',
}
