import { AnyAction } from 'redux';

import { Sorting, ListConfigs } from '@workerbase/types/ListConfig';

import { ConnectorLog } from 'services/types/Connector';
import { PaginationMeta } from '@workerbase/types/Response';

import { ConnectorLogsActions } from './actions';
import { UpdatePaginationActionPayload, UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updatePagination, updateSorting, updateListConfig } from '../common/ListConfig/reducers';

export type ConnectorLogsState = Readonly<{
  connectorLogsById: { [key: string]: ConnectorLog };
  listConfigs: ListConfigs;
  currentListItemsIds: string[];
  errorMessage: string | null;
  isLoading: boolean;
}>;

export const initialState: ConnectorLogsState = {
  connectorLogsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    properties: [],

    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
  isLoading: false,
};

interface GetConnectorLogsSuccessActionPayload {
  connectorLogs: ConnectorLog[];
  meta: PaginationMeta;
}

interface GetConnectorLogsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: ConnectorLogsState = initialState, action: AnyAction): ConnectorLogsState => {
  switch (action.type) {
    case ConnectorLogsActions.GET_CONNECTORLOGS_REQUEST: {
      if (action.payload.forceLoading) {
        return { ...state, isLoading: true };
      }
      return state;
    }
    case ConnectorLogsActions.GET_CONNECTORLOGS_SUCCESS: {
      const payload = action.payload as GetConnectorLogsSuccessActionPayload;

      const connectorLogsById = payload.connectorLogs.reduce((prev, connectorlog) => {
        const updatedConnectorLogs = prev;
        updatedConnectorLogs[connectorlog.id] = connectorlog;
        return prev;
      }, {});

      return {
        ...state,
        connectorLogsById: {
          ...state.connectorLogsById,
          ...connectorLogsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        isLoading: false,
        errorMessage: null,
        currentListItemsIds: payload.connectorLogs.map((connectorlog) => connectorlog.id),
      };
    }
    case ConnectorLogsActions.GET_CONNECTORLOGS_FAILURE: {
      const payload = action.payload as GetConnectorLogsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
        isLoading: false,
        currentListItemsIds: [],
      };
    }
    case ConnectorLogsActions.UPDATE_PAGINATION: {
      const payload = action.payload as UpdatePaginationActionPayload;
      return updatePagination(state, payload);
    }
    case ConnectorLogsActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case ConnectorLogsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
