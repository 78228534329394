import { Prop, Schema } from '@nestjs/mongoose';
import { IsOptional, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoStatus extends StepBase {
  type: StepTypes.INFO_STATUS;

  @Prop({
    required: true,
  })
  @IsString()
  color: string;

  @Prop({
    required: true,
  })
  @IsString()
  icon: string;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  @IsOptional()
  headline?: string;
}
