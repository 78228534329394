import { LeanDocument } from 'mongoose';
import { SharedConstants as Constants } from './SharedConstants';

import { DateFormatter } from './DateFormatter';
import {
  DatabaseDocument,
  DatabaseColumn,
  DatabaseColumnTypes,
  DatabaseExternalIdColumnTypes,
} from './MongoDB/Database';
import { ItemPayloadValue } from './DatabaseItem';

export { DatabaseColumn, DatabaseColumnTypes, DatabaseExternalIdColumnTypes };

export type ColumnUpdatesById = Record<
  string /** DatabaseColumn._id */,
  { current: DatabaseColumn; updated: DatabaseColumn }
>;

export interface DatabaseColumnUpdates {
  addedColumns: DatabaseColumn[];
  deletedColumns: DatabaseColumn[];
  updatedColumns: ColumnUpdatesById;
}

export interface ColumnToRename {
  _id: string;
  oldName: string;
  newName: string;
}

export const formatRawDatabaseCellValue = (rawValue: ItemPayloadValue, columnType: DatabaseColumnTypes): string => {
  if (rawValue === null || rawValue === Constants.NULL_STRING || rawValue === undefined) {
    return Constants.NULL_STRING;
  }

  switch (columnType) {
    case DatabaseColumnTypes.DATETIME: {
      return DateFormatter.format(String(rawValue), DateFormatter.Formats.DATETIME);
    }
    case DatabaseColumnTypes.DATE: {
      return DateFormatter.format(String(rawValue), DateFormatter.Formats.DATE);
    }
    case DatabaseColumnTypes.TIME: {
      return DateFormatter.format(String(rawValue), DateFormatter.Formats.TIME);
    }
    case DatabaseColumnTypes.BOOLEAN: {
      return Boolean(rawValue).toString();
    }
  }
  return String(rawValue);
};

export { DatabaseDocument };
export type Database = LeanDocument<DatabaseDocument>;
