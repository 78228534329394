import { ShiftPlanGET } from '@workerbase/types/api/shiftPlans';

import { RootState } from '../types';

export const getShiftPlansOnCurrentPage = (store: RootState) =>
  store.shiftPlans.currentListItemsIds.map((id) => store.shiftPlans.shiftPlansById[id]);

export const getAllShifts = (store: RootState): ShiftPlanGET[] => Object.values(store.shiftPlans.shiftPlansById);

export const getListConfigs = (store: RootState) => store.shiftPlans.listConfigs;

export const getShiftPlanWithId =
  (shiftPlanId?: string) =>
  (store: RootState): ShiftPlanGET | undefined =>
    shiftPlanId ? store.shiftPlans.shiftPlansById[shiftPlanId] : undefined;
