import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { clearAuthTokensFromLocalStorage } from 'services/auth/auth.service';
import { reducer as login } from './Login';
import { reducer as roles } from './Roles';
import { reducer as users } from './Users';
import { reducer as devices } from './Devices';
import { reducer as projects } from './Projects';
import { reducer as tasks } from './Tasks';
import { reducer as rules } from './Rules';
import { reducer as workinstructions } from './Workinstructions';
import { reducer as connectors } from './Connectors';
import { reducer as settings } from './Settings';
import { reducer as connectorLogs } from './ConnectorLogs';
import { reducer as databases } from './Databases';
import { reducer as databaseItems } from './DatabaseItems';
import { reducer as mqtt } from './Mqtt';
import { reducer as skills } from './Skills';
import { reducer as shiftPlans } from './ShiftPlans';
import { reducer as functions } from './Functions';
import { reducer as functionLogs } from './FunctionLogs';
import { reducer as locations } from './Locations';
import { reducer as translations } from './Translations';
import { reducer as projectLogs } from './ProjectLogs';

import { LoginActions } from './Login/actions';
import { GLOBAL_STATE_LOCAL_STORAGE_KEY } from './configureStore';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    login,
    roles,
    users,
    devices,
    projects,
    tasks,
    rules,
    workinstructions,
    connectors,
    connectorLogs,
    databases,
    databaseItems,
    mqtt,
    shiftPlans,
    skills,
    functions,
    functionLogs,
    locations,
    translations,
    settings,
    projectLogs,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === LoginActions.LOGOUT_USER) {
    clearAuthTokensFromLocalStorage();
    localStorage.removeItem(GLOBAL_STATE_LOCAL_STORAGE_KEY);

    return createRootReducer(history)(undefined, action);
  }
  return createRootReducer(history)(state, action);
};

export default rootReducer;
