import { ComparingConditionOperator } from '../../../RuleCondition/ConditionOperator';

export const InputBarcodeOperators = [
  ComparingConditionOperator.EQUAL,
  ComparingConditionOperator.CONTAINS,
  ComparingConditionOperator.BEGINS,
  ComparingConditionOperator.ENDS,
  ComparingConditionOperator.EQUALS_VAR,
  ComparingConditionOperator.REGEX,
] as const;
