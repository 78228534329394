// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SquareButton-module__print___XZUFk{overflow:visible !important}.SquareButton-module__button___YIvyU{font-family:Proxima Nova;font-style:normal;font-weight:600;font-size:14px;line-height:20px;letter-spacing:0px;border-radius:4px;padding-left:9px;padding-right:9px;display:flex;width:40px;height:40px;align-items:center;justify-content:center}.SquareButton-module__button___YIvyU:focus{outline:0}.SquareButton-module__button___YIvyU:hover{cursor:pointer}.SquareButton-module__button___YIvyU:disabled{background-color:#f1f3f9;color:#58637e;cursor:not-allowed}.SquareButton-module__regular___EdCSj{background-color:#f1f3f9;color:#58637e}.SquareButton-module__regular___EdCSj:hover:not([disabled]),.SquareButton-module__regular___EdCSj:active:not([disabled]){background-color:#ccdbff;color:#174dca}.SquareButton-module__danger___qyL3U{background-color:#f1f3f9;color:#58637e}.SquareButton-module__danger___qyL3U:hover:not([disabled]),.SquareButton-module__danger___qyL3U:active:not([disabled]){background-color:#feebe1;color:#ff6868}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `SquareButton-module__print___XZUFk`,
	"button": `SquareButton-module__button___YIvyU`,
	"regular": `SquareButton-module__regular___EdCSj`,
	"danger": `SquareButton-module__danger___qyL3U`
};
export default ___CSS_LOADER_EXPORT___;
