import { createTheme, darken } from '@mui/material/styles';
import { ButtonClasses as MuiButtonClasses, IconButtonClasses as MuiIconButtonClasses } from '@mui/material';

// See https://mui.com/material-ui/customization/palette/#typescript
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }

  interface ButtonPropsSizeOverrides {
    extraSmall: true;
  }

  // @ts-ignore --- there are no overrides' mechanism inside the MUI, so need to be done in this way manually
  interface ButtonClasses extends MuiButtonClasses {
    /** Styles applied to the root element if `variant="text"` and `color="tertiary"`. */
    textTertiary: string;
    /** Styles applied to the root element if `variant="contained"` and `color="tertiary"`. */
    containedTertiary: string;
    /** Styles applied to the root element if `variant="outlined"` and `color="tertiary"`. */
    outlinedTertiary: string;

    /** Styles applied to the root element if `size="extraSmall"`. */
    sizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="text"`. */
    textSizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="outlined"`. */
    outlinedSizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="contained"`. */
    containedSizeExtraSmall: string;
    /** Styles applied to the icon element if supplied and `size="extraSmall"`. */
    iconSizeExtraSmall: string;
  }
}

// @babel-ignore-comment-in-output Update the IconButton's color prop options
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }

  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }

  // @ts-ignore --- there are no overrides' mechanism inside the MUI, so need to be done in this way manually
  interface IconButtonClasses extends MuiIconButtonClasses {
    /** Styles applied to the root element if `color="tertiary"`. */
    colorTertiary: string;
    /** Styles applied to the root element if `size="extraSmall"`. */
    sizeExtraSmall: string;
  }
}

// We want to have a unique file to define style variables.
// We do it mainly in the main scss file but we can't easily access the variables as "plain javascript variables".
// So we duplicate these variables here.

export const colors = {
  B100: '#e6eeff',
  P: '#AF4BE8',
  B150: '#ccdbff',
  B200: '#79A1FF',
  B300: '#2660E6',
  B50: '#F5F8FF',
  B500: '#174dca',
  G50: '#ECEFF1',
  G100: '#E1FDEA',
  G200: '#66DFBB',
  G300: '#74ec5c',
  G400: '#66BB6A',
  G700: '#279567',
  N0: '#FFFFFF',
  N100: '#F1F3F9',
  N1000: '#252A37',
  N150: '#F8F9FC',
  N200: '#EEF1F6',
  N300: '#CDD2E0',
  N400: '#8992A9',
  N50: '#F9FAFD',
  N500: '#778089',
  N700: '#233149',
  P100: '#FDF0FD',
  P150: '#E985E9',
  P200: '#FE0FFE',
  R100: '#FEEBE1',
  R150: '#fed7c3',
  R200: '#FF6868',
  R300: '#F54A4A',
  T100: '#E6F9F8',
  T200: '#53E0DB',
  Y100: '#FEFBD3',
  Y200: '#FCE028',
};

export const inputSizes = {
  small: {
    height: '32px',
    padding: {
      top: 6,
      right: 10,
      bottom: 6,
      left: 10,
    },
    fontSize: 14,
    borderRadius: '4px',
  },
  normal: {
    height: '40px',
    padding: {
      top: 10,
      right: 12,
      bottom: 10,
      left: 12,
    },
    fontSize: 16,
    lineHeight: '20px',
  },
  // regular input is a base, define overrides for autocomplete wrapper here
  autocomplete: {
    paddingRight: '32px',
  },
};

export const shadows = {
  S100: '0px 2px 6px rgba(182, 182, 182, 0.2)',
  S200: '0px 4px 16px rgba(0, 0, 0, 0.08)',
};

export const fontFamily = {
  textRegularFontFamily: 'Proxima Nova',
};

export const fontSize = {
  textRegularFontSize: '14px',
  titleFontSize: '20px',
};
export const fontWeight = {
  textSemiboldFontWeight: '600',
};

export const dropdown = {
  itemHeight: 36,
  paddingY: 8,
  /*
   * the max height before scroll appears
   * where 36 is a height of a single item
   * and 5 - number of items to display
   */
  maxHeight: 36 * 5,
};

export const databaseViewFilters = {
  mainMenuWidth: 680,
};

const {
  palette: { augmentColor },
} = createTheme(); // needed for accessing the utils

// We use some Material UI components in the project.
// This file defines the global theme for our components.
export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.B300,
      dark: colors.B500,
      light: colors.B100,
    },
    secondary: {
      light: colors.B50,
      main: colors.B100,
    },
    tertiary: augmentColor({
      name: 'tertiary',
      color: { main: colors.N100, dark: colors.N300, contrastText: colors.N700 },
    }),
    warning: {
      main: '#FE812A',
    },
    success: {
      main: colors.G400,
      100: colors.G100,
      200: colors.G200,
      300: colors.G300,
      400: colors.G400,
      700: colors.G700,
    },
    error: {
      main: colors.R300,
    },
    grey: {
      50: colors.N50,
      100: colors.N100,
      200: colors.N200,
      300: colors.N300,
      400: colors.N400,
      500: colors.N500,
      700: colors.N700,
    },
  },
  typography: {
    body1: {
      color: colors.N1000,
    },
    body2: {
      color: colors.N1000,
    },
    fontFamily: ['Proxima Nova', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: shadows.S100,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: '30px',
          padding: theme.spacing(0.375, 1.75),
          minHeight: `${dropdown.itemHeight}px`, // override default 48px for all menu items
          '&:hover': {
            backgroundColor: theme.palette.grey['200'],
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorTertiary: {},
        sizeExtraSmall: {
          padding: 2,
          '& > .MuiSvgIcon-root': {
            fontSize: 16,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
        sizeExtraSmall: {
          fontSize: 14,
          lineHeight: '22px',
          padding: '3px 10px',
          borderRadius: '4px',
          minHeight: '28px',
        },
        iconSizeExtraSmall: {
          '& > .MuiSvgIcon-root': {
            fontSize: 16,
          },
        },
        // sizeSmall: {
        //   fontSize: 14,
        //   lineHeight: '22px',
        //   padding: '5px 12px',
        //   borderRadius: '4px',
        //   minHeight: '32px',
        // },
        sizeMedium: {
          padding: '4px 16px',
          fontSize: 16,
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:active': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: colors.N0,
          backgroundColor: colors.B300,
          '&:hover, &:active': {
            backgroundColor: colors.B500,
          },
        },
        containedSecondary: {
          color: colors.B500,
          backgroundColor: colors.B100,
          '&:hover, &:active': {
            backgroundColor: colors.B150,
          },
        },
        textSecondary: {
          color: darken(colors.B150, 0.3), // TODO: needs to be discussed with the designer
        },
        outlinedSecondary: {
          color: darken(colors.B150, 0.3), // TODO: needs to be discussed with the designer
        },
        textTertiary: ({ _ownerState, theme }) => ({
          color: theme.palette.tertiary.contrastText,
          fontWeight: 600,
        }),
        outlinedTertiary: ({ _ownerState, theme }) => ({
          color: theme.palette.tertiary.contrastText,
          fontWeight: 600,
        }),
        containedTertiary: {
          fontWeight: 600,
        },
        outlined: ({ _ownerState, theme }) => ({
          padding: '7px 15px',
          borderColor: theme.palette.grey['300'],
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        inputSizeSmall: {
          padding: `${inputSizes.small.padding.top}px ${inputSizes.small.padding.right}px ${inputSizes.small.padding.bottom}px ${inputSizes.small.padding.left}px!important`,
          fontSize: inputSizes.small.fontSize,
          height: inputSizes.small.height,
        },
        input: {
          boxSizing: 'border-box',
          padding: `${inputSizes.normal.padding.top}px ${inputSizes.small.padding.right}px ${inputSizes.normal.padding.bottom}px ${inputSizes.normal.padding.left}px!important`,
          fontSize: inputSizes.normal.fontSize,
          height: inputSizes.normal.height,
          lineHeight: inputSizes.normal.lineHeight,
          '&.MuiSelect-select': {
            paddingRight: '32px!important', // indent for select dropdown arrow
          },
        },
        adornedStart: {
          paddingLeft: `${inputSizes.normal.padding.left}px!important`,
          '.MuiIconButton-root': {
            marginLeft: `-${inputSizes.normal.padding.left - 2}px!important`,
          },

          '&.MuiInputBase-sizeSmall': {
            paddingLeft: `${inputSizes.small.padding.left}px!important`,
            '.MuiIconButton-root': {
              marginLeft: `-${inputSizes.small.padding.left - 2}px!important`,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: '0!important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: colors.N0,
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #8992a9',
            },
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #2660e6',
            },
            border: 'none',
          },
        },
        inputSizeSmall: {
          borderRadius: inputSizes.small.borderRadius,
        },
        notchedOutline: {
          border: '1px solid rgb(205, 210, 224)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});
