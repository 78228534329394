import { Property } from '@workerbase/types/ListConfig';
import { updateSortingFactory } from '@redux/common/ListConfig/actions';
import { User } from 'services/types/User';
import { BaseAction } from '../common/actions';

export enum UsersActions {
  GET_USER_BY_ID_REQUEST = '@@users/GET_USER_BY_ID_REQUEST',
  GET_USER_BY_ID_SUCCESS = '@@users/GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_FAILURE = '@@users/GET_USER_BY_ID_FAILURE',
  GET_LIST_CONFIG_REQUEST = '@@users/GET_LIST_CONFIG_REQUEST',
  UPDATE_LIST_CONFIG = '@@users/UPDATE_LIST_CONFIG',
  GET_FUNCTION_TEST_RUN_DATA_REQUEST = '@@users/GET_FUNCTION_TEST_RUN_DATA_REQUEST',
  GET_FUNCTION_TEST_RUN_DATA_SUCCESS = '@@users/GET_FUNCTION_TEST_RUN_DATA_SUCCESS',
  UPDATE_FUNCTION_TEST_RUN_DATA_REQUEST = '@@users/UPDATE_FUNCTION_TEST_RUN_DATA_REQUEST',
  UPDATE_FUNCTION_TEST_RUN_DATA_SUCCESS = '@@users/UPDATE_FUNCTION_TEST_RUN_DATA_SUCCESS',
  UPDATE_SORTING = '@@users/UPDATE_SORTING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@users/UPDATE_LISTCONFIG_PROPERTIES',
}
// GET_USER_BY_ID
export const getUserByIdRequestAction = (userId: string): BaseAction => ({
  type: UsersActions.GET_USER_BY_ID_REQUEST,
  payload: { userId },
});

export const getUserByIdSuccess = (data: User): BaseAction => ({
  type: UsersActions.GET_USER_BY_ID_SUCCESS,
  payload: {
    user: data,
  },
});

export const getUserByIdError = (errorMessage: string): BaseAction => ({
  type: UsersActions.GET_USER_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export interface GetUserListConfigAction {
  type: string;
  payload: {
    userId: string;
  };
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateSorting = updateSortingFactory(UsersActions.UPDATE_SORTING);

export const updateListConfigProperties = (newProperties: Property[]) => ({
  type: UsersActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});

export const getFunctionTestRunData = (functionId: string) => ({
  type: UsersActions.GET_FUNCTION_TEST_RUN_DATA_REQUEST,
  payload: { functionId },
});

export const getFunctionTestRunDataSuccess = (functionId: string, testRunData: string): BaseAction => ({
  type: UsersActions.GET_FUNCTION_TEST_RUN_DATA_SUCCESS,
  payload: {
    functionId,
    testRunData,
  },
});

export const updateFunctionTestRunData = (functionId: string, testRunData: string) => ({
  type: UsersActions.UPDATE_FUNCTION_TEST_RUN_DATA_REQUEST,
  payload: { functionId, testRunData },
});

export const updateFunctionTestRunDataSuccess = (functionId: string, testRunData: string): BaseAction => ({
  type: UsersActions.UPDATE_FUNCTION_TEST_RUN_DATA_SUCCESS,
  payload: {
    functionId,
    testRunData,
  },
});
