import * as React from 'react';
import { toast } from 'react-toastify';
import { getIntl } from '../providers/IntlProvider';

export class ToastUtils {
  public static showToastWithErrorReportingId(message: string, id?: string) {
    const intl = getIntl();
    toast.error(
      <div>
        <div>{message}</div>
        <div>{`${intl.formatMessage({
          id: 'global.error-id',
        })}: ${
          id ||
          intl.formatMessage({
            id: 'error-reporting.id-not-found',
          })
        }`}</div>
      </div>,
      {
        autoClose: 30000,
        style: {
          width: 'max-content',
        },
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      },
    );
  }
}
