import { Prop, Schema } from '@nestjs/mongoose';
import { IsString } from 'class-validator';
import { StepBase } from './StepBase';

/**
 * Only used for input-* steps
 * */

@Schema()
export class InputableStep extends StepBase {
  @Prop({ required: true })
  @IsString()
  outputVarName: string;
}
