import { WorkinstructionOld } from '@workerbase/types/Workinstruction';
import { ClientPreparedWorkinstruction } from '@workerbase/types/api/clients/workinstructions';

export abstract class WorkinstructionStepsMapper {
  static arrayToMap(workinstruction: ClientPreparedWorkinstruction): WorkinstructionOld {
    const workinstructionWithStepsMap = workinstruction as unknown as WorkinstructionOld;

    workinstructionWithStepsMap.steps = (workinstruction?.steps || []).reduce((stepsMap, step) => {
      stepsMap[step.id] = step;
      return stepsMap;
    }, {});

    return workinstructionWithStepsMap;
  }

  static mapToArray(workinstruction: WorkinstructionOld): ClientPreparedWorkinstruction {
    return {
      ...workinstruction,
      steps: Object.values(workinstruction?.steps || {}),
    };
  }
}
