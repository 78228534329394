import atob from 'atob';
import btoa from 'btoa';

/**
 * The purpose of the class is to add UTF-8 support to base64 encoding/decoding.
 */
export class Base64 {
  public static encode(text: string): string {
    return btoa(unescape(encodeURIComponent(text)));
  }

  public static decode(text: string): string {
    return decodeURIComponent(escape(atob(text)));
  }
}
