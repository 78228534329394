import { call, put, takeLatest, select } from 'redux-saga/effects';

import { ListConfigs } from '@workerbase/types/ListConfig';

import { getProjectLogsPaginated } from 'services/networking/projects';
import { ProjectLog } from 'services/types/ProjectLog';
import { PaginationMeta } from '@workerbase/types/Response';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { handleRequestError } from '../common';

import { getListConfigs } from './selectors';

import { getProjectLogsSuccess, getProjectLogsError, ProjectLogsActions } from './actions';

export function* getProjectLogsRequestSaga(action) {
  try {
    const listConfigs: ListConfigs = yield select(getListConfigs);

    const response = yield call(
      getProjectLogsPaginated,
      action.payload.projectId,
      listConfigs.pagination?.currentPage,
      listConfigs.pagination?.itemsPerPage,
      listConfigs.sorting,
      listConfigs.filtering.searchTerms,
      listConfigs.tabs?.selectedTabKey,
      listConfigs.tabs?.categoryKey,
    );

    const logs: ProjectLog[] = response?.data;
    const meta: PaginationMeta = response?.meta;

    yield put(getProjectLogsSuccess(logs, meta));
  } catch (error) {
    yield put(getProjectLogsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export default function* projectLogsSagas() {
  yield takeLatest(
    [ProjectLogsActions.GET_PROJECT_LOGS_REQUEST, ProjectLogsActions.UPDATE_LISTCONFIG_PROPERTIES],
    getProjectLogsRequestSaga,
  );
  yield takeLatest(ProjectLogsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(ProjectLogsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
