import { StandardDatabaseDefinition } from '@workerbase/types/domain/database/standard/StandardDatabaseDefinition.interface';
import { STANDARD_DATABASES } from '@workerbase/types/domain/database/standard/StandardDatabaseDefinitions.constant';
import { StandardDatabaseSlugs } from '@workerbase/types/domain/database/standard/StandardDatabaseSlugs.enum';

const STANDARD_DATABASES_SLUGS_ORDERED: StandardDatabaseSlugs[] = [
  StandardDatabaseSlugs.Asset,
  StandardDatabaseSlugs.Machine,
  StandardDatabaseSlugs.Workstation,
  StandardDatabaseSlugs.WorkOrder,
  StandardDatabaseSlugs.ProductionLine,
  StandardDatabaseSlugs.AssetType,
  StandardDatabaseSlugs.MachineType,
  StandardDatabaseSlugs.MaterialType,
  StandardDatabaseSlugs.ProductType,
];

/**
 * Ordered list of standard databases by design, ready for UI
 */
export const STANDARD_DATABASES_ORDERED_LIST = STANDARD_DATABASES_SLUGS_ORDERED.map((slug) =>
  STANDARD_DATABASES.find((db) => db.slug === slug),
).filter((db): db is StandardDatabaseDefinition => db !== undefined);
