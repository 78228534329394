import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Skeleton } from '@mui/material';

import { BASE_URL } from '../../globals';
import { getMediaMetaById } from '../../services/networking/media';
import { getAccessTokenFromLocalStorage } from '../../services/auth/auth.service';
import { WuiModal } from '../WuiModal';

export interface WuiMediaDisplayProps {
  id: string;
  name?: string;
  type?: string;
  disableExpand?: boolean;
}

export const WuiMediaDisplay: FC<WuiMediaDisplayProps> = ({ id, name = '', type = '', disableExpand = false }) => {
  const [mediaReady, setMediaReady] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(name);
  const [mimeType, setMimeType] = useState<string>(type);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      if (!fileName && !mimeType) {
        const result = await getMediaMetaById(id);
        setFileName(result.filename);
        setMimeType(result.contentType);
        setMediaReady(true);
      } else {
        setMediaReady(true);
      }
    })();
  }, []);

  if (!mediaReady) {
    return <Skeleton height="100px" />;
  }

  return (
    <>
      <Box onClick={() => !disableExpand && setOpenModal(true)} sx={{ borderRadius: 'unset' }}>
        <MediaDisplay key="fileThumbnail" id={id} name={fileName} type={mimeType} />
      </Box>
      <WuiModal
        open={openModal}
        showButtons={false}
        onClose={() => setOpenModal(false)}
        onDone={() => setOpenModal(false)}
      >
        <MediaDisplay key="fileDisplay" id={id} name={fileName} type={mimeType} expandMode />
      </WuiModal>
    </>
  );
};

interface MediaDisplayProps {
  id: string;
  name: string;
  type: string;
  expandMode?: boolean;
}

const MediaDisplay: FC<MediaDisplayProps> = ({ id, name, type, expandMode = false }: MediaDisplayProps) => {
  const TOKEN = getAccessTokenFromLocalStorage();
  const mediaUrl = useMemo(() => `${BASE_URL}/api/v1/media/${id}?accesstoken=${TOKEN}`, [id, TOKEN]);

  if (type.startsWith('image')) {
    return <img src={mediaUrl} style={{ width: '100%', display: 'block' }} />;
  }

  if (type.startsWith('video')) {
    return (
      <video
        controls
        src={mediaUrl}
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
        }}
      />
    );
  }

  if (type.startsWith('audio')) {
    return <audio controls src={mediaUrl} style={{ width: '100%', display: 'block' }} />;
  }

  return (
    <>
      {expandMode ? (
        <iframe
          src={mediaUrl}
          width="100%"
          height="100%"
          style={{ margin: '0 auto', border: 'none', minHeight: '40vh' }}
        />
      ) : (
        <a href={mediaUrl} download={name} target="_blank" rel="noreferrer">
          {name}
        </a>
      )}
    </>
  );
};
