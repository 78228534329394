// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar-module__print___Xf73Y{overflow:visible !important}.Sidebar-module__sidebar___tcNsK{position:fixed;width:280px;top:117px;bottom:0px;min-width:280px;background-color:#f9fafd;padding:24px 0 24px 8px;overflow-y:scroll;overflow-x:hidden;box-sizing:border-box}.Sidebar-module__sidebar___tcNsK.Sidebar-module__right___XRslt{right:0px;width:360px;min-width:360px}.Sidebar-module__sidebar___tcNsK.Sidebar-module__bigPadding___Q0Ofb{padding:24px 40px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `Sidebar-module__print___Xf73Y`,
	"sidebar": `Sidebar-module__sidebar___tcNsK`,
	"right": `Sidebar-module__right___XRslt`,
	"bigPadding": `Sidebar-module__bigPadding___Q0Ofb`
};
export default ___CSS_LOADER_EXPORT___;
