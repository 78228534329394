import { Project } from 'services/types/Project';

import { RootState } from '../types';

export const getCurrentListItemsIds = (store: RootState) => store.projects.currentListItemsIds;

export const getProjectsOnCurrentPage = (store: RootState) =>
  store.projects.currentListItemsIds.map((id) => store.projects.projectsById[id]);

export const getProjectTaskFields = (store: RootState, projectId: string) =>
  store.projects.projectsById[projectId]?.config.taskDetailsFilter;

export const getProjectRoleIdsWithId =
  (projectId: string) =>
  (store: RootState): string[] | undefined =>
    store.projects.projectsById[projectId]?.roles;

export const getProjectWithId =
  (projectId?: string) =>
  (store: RootState): Project | undefined =>
    projectId ? store.projects.projectsById[projectId] : undefined;

export const getListConfigs = (store: RootState) => store.projects.listConfigs;
