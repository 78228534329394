import { StepCore } from '../../../../MongoDB/Workinstruction/Steps';
import { StepTypes } from '../StepTypes.enum';
import { InputButtonList, InputStepMenu, InputTileList, InputValueList } from '../steps';

const LIST_STEPS_WITH_CUSTOM_NEXT_STEP = [StepTypes.INPUT_VALUE_LIST, StepTypes.INPUT_TILE_LIST];

const LIST_STEPS_ACTIONABLE = [StepTypes.INPUT_BUTTON_LIST, StepTypes.INPUT_STEP_MENU];

export type ActionableInputList = InputValueList | InputTileList | InputButtonList | InputStepMenu;

/**
 * Guard that checks if listable step is actionable which means that list options have an 'action' object
 *
 * INPUT_VALUE_LIST and INPUT_TILE_LIST steps can only have actions
 * when customNextAction is true otherwise action equals 'null'.
 * However INPUT_BUTTON_LIST and INPUT_STEP_MENU steps use buttons as options
 * so they always have action without 'customNextAction' flag.
 */
export const isListOptionsActionable = (step: StepCore /** Listable */): step is ActionableInputList =>
  LIST_STEPS_ACTIONABLE.includes(step.type) ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- customNextStep does not exist on INPUT_BUTTON_LIST and INPUT_STEP_MENU steps
  (LIST_STEPS_WITH_CUSTOM_NEXT_STEP.includes(step.type) && step.customNextStep);
