export const formatFileSize = (fileSizeInBytes: number): string => {
  const sizeInKBs = fileSizeInBytes / 1024;
  const sizeInMBs = fileSizeInBytes / (1024 * 1024);

  switch (true) {
    case fileSizeInBytes < 1024:
      return `${fileSizeInBytes}b`;
    case sizeInKBs < 1024:
      return `${sizeInKBs.toFixed(2)}kb`;
    default:
      return `${sizeInMBs.toFixed(2)}mb`;
  }
};
