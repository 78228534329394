import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean } from 'class-validator';
import { InputListBaseStep } from './InputListBaseStep';
import { Searchable } from '../../../../domain/workinstruction/step/steps';

@Schema()
export class InputListSearchableStep extends InputListBaseStep implements Searchable {
  @Prop({ default: false })
  @IsBoolean()
  showSearchBar: boolean;
}
