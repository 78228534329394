import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useSelector } from 'react-redux';
import { getIsRootAdministrator, getIsDeveloper, getIsOnPremise } from '@redux/Login';
import { RootState } from '@redux/types';
import { history } from '@redux/configureStore';
import { SvgIcon } from '@mui/material';

import MainContainer from 'components/MainContainer';
import Sidebar from 'components/Sidebar';
import SidebarItem from 'components/SidebarItem';
import SidebarList from 'components/SidebarList';
import { getAccessTokenFromLocalStorage } from 'services/auth/auth.service';
import ElasticSearchLogo from '../../../assets/icons/brands/elasticsearch-bw.svgr';

import styles from './SettingsSideBar.module.scss';
import { ResyncElastic } from '../components/ResyncElastic';

export enum GlobalSettingsTabs {
  NETWORK_SETUP = 'network-setup',
  LANGUAGES = 'languages',
  LOCATION_STRUCTURE = 'location-structure',
  LICENSE_USAGE = 'licence-usage',
  SHIFT_PLANS = 'shift-plans',
  TRANSLATIONS = 'translations',
  MODULES = 'modules',
}

interface SettingsSideBarProps {
  sidebarLayoutContent?: JSX.Element;
  currentTab: GlobalSettingsTabs;
}

export const SettingsSideBar: React.FC<SettingsSideBarProps> = ({ sidebarLayoutContent, currentTab }) => {
  const intl = useIntl();
  const userToken = getAccessTokenFromLocalStorage();
  const isOnPremise = useSelector<RootState, boolean>(getIsOnPremise);
  const isRootAdministrator = useSelector<RootState, boolean>(getIsRootAdministrator);
  const isDeveloper = useSelector<RootState, boolean>(getIsDeveloper);

  const [openSyncElastic, setOpenSyncElastic] = useState(false);

  return (
    <MainContainer>
      <Sidebar>
        <SidebarList>
          <SidebarItem
            active={currentTab === GlobalSettingsTabs.NETWORK_SETUP}
            onClick={() => history.push(`/settings/${GlobalSettingsTabs.NETWORK_SETUP}`)}
          >
            {intl.formatMessage({
              id: 'settings.network-setup',
            })}
          </SidebarItem>
          {isRootAdministrator && (
            <React.Fragment>
              <SidebarItem
                active={currentTab === GlobalSettingsTabs.LANGUAGES}
                onClick={() => history.push(`/settings/${GlobalSettingsTabs.LANGUAGES}`)}
              >
                {intl.formatMessage({
                  id: 'settings.languages',
                })}
              </SidebarItem>
              <SidebarItem
                active={currentTab === GlobalSettingsTabs.LOCATION_STRUCTURE}
                onClick={() => history.push(`/settings/${GlobalSettingsTabs.LOCATION_STRUCTURE}`)}
              >
                {intl.formatMessage({
                  id: 'settings.location-structure',
                })}
              </SidebarItem>
            </React.Fragment>
          )}
          <SidebarItem
            active={currentTab === GlobalSettingsTabs.LICENSE_USAGE}
            onClick={() => history.push(`/settings/${GlobalSettingsTabs.LICENSE_USAGE}`)}
          >
            {intl.formatMessage({
              id: 'settings.license-usage',
            })}
          </SidebarItem>
          <SidebarItem
            active={currentTab === GlobalSettingsTabs.MODULES}
            onClick={() => history.push(`/settings/${GlobalSettingsTabs.MODULES}`)}
          >
            {intl.formatMessage({
              id: 'global.modules',
            })}
          </SidebarItem>
          {isRootAdministrator && (
            <SidebarItem
              active={currentTab === GlobalSettingsTabs.SHIFT_PLANS}
              onClick={() => history.push(`/settings/${GlobalSettingsTabs.SHIFT_PLANS}`)}
            >
              {intl.formatMessage({
                id: 'settings.shift-plans.shift-plans',
              })}
            </SidebarItem>
          )}
          <SidebarItem
            active={currentTab === GlobalSettingsTabs.TRANSLATIONS}
            onClick={() => history.push(`/settings/${GlobalSettingsTabs.TRANSLATIONS}`)}
          >
            {intl.formatMessage({
              id: 'settings.translations',
            })}
          </SidebarItem>

          {isRootAdministrator && isDeveloper && isOnPremise && (
            <SidebarItem
              onClick={() =>
                window.open(
                  `/api/v1/settings/download-sentry-envelopes?accesstoken=${userToken}`,
                  '_blank',
                  'noreferrer',
                )
              }
            >
              {intl.formatMessage({
                id: 'settings.download-sentry-envelope',
              })}
            </SidebarItem>
          )}
        </SidebarList>
        <div className={styles.version}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.workerbase.com/hc/en-us/categories/9520705610397-Release-Notes"
          >
            {`v${VERSION}`}
          </a>
        </div>
        {isRootAdministrator && isDeveloper && (
          <SvgIcon
            component={ElasticSearchLogo}
            inheritViewBox
            onClick={() => setOpenSyncElastic(true)}
            sx={{ position: 'absolute', bottom: '16px', left: '16px' }}
          />
        )}
      </Sidebar>
      {sidebarLayoutContent}
      {openSyncElastic && <ResyncElastic isOpen={openSyncElastic} onClose={() => setOpenSyncElastic(false)} />}
    </MainContainer>
  );
};
