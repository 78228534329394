/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import { Link, Stack, Typography } from '@mui/material';

export interface WuiFileDropzoneProps {
  onDrop: (files: File[]) => void;
  accept: string[];
  error?: boolean;
  multiple?: boolean;
}

export const WuiFileDropzone: FC<WuiFileDropzoneProps> = ({ accept, onDrop, multiple = false, error = false }) => {
  const intl = useIntl();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, multiple });

  return (
    <Stack
      sx={{
        width: '100%',
        height: '152px',
        border: 1,
        borderColor: isDragActive ? 'primary.main' : 'divider',
        borderStyle: 'dashed',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <UploadFileIcon sx={{ color: error ? 'error.main' : 'primary.main', mb: 1 }} />
      {isDragActive ? (
        <Typography color={error ? 'error.main' : 'primary.main'}>
          {intl.formatMessage({ id: 'documents.upload-files.dropzone-info.drop-files' })}
        </Typography>
      ) : (
        <Typography>
          {intl.formatMessage(
            {
              id: 'documents.upload-files.dropzone-info.text',
            },
            {
              link: (
                <Link
                  component="a"
                  sx={{
                    color: error ? 'error.main' : 'primary.main',
                    textDecorationColor: error ? 'error.main' : 'primary.main',
                  }}
                >
                  {intl.formatMessage({ id: 'documents.upload-files.dropzone-info.link' })}
                </Link>
              ),
            },
          )}
        </Typography>
      )}
      <Typography variant="body2" color="grey.500">
        {accept.join(', ')}
      </Typography>
    </Stack>
  );
};
