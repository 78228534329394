import { Prop, Schema } from '@nestjs/mongoose';
import { Equals } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

export const INFO_EMPTY_ID = 'infoempty';

@Schema()
export class InfoEmpty extends StepBase {
  type: StepTypes.INFO_EMPTY;

  @Prop({
    required: true,
    type: String,
    enum: [INFO_EMPTY_ID],
    default: INFO_EMPTY_ID,
  })
  @Equals(INFO_EMPTY_ID)
  id: typeof INFO_EMPTY_ID;
}
