import { BaseStep } from '@workerbase/types/domain/workinstruction/step/steps/Base';
import { StepsIconTypes } from '@workerbase/types/WorkinstructionStep/StepsIconTypes';
import { StepTypes, ListOptionsSource } from '@workerbase/types/domain/workinstruction/step';
import { generateStepId } from '@workerbase/utils/generateStepId';

import {
  InfoAudio,
  InfoLocationDirection,
  InfoPdf,
  InfoPhoto,
  InfoQrCode,
  InfoStatus,
  InfoText,
  InfoTextLarge,
  InfoVideo,
  InfoLoading,
  InfoWebview,
  InputAudio,
  InputBarcode,
  InputButtonList,
  InputCheckboxList,
  InputIndexList,
  InputLocation,
  INPUT_NOICE_LEVEL_DURATION_DEFAULT,
  INPUT_NOICE_LEVEL_MAX_VALUE_DEFAULT,
  InputNoiseLevel,
  INPUT_NUMBER_DECIMAL_DIGITS_DEFAULT,
  INPUT_NUMBER_INTEGER_DIGITS_DEFAULT,
  InputNumber,
  INPUT_NUMBER_PICKER_INTEGER_DIGITS_DEFAULT,
  INPUT_NUMBER_PICKER_DECIMAL_DIGITS_DEFAULT,
  InputNumberPicker,
  InputPhoto,
  InputStepMenu,
  InputText,
  InputTileList,
  InputValueList,
  InputVideo,
  LayoutAssemblyPicture,
} from '@workerbase/types/domain/workinstruction/step/steps';

import { ImageScaleType } from '@workerbase/types/ImageScaleType';
import { LayoutOrientation } from '@workerbase/types/LayoutOrientation';
import {
  InputListBaseStep,
  LayoutAssemblyDetail,
  LayoutAssemblyVideo,
  LayoutFlexStep,
  LayoutScrewFitting,
  ModuleStep,
} from '@workerbase/types/MongoDB/Workinstruction/Steps';

import { StepActionTypes } from '@workerbase/types/StepActionTypes';
import { DatabaseViewMappingBody } from '@workerbase/types/api/workinstructions';
import { RichTextConverter } from '@workerbase/utils/RichTextConverter';
import { WiWorkflowType } from 'components/WorkinstructionForm/types';
import { getDefaultStepButtons } from 'components/WorkinstructionForm/utils/getDefaultStepButtons';
import { WorkinstructionStep } from 'services/types/Workinstruction';
import { FlexDirection } from '@workerbase/types/MongoDB/Workinstruction/Steps/FlexDirection.enum';
import { ObjectId } from './generateObjectId';

type ListSourceData = Pick<InputListBaseStep, 'isVar' | 'loadOptionsFromUrl' | 'loadFromUrl'> & {
  mapping?: DatabaseViewMappingBody;
};

export const getListOptionsSource = ({
  loadOptionsFromUrl,
  loadFromUrl,
  isVar,
  mapping,
}: ListSourceData): ListOptionsSource => {
  if (loadOptionsFromUrl && loadFromUrl) {
    return ListOptionsSource.CONNECTOR;
  }
  if (isVar) {
    return ListOptionsSource.VARIABLE;
  }
  if (mapping) {
    return ListOptionsSource.DATABASE;
  }
  return ListOptionsSource.OPTIONS;
};

export const INITIAL_WI_STEP_TYPE = StepTypes.INFO_TEXT;

type GenerateWorkinstructionStep =
  | {
      type: Exclude<StepTypes, StepTypes.MODULE>;
      workflowType: WiWorkflowType;
      previousStep?: WorkinstructionStep;
      stepId?: string;
      title?: string;
      stepBody?: never;
    }
  | {
      type: StepTypes.MODULE;
      workflowType: WiWorkflowType;
      previousStep?: WorkinstructionStep;
      stepId?: string;
      title?: string;
      stepBody: Pick<ModuleStep, 'moduleId'>;
    };

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line complexity
export const generateWorkinstructionStep = ({
  type,
  workflowType,
  previousStep,
  stepId,
  title,
  stepBody,
}: GenerateWorkinstructionStep): WorkinstructionStep => {
  const baseStep: BaseStep = {
    _id: ObjectId(),
    id: stepId || generateStepId(),
    type,
    title: title || '',
    buttons: (previousStep && 'buttons' in previousStep && previousStep.buttons) || getDefaultStepButtons(workflowType),
    // TODO: WB-4080 (Modules): Ignore for now. The generation of module steps has to be adapted anyways.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadVariablesFromUrl: previousStep?.loadVariablesFromUrl,
  };

  switch (type) {
    case StepTypes.INFO_WEBVIEW: {
      const baseInfoWebviewStep: InfoWebview = {
        ...baseStep,
        type: StepTypes.INFO_WEBVIEW, // Have to put this again here as TS doesn't get prev assignment.
        url: previousStep && 'url' in previousStep ? previousStep.url : '',
      };
      return baseInfoWebviewStep;
    }
    case StepTypes.INFO_TEXT: {
      const baseInfoTextStep: InfoText = {
        ...baseStep,
        type: StepTypes.INFO_TEXT, // Have to put this again here as TS doesn't get prev assignment.
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
      };
      return baseInfoTextStep;
    }
    case StepTypes.INPUT_LOCATION: {
      const baseInputLocationStep: InputLocation = {
        ...baseStep,
        type: StepTypes.INPUT_LOCATION, // Have to put this again here as TS doesn't get prev assignment.
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        minAccuracyInMeters: 25,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputLocationStep;
    }
    case StepTypes.INFO_LOCATION_DIRECTION: {
      const baseInfoLocationStep: InfoLocationDirection = {
        ...baseStep,
        // Have to put this again here as TS doesn't get prev assignment.
        type: StepTypes.INFO_LOCATION_DIRECTION,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        gpsLocationLatitude: '48.13913',
        gpsLocationLongitude: '11.58022',
      };
      return baseInfoLocationStep;
    }
    case StepTypes.INFO_PHOTO: {
      const baseInfoPhotoStep: InfoPhoto = {
        ...baseStep,
        type: StepTypes.INFO_PHOTO,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        mediaId: '',
        isVar: false,
        styles: { image: { scaleType: ImageScaleType.FIT } },
      };
      return baseInfoPhotoStep;
    }
    case StepTypes.INFO_VIDEO: {
      const baseInfoVideoStep: InfoVideo = {
        ...baseStep,
        type: StepTypes.INFO_VIDEO,
        isVar: false,
        mediaId: '',
      };
      return baseInfoVideoStep;
    }
    case StepTypes.INFO_PDF: {
      const DEFAULT_PAGE = '1';
      const page =
        previousStep && 'page' in previousStep && Number.parseInt(previousStep.page, 10) > 0
          ? previousStep.page.toString()
          : DEFAULT_PAGE;

      const baseInfoPdfStep: InfoPdf = {
        ...baseStep,
        type: StepTypes.INFO_PDF,
        isVar: false,
        mediaId: '',
        slideModeEnabled: false,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        page,
      };
      return baseInfoPdfStep;
    }
    case StepTypes.INFO_AUDIO: {
      const baseInfoAudioStep: InfoAudio = {
        ...baseStep,
        type: StepTypes.INFO_AUDIO,
        mediaId: '',
        isVar: false,
      };
      return baseInfoAudioStep;
    }
    case StepTypes.INFO_STATUS: {
      const baseInfoStatusStep: InfoStatus = {
        ...baseStep,
        type: StepTypes.INFO_STATUS,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        headline: previousStep && 'headline' in previousStep ? previousStep.headline : '',
        color: previousStep && 'color' in previousStep ? previousStep.color : '#3E4D59',
        icon: previousStep && 'icon' in previousStep ? previousStep.icon : StepsIconTypes.ICON_INFO,
      };
      return baseInfoStatusStep;
    }
    case StepTypes.INFO_LOADING: {
      const baseInfoLoadingStep: InfoLoading = {
        ...baseStep,
        type: StepTypes.INFO_LOADING,
        taskAppearingDuration: 0,
      };
      return baseInfoLoadingStep;
    }
    case StepTypes.INFO_QRCODE: {
      const baseInfoQrCodeStep: InfoQrCode = {
        ...baseStep,
        type: StepTypes.INFO_QRCODE,
        qrcode: previousStep && 'qrcode' in previousStep ? previousStep.qrcode : '',
      };
      return baseInfoQrCodeStep;
    }
    case StepTypes.INFO_TEXT_LARGE: {
      const baseInfoTextLargeStep: InfoTextLarge = {
        ...baseStep,
        type: StepTypes.INFO_TEXT_LARGE,
        description:
          previousStep && 'description' in previousStep
            ? RichTextConverter.convertRichTextToPlainText(previousStep.description)
            : '',
      };
      return baseInfoTextLargeStep;
    }
    case StepTypes.INPUT_TEXT: {
      const baseInputTextStep: InputText = {
        ...baseStep,
        type: StepTypes.INPUT_TEXT,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputTextStep;
    }
    case StepTypes.INPUT_NUMBER_PICKER: {
      const baseInputNumberPickerStep: InputNumberPicker = {
        ...baseStep,
        type: StepTypes.INPUT_NUMBER_PICKER,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        integerDigits:
          previousStep && 'integerDigits' in previousStep
            ? previousStep.integerDigits
            : INPUT_NUMBER_PICKER_INTEGER_DIGITS_DEFAULT,
        decimalDigits:
          previousStep && 'decimalDigits' in previousStep
            ? previousStep.decimalDigits
            : INPUT_NUMBER_PICKER_DECIMAL_DIGITS_DEFAULT,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputNumberPickerStep;
    }
    case StepTypes.INPUT_NUMBER: {
      const baseInputNumberStep: InputNumber = {
        ...baseStep,
        type: StepTypes.INPUT_NUMBER,
        integerDigits:
          previousStep && 'integerDigits' in previousStep
            ? previousStep.integerDigits
            : INPUT_NUMBER_INTEGER_DIGITS_DEFAULT,
        decimalDigits:
          previousStep && 'decimalDigits' in previousStep
            ? previousStep.decimalDigits
            : INPUT_NUMBER_DECIMAL_DIGITS_DEFAULT,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputNumberStep;
    }
    case StepTypes.INPUT_NOISE_LEVEL: {
      const baseInputNoiseLevelStep: InputNoiseLevel = {
        ...baseStep,
        type: StepTypes.INPUT_NOISE_LEVEL,
        warningThresholdInDecibel:
          previousStep && 'warningThresholdInDecibel' in previousStep
            ? previousStep.warningThresholdInDecibel
            : INPUT_NOICE_LEVEL_MAX_VALUE_DEFAULT,
        autoStartMeasurement:
          previousStep && 'autoStartMeasurement' in previousStep ? previousStep.autoStartMeasurement : true,
        autoContinue: previousStep && 'autoContinue' in previousStep ? previousStep.autoContinue : false,
        inputRequired: previousStep && 'inputRequired' in previousStep ? previousStep.inputRequired : true,
        measureDurationInSec:
          previousStep && 'measureDurationInSec' in previousStep
            ? previousStep.measureDurationInSec
            : INPUT_NOICE_LEVEL_DURATION_DEFAULT,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputNoiseLevelStep;
    }
    case StepTypes.INPUT_BARCODE: {
      const baseInputBarcodeStep: InputBarcode = {
        ...baseStep,
        type: StepTypes.INPUT_BARCODE,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        autoContinue: previousStep && 'autoContinue' in previousStep ? previousStep.autoContinue : false,
        regex: previousStep && 'regex' in previousStep ? previousStep.regex : [],
        regexOp: previousStep && 'regexOp' in previousStep ? previousStep.regexOp : [],
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        allowManualBarcodeInput: false,
      };
      return baseInputBarcodeStep;
    }
    case StepTypes.INPUT_VALUE_LIST: {
      const loadFromUrl = previousStep && 'loadFromUrl' in previousStep ? previousStep.loadFromUrl : false;
      const loadOptionsFromUrl =
        previousStep && 'loadOptionsFromUrl' in previousStep && previousStep.loadFromUrl
          ? previousStep.loadOptionsFromUrl
          : undefined;
      const isVar = previousStep && 'isVar' in previousStep ? previousStep.isVar : false;
      const mapping = previousStep && 'mapping' in previousStep ? previousStep.mapping : undefined;
      const baseInputValueListStep: InputValueList = {
        ...baseStep,
        type: StepTypes.INPUT_VALUE_LIST,
        minSelect: previousStep && 'minSelect' in previousStep ? previousStep.minSelect : 1,
        autoContinue: previousStep && 'autoContinue' in previousStep ? previousStep.autoContinue : false,
        customNextStep: false,
        showSearchBar: false,
        // cannot convert options from checkbox
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text ? option.text : option.title,
                action: option.action,
                icon: option.icon,
              }))
            : [],
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        isVar,
        loadFromUrl,
        loadOptionsFromUrl,
        source: getListOptionsSource({
          loadFromUrl,
          loadOptionsFromUrl,
          isVar,
          mapping,
        }),
      } as InputValueList;

      return baseInputValueListStep;
    }
    case StepTypes.INPUT_STEP_MENU: {
      const baseInputStepMenuStep: InputStepMenu = {
        ...baseStep,
        type: StepTypes.INPUT_STEP_MENU,
        autoContinue: previousStep && 'autoContinue' in previousStep ? previousStep.autoContinue : true,
        source: ListOptionsSource.OPTIONS,
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text,
                action: option.action,
                icon: option.icon,
              }))
            : [],
        outputVarName: baseStep.id,
      };
      return baseInputStepMenuStep;
    }
    case StepTypes.INPUT_CHECKBOX_LIST: {
      const baseInputCheckboxListStep: InputCheckboxList = {
        ...baseStep,
        type: StepTypes.INPUT_CHECKBOX_LIST,
        source: ListOptionsSource.OPTIONS,
        selectAll: previousStep && 'selectAll' in previousStep ? previousStep.selectAll : false,
        minSelect: previousStep && 'minSelect' in previousStep ? previousStep.minSelect : 1,
        showSearchBar: false,
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text,
                action: option.action,
                icon: option.icon,
                isChecked: false,
              }))
            : [],
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        isVar: previousStep && 'isVar' in previousStep ? previousStep.isVar : false,
        loadFromUrl: previousStep && 'loadFromUrl' in previousStep ? previousStep.loadFromUrl : false,
        loadOptionsFromUrl:
          previousStep && 'loadOptionsFromUrl' in previousStep && previousStep.loadFromUrl
            ? previousStep.loadOptionsFromUrl
            : undefined,
      } as InputCheckboxList;
      return baseInputCheckboxListStep;
    }
    case StepTypes.INPUT_INDEX_LIST: {
      const baseInputIndexListStep: InputIndexList = {
        ...baseStep,
        type: StepTypes.INPUT_INDEX_LIST,
        minSelect: previousStep && 'minSelect' in previousStep ? previousStep.minSelect : 1,
        source: ListOptionsSource.OPTIONS,
        showSearchBar: false,
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text,
                action: option.action,
                icon: option.icon,
                isHeadline: false,
                title: '',
              }))
            : [],
        autoContinue: previousStep && 'autoContinue' in previousStep ? previousStep.autoContinue : false,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        isVar: previousStep && 'isVar' in previousStep ? previousStep.isVar : false,
        loadFromUrl: previousStep && 'loadFromUrl' in previousStep ? previousStep.loadFromUrl : false,
        loadOptionsFromUrl:
          previousStep && 'loadOptionsFromUrl' in previousStep && previousStep.loadFromUrl
            ? previousStep.loadOptionsFromUrl
            : undefined,
      } as InputIndexList;

      return baseInputIndexListStep;
    }
    case StepTypes.INPUT_TILE_LIST: {
      const baseInputTileListStep: InputTileList = {
        ...baseStep,
        type: StepTypes.INPUT_TILE_LIST,
        source: ListOptionsSource.OPTIONS,
        customNextStep: false,
        minSelect: previousStep && 'minSelect' in previousStep ? previousStep.minSelect : 1,
        autoContinue: true,
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text,
                action: option.action,
              }))
            : [],
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        isVar: previousStep && 'isVar' in previousStep ? previousStep.isVar : false,
        loadFromUrl: previousStep && 'loadFromUrl' in previousStep ? previousStep.loadFromUrl : false,
        loadOptionsFromUrl:
          previousStep && 'loadOptionsFromUrl' in previousStep && previousStep.loadFromUrl
            ? previousStep.loadOptionsFromUrl
            : undefined,
      } as InputTileList;

      return baseInputTileListStep;
    }
    case StepTypes.INPUT_AUDIO: {
      const baseInputAudioStep: InputAudio = {
        ...baseStep,
        type: StepTypes.INPUT_AUDIO,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputAudioStep;
    }
    case StepTypes.INPUT_VIDEO: {
      const baseInputVideoStep: InputVideo = {
        ...baseStep,
        type: StepTypes.INPUT_VIDEO,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputVideoStep;
    }
    case StepTypes.INPUT_PHOTO: {
      const baseInputPhotoStep: InputPhoto = {
        ...baseStep,
        type: StepTypes.INPUT_PHOTO,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
      };
      return baseInputPhotoStep;
    }
    case StepTypes.INPUT_BUTTON_LIST: {
      const baseInputButtonListStep: InputButtonList = {
        ...baseStep,
        type: StepTypes.INPUT_BUTTON_LIST,
        source: ListOptionsSource.OPTIONS,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        minSelect: previousStep && 'minSelect' in previousStep ? previousStep.minSelect : 1,
        options:
          previousStep && 'options' in previousStep && Array.isArray(previousStep.options)
            ? (previousStep.options as any).map((option) => ({
                id: option.id,
                text: option.text,
                action: option.action,
                icon: option.icon,
                isChecked: false,
              }))
            : [],
        autoContinue: true,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        isVar: previousStep && 'isVar' in previousStep ? previousStep.isVar : false,
        loadFromUrl: previousStep && 'loadFromUrl' in previousStep ? previousStep.loadFromUrl : false,
        loadOptionsFromUrl:
          previousStep && 'loadOptionsFromUrl' in previousStep && previousStep.loadFromUrl
            ? previousStep.loadOptionsFromUrl
            : undefined,
      } as InputButtonList;
      return baseInputButtonListStep;
    }
    case StepTypes.LAYOUT_ASSEMBLY_PICTURE: {
      const baseLayoutAssemblyPicture: LayoutAssemblyPicture = {
        ...baseStep,
        type: StepTypes.LAYOUT_ASSEMBLY_PICTURE,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        orientation: LayoutOrientation.HORIZONTAL,
        mediaId: '',
        isVar: false,
        styles: { image: { scaleType: ImageScaleType.FIT } },
      };
      return baseLayoutAssemblyPicture;
    }
    case StepTypes.LAYOUT_ASSEMBLY_VIDEO:
      return {
        ...baseStep,
        type: StepTypes.LAYOUT_ASSEMBLY_VIDEO,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        orientation: LayoutOrientation.HORIZONTAL,
        isVar: false,
        mediaId: '',
      } as LayoutAssemblyVideo;
    case StepTypes.LAYOUT_ASSEMBLY_DETAIL: {
      const prevMaterialList = (previousStep as LayoutAssemblyDetail).materialsList;

      const isVar = prevMaterialList && 'isVar' in prevMaterialList ? prevMaterialList.isVar : false;
      const loadFromUrl = prevMaterialList && 'loadFromUrl' in prevMaterialList ? prevMaterialList.loadFromUrl : false;
      const loadOptionsFromUrl =
        prevMaterialList && 'loadOptionsFromUrl' in prevMaterialList && prevMaterialList.loadFromUrl
          ? prevMaterialList.loadOptionsFromUrl
          : undefined;
      const mapping = previousStep && 'mapping' in previousStep ? previousStep.mapping : undefined;

      const baseLayoutAssemblyDetail: LayoutAssemblyDetail = {
        ...baseStep,
        type: StepTypes.LAYOUT_ASSEMBLY_DETAIL,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        mediaId: '',
        isVar: false,
        styles: { image: { scaleType: ImageScaleType.FIT } },
        materialsList: {
          options:
            prevMaterialList && 'options' in prevMaterialList && Array.isArray(prevMaterialList.options)
              ? (prevMaterialList.options as any).map((option) => ({
                  id: option.id,
                  text: option.text,
                  action: option.action,
                  icon: option.icon,
                }))
              : [],
          showSearchBar: false,
          isVar,
          loadFromUrl,
          loadOptionsFromUrl,
          source: getListOptionsSource({
            loadFromUrl,
            loadOptionsFromUrl,
            isVar,
            mapping,
          }),
        },
      };
      return baseLayoutAssemblyDetail;
    }
    case StepTypes.LAYOUT_SCREW_FITTING: {
      const prevMaterialList = (previousStep as LayoutScrewFitting).materialsList;

      const isVar = prevMaterialList && 'isVar' in prevMaterialList ? prevMaterialList.isVar : false;
      const loadFromUrl = prevMaterialList && 'loadFromUrl' in prevMaterialList ? prevMaterialList.loadFromUrl : false;
      const loadOptionsFromUrl =
        prevMaterialList && 'loadOptionsFromUrl' in prevMaterialList && prevMaterialList.loadFromUrl
          ? prevMaterialList.loadOptionsFromUrl
          : undefined;
      const mapping = previousStep && 'mapping' in previousStep ? previousStep.mapping : undefined;

      const baseLayoutScrewFitting: LayoutScrewFitting = {
        ...baseStep,
        type: StepTypes.LAYOUT_SCREW_FITTING,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        mediaId: '',
        isVar: false,
        styles: { image: { scaleType: ImageScaleType.FIT } },
        materialsList: {
          options:
            prevMaterialList && 'options' in prevMaterialList && Array.isArray(prevMaterialList.options)
              ? (prevMaterialList.options as any).map((option) => ({
                  id: option.id,
                  text: option.text,
                  action: option.action,
                  icon: option.icon,
                }))
              : [],
          showSearchBar: false,
          isVar,
          loadFromUrl,
          loadOptionsFromUrl,
          source: getListOptionsSource({
            loadFromUrl,
            loadOptionsFromUrl,
            isVar,
            mapping,
          }),
        },
        bit: { mediaId: '', isVar: false },
        count: '',
        torque: '',
      };
      return baseLayoutScrewFitting;
    }
    case StepTypes.LAYOUT_FLEX:
      return {
        ...baseStep,
        type: StepTypes.LAYOUT_FLEX,
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
        direction: FlexDirection.ROW,
        outputVarName: previousStep && 'outputVarName' in previousStep ? previousStep.outputVarName : baseStep.id,
        children: [],
      } as LayoutFlexStep;
    case StepTypes.MODULE:
      if (!stepBody.moduleId) {
        console.warn(`"moduleId" is required when generating Module WI step`);
      }

      return {
        _id: baseStep._id,
        id: baseStep.id,
        type: StepTypes.MODULE,
        abortAction: { type: StepActionTypes.SUSPEND },
        finishAction: { type: StepActionTypes.FINISH },
        moduleId: stepBody.moduleId,
      };
    default: {
      // By default return InfoText
      const baseInfoTextStep: InfoText = {
        ...baseStep,
        type: StepTypes.INFO_TEXT, // Have to put this again here as TS doesn't get previous assignment.
        description: previousStep && 'description' in previousStep ? previousStep.description : '',
      };
      return baseInfoTextStep;
    }
  }
};
