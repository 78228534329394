import React, { FC, ReactNode } from 'react';
import { Avatar, Stack, SxProps, Theme, Typography } from '@mui/material';
import { DateFormatter } from '@workerbase/types/DateFormatter';

export interface WuiUserAndDateLabelProps {
  user?: { firstName: string; lastName: string };
  /**
   * If you want to show a different name than the one in the user object
   */
  overridenName?: ReactNode;
  date?: string;
  showAvatar?: boolean;
  /**
   * sx props to be passed to the root component either Stack or Typography (when date only)
   */
  sx?: SxProps<Theme>;
  sxAvatar?: SxProps<Theme>;
  avatarSize?: 'standard' | 'small';
}

// TODO: add the possibility to get the user image by ID
/**
 * Component to show user name, avatar and/or date in consistent format across the application
 */
export const WuiUserAndDateLabel: FC<WuiUserAndDateLabelProps> = ({
  user,
  date,
  showAvatar = true,
  sx,
  sxAvatar,
  overridenName,
  avatarSize = 'standard',
}) => {
  const dateObj = date ? new Date(date) : null;

  if (!user && dateObj) {
    return (
      <Typography sx={sx} variant={avatarSize === 'small' ? 'subtitle2' : 'inherit'}>
        {dateObj.toLocaleDateString()} {dateObj.toLocaleTimeString()}
      </Typography>
    );
  }

  if (!user) {
    return null;
  }

  let newSxAvatar: Record<string, unknown> = { ...sxAvatar };

  if (avatarSize === 'small') {
    newSxAvatar = {
      ...newSxAvatar,
      height: 24,
      width: 24,
      display: 'flex',
      alignItems: 'flex-end',
    };
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={sx}>
      {showAvatar && (
        <Avatar
          sx={{
            backgroundColor: '#BDBDBD',
            ...(newSxAvatar ?? {}),
          }}
        >
          {avatarSize === 'small' ? (
            <Typography variant="subtitle2">{`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()}</Typography>
          ) : (
            `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
          )}
        </Avatar>
      )}
      <Stack>
        {overridenName ?? (
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
        )}
        {dateObj && (
          <Typography variant="body2" color="text.secondary">
            {DateFormatter.format(dateObj, DateFormatter.Formats.DATE)}{' '}
            {DateFormatter.format(dateObj, DateFormatter.Formats.TIME)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
