import { handleRequestError } from '@redux/common';
import { useDispatch } from 'react-redux';
import { useRequest as useAhooksRequest } from 'ahooks';
import { Options as UseRequestOptions, Service, Result, Plugin } from 'ahooks/lib/useRequest/src/types';

export interface Options<TData, TParams extends unknown[]>
  extends Omit<UseRequestOptions<TData, TParams>, 'defaultParams'> {
  params?: TParams;
}

/**
 * Wrapper that handles request errors and simplifies usage of parameters of ahooks useRequest.
 * Removed the `defaultParams` from the `options` interface and replace with just `params`.
 * If you wanna refetch on `param` variable change just add it to the `refreshDeps`

 * @example   const { data } = useRequest(getUser, {
                params: [id],
                refreshDeps: [id], //if you want fresh data on id change
              });
 */
export const useApiRequest = <TData, TParams extends unknown[]>(
  service: Service<TData, TParams>,
  options?: Options<TData, TParams> & { errorToastMessage?: string | ((params: TParams) => string) },
  plugins?: Plugin<TData, TParams>[],
): Result<TData, TParams> => {
  const dispatch = useDispatch();

  const params = options?.params;

  const optionsWithGlobalErrorHandler = {
    ...(options ?? {}),
    onError(e: Error, errorParams: TParams) {
      const errorMessage =
        typeof options?.errorToastMessage === 'function'
          ? options?.errorToastMessage(errorParams)
          : options?.errorToastMessage;

      dispatch(handleRequestError(e, errorMessage));
      options?.onError?.(e, errorParams);
    },
  };

  return useAhooksRequest(params ? () => service(...params) : service, optionsWithGlobalErrorHandler, plugins);
};
