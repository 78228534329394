import { Prop, Schema } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsDefined, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { ModelNames } from '../../ModelNames';
import {
  StepAction,
  StepActionBase,
  StepActionBaseSchema,
  StepActionClassByActionType,
  StepActionDiscriminator,
} from '../StepAction';
import { IModuleStep, StepTypes } from '../../../domain/workinstruction/step';
import { StepCore } from './base/StepCore';

@Schema({ strict: true })
export class ModuleStep extends StepCore implements IModuleStep {
  type: StepTypes.MODULE;

  @Prop({ type: mongoose.Schema.Types.ObjectId, ref: ModelNames.Module, required: true })
  moduleId: string;

  @Prop({
    type: StepActionBaseSchema,
    discriminators: StepActionDiscriminator,
    required: true,
  })
  @ValidateNested()
  @IsDefined()
  @Type(() => StepActionBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: Object.entries(StepActionClassByActionType).map(([actionType, action]) => ({
        name: actionType,
        value: action,
      })),
    },
  })
  finishAction: StepAction;

  @Prop({
    type: StepActionBaseSchema,
    discriminators: StepActionDiscriminator,
    required: true,
  })
  @ValidateNested()
  @IsDefined()
  @Type(() => StepActionBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: Object.entries(StepActionClassByActionType).map(([actionType, action]) => ({
        name: actionType,
        value: action,
      })),
    },
  })
  abortAction: StepAction;
}
