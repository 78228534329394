import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/Button';
import BaseModal from 'components/Modal/BaseModal';

import styles from './ActionModal.module.scss';

interface Props {
  title: string;
  mainButtonTranslationId: string;
  mainButtonVariant?: 'primary' | 'danger';
  cancelButtonTranslationId?: string;
  isMainButtonDisabled?: boolean;
  children: ReactNode;
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}

const Modal: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  mainButtonTranslationId,
  mainButtonVariant = 'primary',
  isMainButtonDisabled = false,
  children,
  cancelButtonTranslationId = 'global.cancel',
}) => {
  const intl = useIntl();

  const onRefChange = useCallback((ref: HTMLButtonElement) => {
    if (ref) {
      ref.focus();
    }
  }, []);

  return (
    <BaseModal open={open} onClose={onClose} isCloseIconEnabled={false}>
      <div>
        <h2>{title}</h2>
        <div className={styles.contentContainer}>{children}</div>
        <div className={styles.buttonsContainer}>
          <Button
            type="button"
            variant="light"
            title={intl.formatMessage({ id: cancelButtonTranslationId })}
            onClick={onClose}
            icon={<CloseIcon fontSize="small" />}
          />
          <Button
            type="button"
            disabled={isMainButtonDisabled}
            variant={mainButtonVariant}
            title={intl.formatMessage({ id: mainButtonTranslationId })}
            onClick={onConfirm}
            ref={onRefChange}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default Modal;
