import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InfoMediaStepBase } from './base/InfoMediaStepBase';
import { InfoPhotoStyles } from './base/InfoPhotoStyles';

@Schema()
export class InfoPhoto extends InfoMediaStepBase {
  type: StepTypes.INFO_PHOTO;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  @IsOptional()
  hideZoomButton?: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;
}
