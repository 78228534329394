const VAR_REGEX = /\$([\w-]+(\.[\w-]+)*)/g;

export const getVariableNamesFromString = (source = ''): string[] => {
  let matches = null;
  const results: string[] = [];
  do {
    matches = VAR_REGEX.exec(source);
    if (matches) {
      results.push(matches[1]);
    }
  } while (matches);
  return results;
};
