import { ListOptionsSource } from '../ListOptionsSource.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import {
  InputStepMenuOption,
  InputStepMenu as InputStepMenuStep,
} from '../../../../MongoDB/Workinstruction/Steps/InputStepMenu';

type BaseInputStepMenu = InputStepMenuStep;

export { InputStepMenuOption };

export interface InputStepMenuWithOptions
  extends Omit<BaseInputStepMenu, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options'> {
  source: ListOptionsSource.OPTIONS;
  options: InputStepMenuOption[];
}

export type InputStepMenu = InputStepMenuWithOptions;
