import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsString, ValidateNested } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputBarcodeRegex } from './InputBarcodeRegex';
import { InputableStep } from './base/InputableStep';

@Schema()
export class InputBarcode extends InputableStep {
  type: StepTypes.INPUT_BARCODE;

  @Prop([{ type: SchemaFactory.createForClass(InputBarcodeRegex) }])
  @ValidateNested({ each: true })
  @Type(() => InputBarcodeRegex)
  regexOp: InputBarcodeRegex[];

  @Prop()
  @IsString({ each: true })
  regex: string[];

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop({ default: false })
  @IsBoolean()
  allowManualBarcodeInput: boolean;
}
