/* eslint-disable react/react-in-jsx-scope */
import { Button, ButtonGroup, Checkbox, Stack } from '@mui/material';
import DataTimeInput from 'components/Input/DataTimeInput';
import ActionModal from 'components/Modal/ActionModal';
import { useApiRequest } from 'hooks/useRequest';
import _react, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { triggerElasticSync } from 'services/networking/settings';

export interface ResyncElasticProps {
  isOpen: boolean;
  onClose: () => void;
}

enum Durations {
  LAST_HOUR = '1h',
  LAST_6_HOURS = '6h',
  LAST_24_HOURS = '24h',
  CUSTOM = '',
}

export const ResyncElastic: FC<ResyncElasticProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'settings.elasticsearch-sync',
      }),
    [intl],
  );

  const [timeSelect, setTimeSelect] = useState<Durations>(Durations.LAST_HOUR);

  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setHours(date.getHours() - 1);
    return date;
  });
  const [toDate, setToDate] = useState(() => new Date());
  const [includeTaskAnalytics, setIncludeTaskAnalytics] = useState(false);
  const [includeDatabaseItems, setIncludeDatabaseItems] = useState(false);
  const [disableMainButton, setDisableMainButton] = useState(false);

  const elasticSyncRequest = useApiRequest(triggerElasticSync, {
    manual: true,
    onSuccess: (_data) => {
      toast.success(intl.formatMessage({ id: 'settings.elasticsearch-trigger-sync-success' }));
      onClose();
    },
    onError: () => {
      setDisableMainButton(false);
    },
  });

  const onSetCheckbox = useCallback(
    (setCheckbox: Dispatch<SetStateAction<boolean>>) => (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setCheckbox(checked);
    },
    [],
  );

  const wrapSetDate = useCallback(
    (setDate: Dispatch<SetStateAction<Date>>) => (value: Date) => {
      setTimeSelect(Durations.CUSTOM);
      setDate(value);
    },
    [],
  );

  const onConfirm = useCallback(() => {
    elasticSyncRequest.run({
      beginDate: fromDate,
      endDate: toDate,
      includeDatabaseItems,
      includeTaskAnalytics,
    });
    setDisableMainButton(true);
  }, [fromDate, toDate, includeTaskAnalytics, includeDatabaseItems]);

  const setDuration = (duration: Durations) => () => {
    const to = new Date();
    const from = new Date();
    switch (duration) {
      case Durations.LAST_HOUR:
        from.setHours(from.getHours() - 1);
        break;
      case Durations.LAST_6_HOURS:
        from.setHours(from.getHours() - 6);
        break;
      case Durations.LAST_24_HOURS:
        from.setHours(from.getHours() - 24);
        break;
      case Durations.CUSTOM:
      default:
        setTimeSelect(Durations.CUSTOM);
        return;
    }
    setFromDate(from);
    setToDate(to);
    setTimeSelect(duration);
  };

  return (
    <ActionModal
      title={title}
      mainButtonTranslationId="settings.elasticsearch-trigger-sync"
      isMainButtonDisabled={disableMainButton}
      onConfirm={onConfirm}
      open={isOpen}
      onClose={onClose}
    >
      <Stack direction="row" spacing={3}>
        <DataTimeInput
          type="DateTime"
          label="period.from"
          labelIsTranslationId
          field={{
            name: 'fromDate',
            value: fromDate.toString(),
            onChange: wrapSetDate(setFromDate),
          }}
        />
        <DataTimeInput
          type="DateTime"
          label="period.to"
          labelIsTranslationId
          field={{
            name: 'toDate',
            value: toDate.toString(),
            onChange: wrapSetDate(setToDate),
          }}
        />
      </Stack>
      <ButtonGroup variant="outlined" size="small">
        <Button
          variant={timeSelect === Durations.LAST_HOUR ? 'contained' : 'outlined'}
          onClick={setDuration(Durations.LAST_HOUR)}
        >
          {intl.formatMessage({
            id: 'period.last-hour',
          })}
        </Button>
        <Button
          variant={timeSelect === Durations.LAST_6_HOURS ? 'contained' : 'outlined'}
          onClick={setDuration(Durations.LAST_6_HOURS)}
        >
          {intl.formatMessage({
            id: 'period.last-6-hours',
          })}
        </Button>
        <Button
          variant={timeSelect === Durations.LAST_24_HOURS ? 'contained' : 'outlined'}
          onClick={setDuration(Durations.LAST_24_HOURS)}
        >
          {intl.formatMessage({
            id: 'period.last-24-hours',
          })}
        </Button>
      </ButtonGroup>
      <br />
      <Checkbox
        checked={includeTaskAnalytics}
        onChange={onSetCheckbox(setIncludeTaskAnalytics)}
        id="elastic-sync-include-analytics"
      />
      <label htmlFor="elastic-sync-include-analytics">
        {intl.formatMessage({
          id: 'settings.elasticsearch-include-analytics',
        })}
      </label>
      <br />
      <Checkbox
        checked={includeDatabaseItems}
        onChange={onSetCheckbox(setIncludeDatabaseItems)}
        id="elasticsearch-include-database-items"
      />
      <label htmlFor="elasticsearch-include-database-items">
        {intl.formatMessage({
          id: 'settings.elasticsearch-include-database-items',
        })}
      </label>
    </ActionModal>
  );
};
