import React, { FC, ReactNode } from 'react';
import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';

export type WuiMenuItemProps = MenuItemProps & {
  key: string;
  label: ReactNode;
  icon?: ReactNode;
  /**
   * If true, a divider is added underneath the item.
   */
  divider?: boolean;
};

export const WuiMenuItem: FC<WuiMenuItemProps> = ({ label, icon, sx, onClick, ...itemProps }) => (
  <MenuItem
    sx={{ py: 1.5, px: 2, ...sx }}
    onClick={(e) => {
      onClick?.(e);
    }}
    {...itemProps}
  >
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText primary={label} primaryTypographyProps={{ noWrap: true }} />
  </MenuItem>
);
