import { raw } from '@nestjs/mongoose';
import mongoose from 'mongoose';
import { ModelNames } from '../ModelNames';

export const MetaDefinition = (VERSION = 0, extraRawSchemaFields = {}) =>
  raw({
    userId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.User,
      default: null,
    },
    createdBy: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.User,
      default: null,
    },
    updatedBy: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.User,
      default: null,
    },
    appId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.Application,
      default: null,
    },
    ruleId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.Rule,
      default: null,
    },
    functionId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.Function,
      default: null,
    },
    projectId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: ModelNames.Project,
      default: null,
    },
    app: { type: String },
    startedAt: { type: Date },
    createdAt: { type: Date },
    doneAt: { type: Date },
    updatedAt: { type: Date },
    importData: { type: mongoose.Schema.Types.ObjectId },
    schemaVersion: { index: 1, type: Number, default: VERSION },
    ...extraRawSchemaFields,
  });

export interface MetaInterface {
  userId?: string;
  createdBy?: string;
  updatedBy?: string;
  appId?: string;
  projectId?: string;
  functionId?: string;
  ruleId?: string;
  app?: string;
  startedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  doneAt?: Date;
  importData?: string;
  schemaVersion?: number;
}
