/* eslint-disable react/jsx-props-no-spreading --- needed in this file */
import React, { FC, useCallback } from 'react';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, FormControl, Skeleton } from '@mui/material';
import { useIntl } from 'react-intl';
import { isString } from 'lodash';
import { WuiFormLabel } from '../WuiFormLabel';
import { inputSizes } from '../../stylesheet';

export type WuiTextFieldProps = Omit<MuiTextFieldProps, 'label' | 'helperText'> & {
  name: string;
  /**
   * If `true`, the label and animated skeleton will be shown
   * @default false
   */
  loading?: boolean;
  /**
   * The pattern attribute specifies a regular expression that the TextField component's value is checked against on change.
   */
  pattern?: RegExp;
  /**
   * Works with translation id
   */
  label?: MuiTextFieldProps['label'];
  /**
   * Works with translation id
   */
  helperText?: MuiTextFieldProps['helperText'];
};

export const WuiTextField: FC<WuiTextFieldProps> = ({
  required,
  disabled,
  sx,
  fullWidth,
  pattern,
  loading,
  helperText,
  label,
  onChange,
  ...textFieldProps
}) => {
  const intl = useIntl();
  const id = textFieldProps.id ?? textFieldProps.name;
  const commonProps = { disabled, required };
  const translatedHelperText = isString(helperText)
    ? intl.formatMessage({
        id: helperText,
        defaultMessage: helperText,
      })
    : helperText;
  const translatedLabel = isString(label) ? intl.formatMessage({ id: label, defaultMessage: label }) : label;

  const handleOnChange: MuiTextFieldProps['onChange'] = useCallback(
    (e) => {
      if (
        !onChange ||
        (pattern &&
          !e.nativeEvent.inputType.startsWith('delete') && // allow content deletion
          !pattern.test(e.target.value))
      ) {
        return;
      }

      onChange(e);
    },
    [onChange, pattern],
  );

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      {label && (
        <WuiFormLabel {...commonProps} htmlFor={id}>
          {translatedLabel}
        </WuiFormLabel>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={inputSizes[textFieldProps.size === 'small' ? 'small' : 'normal'].height}
        />
      ) : (
        <MuiTextField
          {...commonProps}
          {...textFieldProps}
          onChange={handleOnChange}
          fullWidth={fullWidth}
          id={id}
          helperText={translatedHelperText}
        />
      )}
    </FormControl>
  );
};
