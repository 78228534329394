import { Prop, Schema } from '@nestjs/mongoose';
import { Equals, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoTask extends StepBase {
  type: StepTypes.INFO_TASK;

  @Prop()
  @Equals('task')
  id: 'task';

  @Prop()
  @IsString()
  taskTitle: string;

  @Prop()
  @IsString()
  description: string;

  @Prop()
  @IsString()
  priority: string;
}
