import { Prop, Schema } from '@nestjs/mongoose';
import { IsOptional, IsString } from 'class-validator';
import { InputListBaseOption } from './InputListBaseOption';

@Schema({ _id: false })
export class InputListOption extends InputListBaseOption {
  @Prop()
  @IsString()
  @IsOptional()
  regex?: string;

  @Prop()
  @IsString()
  @IsOptional()
  icon?: string;

  @Prop()
  @IsString()
  @IsOptional()
  title?: string;
}
