// eslint-disable-next-line max-classes-per-file
import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { StepTypes } from '../../../domain/workinstruction/step';
import { InputValueListOption } from './InputValueList';
import { InputListBaseStep, ListOptions } from './base';

@Schema({ _id: false })
export class InputButtonListOption extends InputValueListOption {}

@Schema()
export class InputButtonList extends InputListBaseStep {
  type: StepTypes.INPUT_BUTTON_LIST;

  @Prop()
  @IsString()
  description: string;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<InputButtonListOption>;

  @Prop()
  @IsBoolean()
  autoContinue: boolean;

  @Prop()
  @IsString()
  @IsOptional()
  imageMediaId?: string;

  @Prop()
  @IsBoolean()
  @IsOptional()
  imageMediaIdIsVar?: boolean;
}
