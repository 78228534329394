import { ListOptionsSource } from '../ListOptionsSource.enum';
import { StepTypes } from '../StepTypes.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import { InputListBaseStep, StepCore } from '../../../../MongoDB/Workinstruction/Steps/base';
import { InputList, InputListFromDatabase, InputListWithOptions } from '../steps/Base/BaseInputList';

export const LISTABLE_STEPS = [
  StepTypes.INPUT_VALUE_LIST,
  StepTypes.INPUT_CHECKBOX_LIST,
  StepTypes.INPUT_INDEX_LIST,
  StepTypes.INPUT_TILE_LIST,
  StepTypes.INPUT_STEP_MENU,
  StepTypes.INPUT_BUTTON_LIST,
];

export const isListable = (step: StepCore): step is InputList => LISTABLE_STEPS.includes(step.type);

export const isListOptionsFromOptions = (step: Pick<InputListBaseStep, 'source'>): step is InputListWithOptions =>
  step.source === ListOptionsSource.OPTIONS;

export const isListOptionsFromDatabase = (step: Pick<InputListBaseStep, 'source'>): step is InputListFromDatabase =>
  step.source === ListOptionsSource.DATABASE;
