import { ProjectRestoreIds } from '@workerbase/types/project/ProjectRestoreIds';
import { Property } from '@workerbase/types/ListConfig';
import { ProjectPOST, ProjectPUT } from '@workerbase/types/api/projects';

import { Project } from 'services/types/Project';
import { PaginationMeta } from '@workerbase/types/Response';

import { BaseAction } from '../common/actions';
import { updateFilteringFactory, updateSortingFactory, updatePaginationFactory } from '../common/ListConfig/actions';

export enum ProjectsActions {
  GET_PROJECTS_REQUEST = '@@projects/GET_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS = '@@projects/GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE = '@@projects/GET_PROJECTS_FAILURE',
  GET_PROJECT_BY_ID_REQUEST = '@@projects/GET_PROJECT_BY_ID_REQUEST',
  GET_PROJECT_BY_ID_SUCCESS = '@@projects/GET_PROJECT_BY_ID_SUCCESS',
  GET_PROJECT_BY_ID_FAILURE = '@@projects/GET_PROJECT_BY_ID_FAILURE',
  DELETE_PROJECT_BY_ID_REQUEST = '@@projects/DELETE_PROJECT_BY_ID_REQUEST',
  DELETE_PROJECT_BY_ID_FAILURE = '@@projects/DELETE_PROJECT_BY_ID_FAILURE',
  CREATE_PROJECT_REQUEST = '@@projects/CREATE_PROJECT_REQUEST',
  UPDATE_PROJECT_BY_ID_REQUEST = '@@projects/UPDATE_PROJECT_BY_ID_REQUEST',
  UPDATE_PAGINATION = '@@projects/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@projects/UPDATE_SORTING',
  UPDATE_FILTERING = '@@projects/UPDATE_FILTERING',
  IMPORT_PROJECT = '@@projects/IMPORT_PROJECT',
  IMPORT_PROJECT_SUCCESS = '@@projects/IMPORT_PROJECT_SUCCESS',
  IMPORT_PROJECT_FAILURE = '@@projects/IMPORT_PROJECT_FAILURE',
  RESTORE_PROJECT = '@@projects/RESTORE_PROJECT',
  UPDATE_LISTCONFIG_PROPERTIES = '@@projects/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_TASK_FIELDS = '@@projects/UPDATE_TASK_FIELDS',
  UPDATE_LIST_CONFIG = '@@projects/UPDATE_LIST_CONFIG',
}

export const getProjectsRequestAction = (): BaseAction => ({
  type: ProjectsActions.GET_PROJECTS_REQUEST,
  payload: {},
});

export const getProjectsSuccess = (projects: Project[], meta: PaginationMeta): BaseAction => ({
  type: ProjectsActions.GET_PROJECTS_SUCCESS,
  payload: {
    projects,
    meta,
  },
});

export const getProjectsError = (errorMessage: string): BaseAction => ({
  type: ProjectsActions.GET_PROJECTS_FAILURE,
  payload: {
    errorMessage,
  },
});

// CREATE_PROJECT
export interface CreateOrUpdateProjectRequestAction {
  type: string;
  payload: {
    project: ProjectPOST;
  };
}
export const createProjectRequest = (project: ProjectPOST): CreateOrUpdateProjectRequestAction => ({
  type: ProjectsActions.CREATE_PROJECT_REQUEST,
  payload: { project },
});

// GET_PROJECT_BY_ID
export const getProjectByIdRequestAction = (projectId: string): BaseAction => ({
  type: ProjectsActions.GET_PROJECT_BY_ID_REQUEST,
  payload: { projectId },
});

export const getProjectByIdSuccess = (data: Project): BaseAction => ({
  type: ProjectsActions.GET_PROJECT_BY_ID_SUCCESS,
  payload: {
    project: data,
  },
});

export const getProjectByIdError = (errorMessage: string): BaseAction => ({
  type: ProjectsActions.GET_PROJECT_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_PROJECT_BY_ID
export interface UpdateProjectByIdRequestAction {
  type: string;
  payload: {
    project: ProjectPUT;
    projectId: string;
  };
}
export const updateProjectByIdRequest = (projectId: string, project: ProjectPUT): UpdateProjectByIdRequestAction => ({
  type: ProjectsActions.UPDATE_PROJECT_BY_ID_REQUEST,
  payload: { project, projectId },
});

// DELETE_PROJECT_BY_ID
export interface DeleteProjectByIdRequestAction {
  type: string;
  payload: {
    projectId: string;
  };
}
export const deleteProjectByIdRequest = (projectId: string): DeleteProjectByIdRequestAction => ({
  type: ProjectsActions.DELETE_PROJECT_BY_ID_REQUEST,
  payload: { projectId },
});

export const deleteProjectByIdError = (errorMessage: string): BaseAction => ({
  type: ProjectsActions.DELETE_PROJECT_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export const importProject = (file: File): BaseAction => ({
  type: ProjectsActions.IMPORT_PROJECT,
  payload: { file },
});

export const importProjectSuccess = (): BaseAction => ({
  type: ProjectsActions.IMPORT_PROJECT_SUCCESS,
});

export const importProjectError = (errorMessage: string): BaseAction => ({
  type: ProjectsActions.IMPORT_PROJECT_FAILURE,
  payload: {
    errorMessage,
  },
});

export const getRestoreProjectAction = (
  projectId: string,
  projectRestoreIds: ProjectRestoreIds,
  userId?: string,
  file?: File,
): BaseAction => ({
  type: ProjectsActions.RESTORE_PROJECT,
  payload: { projectId, projectRestoreIds, userId, file },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(ProjectsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(ProjectsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(ProjectsActions.UPDATE_FILTERING);

export const updateListConfigProperties = (newProperties: Property[]) => ({
  type: ProjectsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});

export const updateProjectTaskFields = (projectId: string, newFields: string[]) => ({
  type: ProjectsActions.UPDATE_TASK_FIELDS,
  payload: { projectId, fields: newFields },
});
