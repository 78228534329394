import { Prop, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { StepButton, StepButtonSchema } from '../../StepButton';
import { StepCore } from './StepCore';
import { StepTimeout } from '../../StepTimeout';
import { StepStyles } from '../../StepStyles';

export class StepBase extends StepCore {
  @Prop()
  @IsString()
  title: string;

  @Prop({ default: false })
  @IsBoolean()
  @IsOptional()
  hideTitleBar?: boolean;

  @Prop([{ type: StepButtonSchema }])
  @ValidateNested({ each: true })
  @Type(() => StepButton)
  buttons: StepButton[];

  @Prop()
  @IsNumber()
  @IsOptional()
  maxTime?: number;

  @Prop({ type: SchemaFactory.createForClass(StepTimeout) })
  @ValidateNested()
  @IsOptional()
  @Type(() => StepTimeout)
  timeout?: StepTimeout;

  @Prop()
  @IsString()
  @IsOptional()
  loadVariablesFromUrl?: string;

  @Prop({ default: false })
  @IsOptional()
  @IsBoolean()
  showAsBox?: boolean;

  @Prop({ type: SchemaFactory.createForClass(StepStyles) })
  styles?: StepStyles;
}
