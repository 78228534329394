import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import mongoose from 'mongoose';
import { DatabaseViewMapping } from '../../../DatabaseViewMapping';
import { ModelNames } from '../../../ModelNames';
import { ClassValidatorOptions } from '../../../utils/ClassValidatorOptions';
import { ListOptionsSource } from '../../../../domain/workinstruction/step/ListOptionsSource.enum';
import { InputableStep } from './InputableStep';

@Schema()
export class InputListBaseStep extends InputableStep {
  @Prop()
  @IsNumber()
  minSelect: number;

  @Prop({
    type: String,
    enum: Object.values(ListOptionsSource),
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop()
  @IsBoolean()
  loadFromUrl: boolean;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  loadOptionsFromUrl?: string;

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.DatabaseViewMapping,
  })
  @IsString()
  @IsOptional() // used only if source === ListOptionsSource.DATABASE
  mapping?: string | DatabaseViewMapping;
}
