import { LoggedUser } from 'services/types/User';

export enum AccessRole {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
}

export const hasUserAccessRole = (user: LoggedUser, accessRole: AccessRole): boolean => {
  switch (accessRole) {
    case AccessRole.ADMIN:
      return user.isRootAdministrator;
    case AccessRole.DEVELOPER:
      return user.isDeveloper;
    default:
      return false;
  }
};
