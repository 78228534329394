/* eslint-disable react/jsx-props-no-spreading --- needed in this file */
import React, { FC, ReactNode } from 'react';
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormControl,
  MenuItem as MuiMenuItem,
  FormHelperText,
  Skeleton,
} from '@mui/material';
import { isString, isUndefined } from 'lodash';
import { useIntl } from 'react-intl';
import { WuiFormLabel } from '../WuiFormLabel';
import { WuiSelectPlaceholder } from './styled';
import { dropdown, inputSizes } from '../../stylesheet';

export const WuiSelectItem = MuiMenuItem;

export interface WuiSelectOption<V = string> {
  value: V;
  label: string | JSX.Element;
}

type WuiSelectBaseProps = Omit<MuiSelectProps, 'displayEmpty'> & {
  name: string;
  helperText?: string; // can be translation id
  /**
   * If `true`, the label and animated skeleton will be shown.
   * Also, loading state will be automatically shown until there are
   * options passed either through the prop or as children
   * @default false
   */
  loading?: boolean;
};

type PassOptionsAsChildren = {
  children: ReactNode;
  options?: never;
};

type PassOptionsAsProp = {
  children?: never;
  options: WuiSelectOption[];
};

export type WuiSelectProps = WuiSelectBaseProps & (PassOptionsAsChildren | PassOptionsAsProp);

export const WuiSelect: FC<WuiSelectProps> = ({
  label,
  required,
  disabled,
  options,
  children,
  helperText,
  placeholder,
  renderValue,
  fullWidth,
  loading,
  sx,
  ...selectProps
}) => {
  const intl = useIntl();
  const id = selectProps.id ?? selectProps.name;
  const commonProps = { disabled, required };
  const translatedHelperText = isString(helperText)
    ? intl.formatMessage({
        id: helperText,
        defaultMessage: helperText,
      })
    : helperText;

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      {label && (
        <WuiFormLabel {...commonProps} focused={false} htmlFor={id}>
          {label}
        </WuiFormLabel>
      )}
      {loading || (!options && !children) ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={inputSizes[selectProps.size === 'small' ? 'small' : 'normal'].height}
        />
      ) : (
        <>
          <MuiSelect
            MenuProps={{
              MenuListProps: {
                sx: {
                  maxHeight: `${dropdown.maxHeight + dropdown.paddingY}px`,
                  py: `${dropdown.paddingY}px`,
                },
              },
            }}
            {...commonProps}
            {...selectProps}
            id={id}
            fullWidth={fullWidth}
            {...(placeholder
              ? {
                  displayEmpty: true,
                  renderValue: (selected) => {
                    if (isUndefined(selected)) {
                      return <WuiSelectPlaceholder>{placeholder}</WuiSelectPlaceholder>;
                    }
                    return (renderValue ? renderValue(selected) : selected) as ReactNode;
                  },
                }
              : { renderValue })}
          >
            {options
              ? options.map((o) => (
                  <WuiSelectItem key={o.value} value={o.value}>
                    {o.label}
                  </WuiSelectItem>
                ))
              : children}
          </MuiSelect>
          {translatedHelperText && <FormHelperText error={selectProps.error}>{translatedHelperText}</FormHelperText>}
        </>
      )}
    </FormControl>
  );
};
