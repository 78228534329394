export enum DatabaseColumnStatuses {
  Active = 'Active',
  Changeover = 'Changeover',
  Error = 'Error',
  Idle = 'Idle',
  MaintenancePlanned = 'Maintenance - Planned',
  MaintenanceUnplanned = 'Maintenance - Unplanned',
  Running = 'Running',
  Shutdown = 'Shutdown',
  Standby = 'Standby',
  StartUp = 'Start-up',
  Testing = 'Testing',
}
