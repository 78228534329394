import React, { FC, useCallback } from 'react';
import { get } from 'lodash';
import { FieldProps, Field } from 'formik';
import type { FormikFieldProps } from '@uiKit/types/FormikField';
import { WuiMentionTextFieldProps, WuiMentionTextField } from './WuiMentionTextField';

type WuiFormikMentionTextFieldProps = FormikFieldProps<WuiMentionTextFieldProps>;

export const WuiFormikMentionTextField: FC<WuiFormikMentionTextFieldProps> = (props) => {
  const component = useCallback(
    ({ field, form, meta, onChange, onBlur, ...componentProps }: WuiFormikMentionTextFieldProps & FieldProps) => {
      const isTouched = get(form.touched, field.name);
      const errorText = get(form.errors, field.name) as string;
      const showError = !!(isTouched && errorText);

      const handleChange: WuiMentionTextFieldProps['onChange'] = (e) => {
        form.setFieldTouched(props.name, true);
        field.onChange(e);
        onChange?.(e);
      };

      const handleBlur: WuiMentionTextFieldProps['onBlur'] = (e) => {
        field.onChange(e);
        onBlur?.(e);
      };

      return (
        <WuiMentionTextField
          {...componentProps}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={showError}
          helperText={showError ? errorText : componentProps.helperText}
        />
      );
    },
    [],
  );

  return <Field component={component} {...props} />;
};
