import React, { createContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginSSORequestFailure, loginSSORequestSuccess, logoutUser } from '@redux/Login';
import { SSO_REDIRECT_LANDING_PAGE } from '@workerbase/types/auth/constants';
import {
  getRefreshTokenFromLocalStorage,
  isRefreshTokenKey,
  setDelayedLogoutOnSessionExp,
} from 'services/auth/auth.service';
import { mapSSOQueryParamsToUser } from './util';

export const AuthProviderContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const isSuccessful = queryParams.get('success') === 'true';

  const setupSessionEndLogout = useCallback(
    (refreshToken: string | null) => {
      // If the refresh token is removed, then the user has logged out from another tab
      if (!refreshToken) {
        dispatch(logoutUser());

        return;
      }

      // If the refresh token is updated, then the user has extended a session from another tab
      setDelayedLogoutOnSessionExp(refreshToken);
    },
    [dispatch],
  );

  // initial setup, check if the user is logged in and setup the session expire logout
  useEffect(() => {
    const refreshToken = getRefreshTokenFromLocalStorage();
    if (refreshToken) {
      setupSessionEndLogout(refreshToken);
    }
  }, [setupSessionEndLogout]);

  // listen for changes to the refresh token
  useEffect(() => {
    function handleLocalStorageChange(e: StorageEvent) {
      if (e.key && isRefreshTokenKey(e.key)) {
        setupSessionEndLogout(e.newValue);
      }
    }

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, [setupSessionEndLogout]);

  if (window.location.pathname === SSO_REDIRECT_LANDING_PAGE) {
    if (isSuccessful) {
      const loginData = mapSSOQueryParamsToUser(queryParams);
      dispatch(loginSSORequestSuccess(loginData));
    } else {
      const message = queryParams.get('message') ?? 'Unknown error';
      dispatch(loginSSORequestFailure(message));
    }
  }

  return <AuthProviderContext.Provider value={null}>{children}</AuthProviderContext.Provider>;
};
