import React, { FC, useCallback, useState } from 'react';
import { NoteBody, NoteResponse } from '@workerbase/types/api/notes';
import { useIntl } from 'react-intl';
import { Formik, Form, FormikProps } from 'formik';
import { Button, Stack, Typography } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { WuiFileDropzone, WuiFormikTextField } from '@uiKit';
import { useApiRequest } from 'hooks/useRequest';
import { deleteMedia, uploadMedia } from 'services/networking/media';
import { FileUploadDisplay, FileUploadStatus } from 'components/UploadFiles';

export interface NoteFormProps {
  note?: NoteResponse;
  onCancel: () => void;
  onSave: (note: Pick<NoteBody, 'text' | 'media'>) => void;
}

export type NoteFormBody = Pick<NoteResponse, 'text' | 'media'>;

export const NoteForm: FC<NoteFormProps> = ({ note, onCancel, onSave }) => {
  const [validNote, setValidNote] = useState(true);
  const [fileStatus, setFileStatus] = useState<FileUploadStatus>(FileUploadStatus.Success);
  const intl = useIntl();

  const uploadFileRequest = useApiRequest(uploadMedia, {
    manual: true,
  });

  const deleteFileRequest = useApiRequest(deleteMedia, {
    manual: true,
  });

  const initialValues = useCallback(
    (note) => ({
      text: note?.text ?? '',
      media: note?.media ?? null,
    }),
    [],
  );

  const validateNote = useCallback((note: NoteFormBody) => Boolean(note.media || note.text.trim()), []);

  const handleOnDrop = (formik: FormikProps<NoteFormBody>) => async (files: File[]) => {
    const [file] = files;
    formik.setFieldValue('media', { _id: '', mimeType: file.type, fileName: file.name, size: file.size });
    setFileStatus(FileUploadStatus.Loading);
    const fileId = await uploadFileRequest.runAsync(file);
    formik.setFieldValue('media', { _id: fileId, mimeType: file.type, fileName: file.name, size: file.size });
    setFileStatus(FileUploadStatus.Success);
  };

  const handleDeleteMedia = async (formik: FormikProps<NoteFormBody>) => {
    if (formik.values.media?._id && formik.values.media._id !== note?.media?._id) {
      setFileStatus(FileUploadStatus.Loading);
      await deleteFileRequest.runAsync(formik.values.media._id);
      setFileStatus(FileUploadStatus.Success);
    }
    formik.setFieldValue('media', null);
  };

  const handleCancel = (formik: FormikProps<NoteFormBody>) => async () => {
    await handleDeleteMedia(formik);
    onCancel();
  };

  const handleFileClick = (formik: FormikProps<NoteFormBody>) => async () => {
    switch (fileStatus) {
      case FileUploadStatus.Success:
        await handleDeleteMedia(formik);
        break;
      case FileUploadStatus.Loading:
      case FileUploadStatus.Error:
      default:
        formik.setFieldValue('media', null);
        break;
    }
  };

  const onAddNote = (note: NoteFormBody) => {
    const noteIsValid = validateNote(note);
    setValidNote(noteIsValid);
    if (noteIsValid) {
      onSave({
        text: note.text,
        media: note.media?._id,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues(note)}
      onSubmit={(values) => {
        onAddNote(values);
      }}
    >
      {(formik) => (
        <Stack component={Form} id="note-form" noValidate>
          {formik.values.media ? (
            <FileUploadDisplay
              status={fileStatus}
              onActionClick={handleFileClick(formik as FormikProps<NoteFormBody>)}
              file={{
                type: formik.values.media.mimeType,
                name: formik.values.media.fileName,
                size: formik.values.media.size,
              }}
            />
          ) : (
            <WuiFileDropzone
              accept={['.jpg', '.jpeg', '.png', '.mp3', '.aac', '.mp4', '.pdf']}
              onDrop={handleOnDrop(formik as FormikProps<NoteFormBody>)}
            />
          )}
          <WuiFormikTextField name="text" label="note.label" fullWidth multiline />
          {!validNote && (
            <Typography variant="body2" color={(theme) => theme.palette.error.main}>
              {intl.formatMessage({ id: 'note.form.error-empty' })}
            </Typography>
          )}
          <Stack direction="row" justifyContent="right" spacing="8px" sx={{ marginTop: '12px' }}>
            <Button
              onClick={handleCancel(formik as FormikProps<NoteFormBody>)}
              startIcon={<CloseIcon />}
              disabled={fileStatus === FileUploadStatus.Loading}
            >
              {intl.formatMessage({ id: 'global.cancel' })}
            </Button>
            <Button
              variant="contained"
              startIcon={<CheckIcon />}
              type="submit"
              form="note-form"
              disabled={fileStatus === FileUploadStatus.Loading}
            >
              {intl.formatMessage({ id: note ? 'note.edit' : 'note.add' })}
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};
