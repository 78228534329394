import { RequestMethod } from '@workerbase/types/RequestMethod.enum';
import { RequestPayloadFormat } from '@workerbase/types/RequestPayloadFormat.enum';

export { RequestMethod, RequestPayloadFormat };

export enum RequestHeaderType {
  PLAIN = 'plain',
}
export interface RequestHeader {
  type: RequestHeaderType;
  name: string;
  value: string;
}

export enum RequestPayloadType {
  PLAIN = 'plain',
  VARIABLE = 'variable',
}

export interface RequestPayload {
  mapFrom: string;
  mapTo: string;
  type: RequestPayloadType;
}

export interface Payload {
  useCustomPayload: boolean;
  format: RequestPayloadFormat;
  reqPayloads: RequestPayload[];
  rootName?: string; // If format is XML, user can set a rootname
}

export interface PayloadPOST {
  useCustomPayload: boolean;
  reqPayloadFormat?: RequestPayloadFormat;
  reqPayloadRootName?: string;
  reqPayload?: RequestPayload[];
}

export interface Request {
  url: string;
  reqMethod: RequestMethod;
  reqHeaders: RequestHeader[];
  payload: Payload;
}
