import { RootState } from '../types';

export const getTasksOnCurrentPage = (store: RootState) =>
  store.tasks.currentListItemsIds.map((id) => store.tasks.tasksById[id]);

export const getListConfigs = (store: RootState) => store.tasks.listConfigs;

export const getProjectSubscribed = (store: RootState) => store.tasks.projectSubscribed;

export const getIsLoading = (store: RootState): boolean => store.tasks.isLoading;

export const getLoadedTaskIds = (store: RootState) => Object.keys(store.tasks.tasksById);
