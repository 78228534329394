/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable import/no-import-module-exports */
import { loader } from '@monaco-editor/react';
import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import App from './App';
import configureStore, { history } from './redux/configureStore';
import { ToastUtils } from './utils/ToastUtils';
import { SentryUtils } from './utils/SentryUtils';

LicenseInfo.setLicenseKey(
  '716bb493d35ee3176705426a03ec250cTz04NDQzMSxFPTE3Mzk2OTQwNzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

if (SENTRY_DSN) {
  // eslint-disable-next-line
  console.log('Sentry Error Reporting is enabled');
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `frontend@${VERSION}`,
    environment: window.location.hostname,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [Sentry.replayIntegration({ maskAllText: false })],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event) {
      if (!SentryUtils.shouldIgnoreEvent(event)) {
        ToastUtils.showToastWithErrorReportingId(SentryUtils.getMessageFromEvent(event), event.event_id);
      }
      return event;
    },
  });
}

if (module?.hot?.accept) {
  module.hot.accept();
}

loader.config({ paths: { vs: '/monaco-editor/vs' } });
export const store = configureStore();

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'));
