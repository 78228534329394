import { SuggestionDataItem } from 'react-mentions';
import { RegexUtils } from '@workerbase/utils/RegexUtils';

interface ConvertValueToMentionsFormatArgs {
  value: string | undefined;
  trigger: string;
  mentions: string[];
}

export const convertValueToMentionsFormat = ({
  value,
  trigger,
  mentions,
}: ConvertValueToMentionsFormatArgs): string => {
  if (!value) {
    return '';
  }

  const regexPattern = `(${RegexUtils.escapeSpecialCharacters(trigger)}(\\w+\\.?)+)`;

  return value.replace(new RegExp(regexPattern, 'g'), (match, p1) => {
    const value = p1.replace(trigger, '');
    // we need to check if matched value exist also in mentions array
    // because there can be strings that follow pattern but is not a mention but just regular string
    if (mentions.includes(value)) {
      return `@[${trigger}${value}](${value})`;
    }
    return p1;
  });
};

export const convertMentionsToSuggestionDataItem = (mentions: string[], trigger: string): SuggestionDataItem[] =>
  mentions.map((mention) => ({
    display: `${trigger}${mention}`,
    id: mention,
  }));
