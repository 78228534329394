// eslint-disable-next-line max-classes-per-file
export class RegexUtils {
  public static SPECIAL_CHARACTERS = ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$'];

  public static EXPRESSIONS = class {
    public static IPV4 = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;

    public static ENGLISH_LETTERS_AND_NUMBERS = '^[a-zA-Z0-9]*$';

    public static TEMPLATE_STRING_VARIABLE = '%(\\w*)%'; // Example: Some string with %variable% to be replaced

    public static VARIABLE = '^\\$[a-zA-Z0-9-_]*$'; // starts from '$' and contains only one phrase of english letters, numbers, hyphen and undescore

    public static VARIABLE_OR_DECIMAL = '\\$[a-zA-Z0-9-_]+|\\d+[.]\\d+|[0-9]+';

    public static VARIABLE_NAME = '^[a-zA-Z0-9-_]*$';

    public static OBJECT_ID = '[a-f\\d]{24}';

    public static MQTT_URL = '^(wss|ws|mqtt|mqtts|tcp|ssl|wx|wxs):\\/\\/(\\S+):([0-9]+)$';

    public static FUNCTION_URL = '^api/v1/functions/([0-9a-fA-F]{24})/invoke$';

    /** Matches min till max digits */
    public static LOGIN_PIN = (min = 4, max = 4) => new RegExp(`^([0-9]){${min},${max}}$`);
  };

  public static removeStrings(text: string, strings: string[]): string {
    let result = text;
    for (const toReplaceText of strings) {
      result = result.replace(new RegExp(toReplaceText, 'g'), '');
    }
    return result;
  }

  public static isValidRegex(text: string): boolean {
    try {
      RegExp(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  public static escapeSpecialCharacters(text: string): string | null {
    if (text) {
      const regex = new RegExp(`(\\${this.SPECIAL_CHARACTERS.join('|\\')})`, 'g');
      return text.replace(regex, '\\$1');
    }
    return null;
  }

  public static endsWith(text: string, value: string): boolean {
    return new RegExp(`${value}$`).test(text);
  }

  public static contains(text: string, value: string): boolean {
    return new RegExp(value).test(text);
  }

  public static getStringBetweenStrings(text: string, string1: string, string2: string): string | null {
    const matches: RegExpExecArray | null = new RegExp(`${string1}(.*)${string2}`).exec(text);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  public static getStringsBetweenStrings(text: string, string1: string, string2: string): string[] {
    const regExp = new RegExp(`(?<=${string1}).*?(?=${string2}).{6}`, 'g');
    const result = text.match(regExp);

    if (!result) {
      return [];
    }

    return result.filter((item) => item.includes('$')).map((item) => item.split('"')[0]);
  }
}
