import { PaginationMeta } from '@workerbase/types/Response';
import { FunctionLog, FunctionLogMQTT } from '@workerbase/types/api/functions';

import { BaseAction } from '../common/actions';

import { updateFilteringFactory, updateSortingFactory, updatePaginationFactory } from '../common/ListConfig/actions';

export enum FunctionLogsActions {
  GET_FUNCTIONLOGS_REQUEST = '@@functionLogs/GET_FUNCTIONLOGS_REQUEST',
  GET_FUNCTIONLOGS_SUCCESS = '@@functionLogs/GET_FUNCTIONLOGS_SUCCESS',
  GET_FUNCTIONLOGS_FAILURE = '@@functionLogs/GET_FUNCTIONLOGS_FAILURE',
  UPDATE_PAGINATION = '@@functionLogs/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@functionLogs/UPDATE_SORTING',
  UPDATE_LIST_CONFIG = '@@functionLogs/UPDATE_LIST_CONFIG',
  UPDATE_FILTERING = '@@functionLogs/UPDATE_FILTERING',
  MQTT_SUBSCRIBE_FUNCTION_CALLS = '@@functionLogs/MQTT_SUBSCRIBE_FUNCTION_CALLS',
  MQTT_UNSUBSCRIBE_FUNCTION_CALLS = '@@functionLogs/MQTT_UNSUBSCRIBE_FUNCTION_CALLS',
  HANDLE_INCOMING_MQTT_FUNCTION_CALLS = '@@functionLogs/HANDLE_INCOMING_MQTT_FUNCTION_CALLS',
}

export const getFunctionLogsRequestAction = (functionId: string, forceLoading = false): BaseAction => ({
  type: FunctionLogsActions.GET_FUNCTIONLOGS_REQUEST,
  payload: { functionId, forceLoading },
});

export const getFunctionLogsSuccess = (data: FunctionLog[], meta: PaginationMeta): BaseAction => ({
  type: FunctionLogsActions.GET_FUNCTIONLOGS_SUCCESS,
  payload: {
    functionLogs: data,
    meta,
  },
});

export const getFunctionLogsError = (errorMessage: string): BaseAction => ({
  type: FunctionLogsActions.GET_FUNCTIONLOGS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const updatePagination = updatePaginationFactory(FunctionLogsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(FunctionLogsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(FunctionLogsActions.UPDATE_FILTERING);

export interface FunctionCallsSubscribeAction {
  type: FunctionLogsActions.MQTT_SUBSCRIBE_FUNCTION_CALLS | FunctionLogsActions.MQTT_UNSUBSCRIBE_FUNCTION_CALLS;
  payload: { functionId: string };
}
export const subscribeToFunctionCalls = (functionId: string) => ({
  type: FunctionLogsActions.MQTT_SUBSCRIBE_FUNCTION_CALLS,
  payload: { functionId },
});

export const unsubscribeToFunctionCalls = (functionId: string) => ({
  type: FunctionLogsActions.MQTT_UNSUBSCRIBE_FUNCTION_CALLS,
  payload: { functionId },
});

export interface IncomingMqttFunctionCallAction {
  type: string;
  payload: {
    topic: string;
    message: FunctionLogMQTT;
    regexpArray: string[];
  };
}
export const handleIncomingMqttFunctionCall = (topic, message, regexpArray): IncomingMqttFunctionCallAction => ({
  type: FunctionLogsActions.HANDLE_INCOMING_MQTT_FUNCTION_CALLS,
  payload: {
    topic,
    message,
    regexpArray,
  },
});
