import { Languages } from '@workerbase/types/Settings';
import { UserUsageType } from '@workerbase/types/users/UserUsageType';
import { SkillGET } from '@workerbase/types/api/skills';
import { RoleGET } from '@workerbase/types/api/roles';
import { NfcCredentialsResponse } from '@workerbase/types/api/users';

export enum UsersCategories {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface LoggedUser {
  id: string;
  firstName: string;
  lastName: string;
  language: Languages;
  email: string;
  isOnPremise: boolean;
  isRootAdministrator: boolean;
  isDeveloper: boolean;
  disableDeviceLock: boolean;
  isWorkbenchEnabled: boolean;
  isMyWorkEnabled: boolean;
  isAiEnabled?: boolean;
  isGDriveEnabled?: boolean;
  roleIds: string[];
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  active: boolean;
  language: Languages;
  department: string;
  email: string;
  phoneNumber: string;
  roles?: RoleGET[]; // will be undefined in getUser response
  rolesIds: string[];
  skills?: SkillGET[]; // will be undefined in getUser response
  skillsIds: string[];
  shiftPlanId: string;
  disableDeviceLock: boolean;
  isRootAdministrator: boolean;
  isDeviceOnlyUser: boolean;
  isDeveloper: boolean;
  locationName?: string;
  pinIdentifier?: string;
  profilePicture?: string;
  usageType: UserUsageType;
  nfcCredentials: Partial<NfcCredentialsResponse>;
}
