import { StepTypes } from '../StepTypes.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import { Step } from '../../../../MongoDB/Workinstruction/Step';
import {
  LayoutAssemblyDetail,
  LayoutScrewFitting,
  LayoutAssemblyVideo,
  LayoutAssemblyPicture,
} from '../../../../MongoDB/Workinstruction/Steps';

const LAYOUT_TEMPLATE_STEPS = [
  StepTypes.LAYOUT_ASSEMBLY_PICTURE,
  StepTypes.LAYOUT_ASSEMBLY_VIDEO,
  StepTypes.LAYOUT_ASSEMBLY_DETAIL,
  StepTypes.LAYOUT_SCREW_FITTING,
] as const; // as const is required for creating union type down below

// gives a union type from array
export type LayoutTemplateStepTypes = (typeof LAYOUT_TEMPLATE_STEPS)[number];

export type LayoutTemplateSteps =
  | LayoutAssemblyPicture
  | LayoutAssemblyVideo
  | LayoutAssemblyDetail
  | LayoutScrewFitting;

export const isLayoutTemplateStep = (step: Step): step is LayoutTemplateSteps =>
  (LAYOUT_TEMPLATE_STEPS as any as StepTypes[]).includes(step.type);
