import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getLanguages, updateLanguages } from 'services/networking/settings';
import { getLanguagesSuccessAction, SettingsActions, updateLanguagesSuccessAction } from './actions';
import { handleRequestError } from '../common';

export function* getLanguagesSaga(action) {
  try {
    const languages = yield call(getLanguages);
    yield put(getLanguagesSuccessAction(languages));
  } catch (error) {
    yield call(toast.error, 'Failed to load languages');
  }
}

export function* updateLanguagesSaga(action) {
  try {
    const languages = yield call(updateLanguages, action.payload.languages);
    yield put(updateLanguagesSuccessAction(languages));
    yield call(toast.success, 'Languages updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* rulesSagas() {
  yield takeLatest(SettingsActions.GET_LANGUAGES, getLanguagesSaga);
  yield takeLatest(SettingsActions.UPDATE_LANGUAGES, updateLanguagesSaga);
}
