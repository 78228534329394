/* eslint-disable react/button-has-type */

import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Button.module.scss';
import { ButtonVariant, isButtonVariant } from './util';

interface Props {
  title?: string;
  type: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  variant?: ButtonVariant;
  icon?: ReactNode;
  onClick?;
  fullWidth?: boolean;
  center?: boolean;
  loading?: boolean;
  shouldPutIconToTheRight?: boolean;
  className?: string;
  form?: ButtonHTMLAttributes<HTMLButtonElement>['form'];
}

export const Button: React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLButtonElement>> = React.forwardRef(
  (
    {
      title,
      type,
      disabled,
      form,
      variant = 'primary',
      icon,
      onClick,
      center,
      fullWidth = false,
      loading,
      shouldPutIconToTheRight,
      className,
    }: Props,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    let buttonClassName = styles.button;
    if (disabled) {
      buttonClassName += ` ${styles.disabled}`;
    }

    if (variant && isButtonVariant(variant)) {
      buttonClassName += ` ${styles[variant]}`;
    }

    if (disabled) {
      buttonClassName += ` ${styles.disabled}`;
    }

    buttonClassName = buttonClassName.concat(` ${className}`);

    return (
      // ESLint does not handle well dynamic assignment on button type...
      // https://github.com/yannickcr/eslint-plugin-react/issues/1555

      <button
        // If ref if specified, it is probably because we want to force focus on this button
        // In such case, we don't deactivate the default focus behavior of the browser
        // so user can see the button is in focus
        className={`${buttonClassName} ${fullWidth ? styles.fullWidth : ''} ${center ? styles.center : ''} ${
          !ref ? styles.disableFocus : ''
        }`}
        form={form}
        type={type}
        disabled={disabled}
        onClick={(e) => (onClick ? onClick(e) : '')}
        ref={ref}
      >
        {loading ? <CircularProgress size={20} /> : !shouldPutIconToTheRight && icon}
        {title && <span>{title}</span>}
        {shouldPutIconToTheRight && icon}
      </button>
    );
  },
);

Button.defaultProps = {
  title: undefined,
  disabled: false,
  variant: 'primary',
  icon: undefined,
  onClick: undefined,
  fullWidth: undefined,
  loading: false,
  shouldPutIconToTheRight: false,
  className: '',
};

export default Button;
