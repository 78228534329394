import { Avatar, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { handleRequestError } from '@redux/common/actions';
import React, { useCallback, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles()(() => ({
  small: {
    width: '32px',
    height: '32px',
  },
  large: {
    width: '64px',
    height: '64px',
  },
}));

interface Props {
  userId: string;
  size?: 'small' | 'large';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  getProfilePictureByUserId: (userId: string) => Promise<Blob>;
}

const ProfileIcon: React.FC<Props> = ({ userId, size = 'small', getProfilePictureByUserId, onClick }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [profileImageBlobUrl, setProfileImageBlobUrl] = useState<string>();

  const getProfileImageUrl = useCallback(() => {
    getProfilePictureByUserId(userId)
      .then((blob: Blob) => {
        // The Mui Avatar has a fallback image which is different from default Workerbase img
        // It creates a small glitch when the image loads.
        // So the simplest way I found is to check for this default image size, 7602, and skip next steps if so
        // This is not perfect because some other images with same size can't be loaded.
        if (blob && blob.size !== 7602) {
          const imageBlobUrl = URL.createObjectURL(blob);
          setProfileImageBlobUrl(imageBlobUrl);
        }
      })
      .catch((err) => {
        dispatch(handleRequestError(err));
      });
  }, [getProfilePictureByUserId, userId]);

  React.useEffect(() => {
    getProfileImageUrl();
  }, [getProfileImageUrl]);

  const getAvatar = () => (
    <Avatar alt="Profile" className={size === 'small' ? classes.small : classes.large} src={profileImageBlobUrl} />
  );

  return onClick ? (
    <IconButton
      aria-label="profile icon"
      aria-controls="user-profile-icon"
      aria-haspopup="true"
      color="inherit"
      onClick={onClick}
      size="large"
    >
      {getAvatar()}
    </IconButton>
  ) : (
    getAvatar()
  );
};

export default ProfileIcon;
