import { Prop, Schema } from '@nestjs/mongoose';
import { IsLatitude, IsLongitude, IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoLocationDirection extends StepBase {
  type: StepTypes.INFO_LOCATION_DIRECTION;

  @Prop()
  @IsString()
  description: string;

  @Prop({
    trim: true,
    required: true,
  })
  @IsLatitude()
  gpsLocationLatitude: string;

  @Prop({
    trim: true,
    required: true,
  })
  @IsLongitude()
  gpsLocationLongitude: string;
}
