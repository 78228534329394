/* eslint-disable max-classes-per-file */
import { Prop, Schema } from '@nestjs/mongoose';
import { IsEnum, IsOptional, IsString } from 'class-validator';
import { ImageScaleType } from '../../ImageScaleType';
import { ClassValidatorOptions } from '../utils/ClassValidatorOptions';

@Schema({ _id: false })
export class TextStyles {
  @Prop()
  @IsString()
  @IsOptional()
  color?: string;

  @Prop()
  @IsString()
  @IsOptional()
  backgroundColor?: string;
}

@Schema({ _id: false })
export class ImageStyles {
  @Prop({
    type: String,
    enum: Object.values(ImageScaleType),
    default: ImageScaleType.FIT,
  })
  @IsEnum(ImageScaleType, ClassValidatorOptions.getEnumErrorMessageOptions(ImageScaleType))
  scaleType: ImageScaleType;
}
