import { Prop, Schema } from '@nestjs/mongoose';
import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import { ClassValidatorOptions } from '../../../utils/ClassValidatorOptions';
import { StepTypes, IStepCore } from '../../../../domain/workinstruction/step';

@Schema({ discriminatorKey: 'type', _id: false, overwriteModels: true })
export class StepCore implements IStepCore {
  _id: string;

  @Prop({ required: true })
  @IsString()
  id: string;

  @Prop()
  @IsNumber()
  @IsOptional()
  index?: number;

  @Prop({
    type: String,
    enum: Object.values(StepTypes),
    required: true,
    $skipDiscriminatorCheck: true,
  })
  @IsEnum(StepTypes, ClassValidatorOptions.getEnumErrorMessageOptions(StepTypes))
  type: StepTypes;
}
