import React, { ReactNode } from 'react';
import MuiModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import { SquareButton } from 'components/SquareButton';

import styles from './BaseModal.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  fullSize?: boolean;
  isCloseIconEnabled?: boolean;
}

const BaseModal: React.FC<Props> = ({ children, open, onClose, fullSize = false, isCloseIconEnabled = true }) => (
  <MuiModal aria-labelledby="modal-title" aria-describedby="modal-description" open={open} onClose={onClose}>
    <div className={`${styles.modal} ${fullSize ? styles.modalFullSize : ''}`}>
      {isCloseIconEnabled && (
        <div className={styles.closeIcon}>
          <SquareButton variant="regular" icon={<CloseIcon fontSize="small" />} onClick={onClose} />
        </div>
      )}
      <div className={`${fullSize ? styles.modalBodyFullHeight : ''}`}>{children}</div>
    </div>
  </MuiModal>
);

export default BaseModal;
