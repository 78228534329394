import { ListOptionsSource } from '../ListOptionsSource.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import { DatabaseViewMapping } from '../../../../MongoDB/DatabaseViewMapping';
import {
  InputButtonList as BaseInputButtonList,
  InputButtonListOption,
} from '../../../../MongoDB/Workinstruction/Steps/InputButtonList';

export { InputButtonListOption };

export interface InputButtonListWithOptions
  extends Omit<BaseInputButtonList, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.OPTIONS;
  options: InputButtonListOption[];
  isVar: false;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
  mapping: undefined;
}

export interface InputButtonListFromVariable
  extends Omit<BaseInputButtonList, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.VARIABLE;
  options: string;
  isVar: true;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
  mapping: undefined;
}
export interface InputButtonListFromConnector
  extends Omit<BaseInputButtonList, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  // should be renamed loadOptionsFromConnectorWithId
  source: ListOptionsSource.CONNECTOR;
  loadOptionsFromUrl: string; // The value is the connectorId (which is an outgoing connector)
  loadFromUrl: true;
  isVar: false;
  options: undefined;
  mapping: undefined;
}

export interface InputButtonListFromDatabase
  extends Omit<BaseInputButtonList, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.DATABASE;
  mapping: DatabaseViewMapping;
  options: undefined;
  isVar: false;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
}

export type InputButtonList =
  | InputButtonListWithOptions
  | InputButtonListFromVariable
  | InputButtonListFromConnector
  | InputButtonListFromDatabase;
