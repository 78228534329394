export const MICROSOFT_OAUTH_BASE_URL =
  process.env.LOGIN_METHODS_MICROSOFT_CUSTOM_OAUTH_BASE_URL || 'https://login.microsoftonline.com';
export const MICROSOFT_OAUTH_AUTH_ENDPOINT =
  process.env.LOGIN_METHODS_MICROSOFT_CUSTOM_OAUTH_AUTHORIZATION_ENDPOINT || '/oauth2/v2.0/authorize';
export const MICROSOFT_OAUTH_GENERATE_TOKEN_ENDPOINT =
  process.env.LOGIN_METHODS_MICROSOFT_CUSTOM_OAUTH_TOKEN_ENDPOINT || '/oauth2/v2.0/token';
export const MICROSOFT_OAUTH_CALLBACK = process.env.LOGIN_METHODS_MICROSOFT_CUSTOM_OAUTH_BASE_URL
  ? `https://${process.env.DOMAIN}/api/v1/oauth/custom/callback`
  : `https://${process.env.DOMAIN}/api/v1/oauth/microsoft/callback`;
export const MICROSOFT_GRAPH_BASE_URL = `https://graph.microsoft.com`;
export const MICROSOFT_GRAPH_ME_ENDPOINT =
  process.env.LOGIN_METHODS_MICROSOFT_CUSTOM_OAUTH_USERINFO_ENDPOINT || `/v1.0/me`;
export const SSO_REDIRECT_LANDING_PAGE = `/landing-sso`;
