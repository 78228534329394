import React from 'react';
import clsx from 'clsx';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/types';
import { getIsWorkbenchEnabled } from '@redux/Login/selectors';
import { Box } from '@mui/material';
import { WuiMenuOnHover } from '@uiKit/WuiMenuOnHover/WuiMenuOnHover';
import { STANDARD_DATABASES_ORDERED_LIST } from 'services/normalizers/standardDatabases';
import { WuiMenuItemProps } from '@uiKit/WuiMenuItem/WuiMenu';
import { Routes } from '../../routes';
import wbLogo from '../../assets/media/wb-logo-only.png';
import { GlobalSettingsTabs } from '../../pages/Settings/SettingsSideBar/SettingsSideBar';
import UserActions from './components/UserActions';

import styles from './HeaderNavigation.module.scss';

const KEYWORD = 'type';
const STANDARD_DATABASES_WITH_KEYWORD = STANDARD_DATABASES_ORDERED_LIST.filter((db) =>
  db.name.toLowerCase().includes(KEYWORD),
);
const STANDARD_DATABASES_WITHOUT_KEYWORD = STANDARD_DATABASES_ORDERED_LIST.filter(
  (db) => !db.name.toLowerCase().includes(KEYWORD),
);

interface Database {
  slug: string;
  name: string;
}

const createDatabaseLink = ({ slug, name }: Database, index: number, arr: Database[]): WuiMenuItemProps => {
  const isLastItem = index === arr.length - 1;
  return {
    label: name,
    key: slug,
    component: NavLink,
    to: `/databases/${slug}`,
    activeClassName: styles.activeNestedLink,
    divider: isLastItem,
    draggable: false,
  } as WuiMenuItemProps;
};

const DatabaseLinkList: WuiMenuItemProps[] = [
  ...STANDARD_DATABASES_WITHOUT_KEYWORD.map(createDatabaseLink),
  ...STANDARD_DATABASES_WITH_KEYWORD.map(createDatabaseLink),
  {
    label: <FormattedMessage id="header.databases.custom" />,
    key: 'custom-database',
    component: NavLink,
    to: Routes.Databases,
    activeClassName: styles.activeNestedLink,
    exact: true,
    draggable: false,
  } as WuiMenuItemProps,
];

const HeaderNavigation: React.FC = () => {
  const isWorkbenchEnabled = useSelector<RootState, boolean>(getIsWorkbenchEnabled);

  const isDatabaseRoute = useRouteMatch(Routes.Databases);

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logoLink}>
        <img src={wbLogo} />
      </Link>

      <nav>
        {/* <NavLink exact activeClassName={styles.active} to="/">
        <FormattedMessage id="header.applications" />
      </NavLink> */}
        <NavLink activeClassName={styles.active} to={Routes.Find}>
          <FormattedMessage id="header.find" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Users}>
          <FormattedMessage id="header.users" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Roles}>
          <FormattedMessage id="header.roles" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Skills}>
          <FormattedMessage id="header.skills" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Documents}>
          <FormattedMessage id="header.documents" />
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          to={Routes.Projects}
          isActive={(match, location) => {
            if (match) {
              return true;
            }

            return (
              location.pathname.startsWith('/connectors') ||
              location.pathname.startsWith('/workinstructions') ||
              location.pathname.startsWith('/rules') ||
              location.pathname.startsWith('/functions')
            );
          }}
        >
          <FormattedMessage id="projects" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={Routes.Devices}>
          <FormattedMessage id="header.devices" />
        </NavLink>
        <WuiMenuOnHover items={DatabaseLinkList}>
          <Box sx={{ userSelect: 'none' }} className={clsx(styles.navItem, { [styles.active]: isDatabaseRoute })}>
            <FormattedMessage id="header.databases" />
          </Box>
        </WuiMenuOnHover>
        {isWorkbenchEnabled && (
          <NavLink activeClassName={styles.active} to={Routes.Workbench}>
            <FormattedMessage id="header.workbench" />
          </NavLink>
        )}
        <NavLink activeClassName={styles.active} to={Routes.Locations}>
          <FormattedMessage id="header.locations" />
        </NavLink>
        <NavLink activeClassName={styles.active} to={`/settings/${GlobalSettingsTabs.NETWORK_SETUP}`}>
          <FormattedMessage id="header.settings" />
        </NavLink>
        <UserActions />
      </nav>
    </div>
  );
};

export default React.memo(HeaderNavigation);
