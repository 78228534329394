export interface VariableWithPath {
  name: string;
  path: string;
}

interface VariableWithValueInfo {
  lastValue: string;
  datatype: VariableDataTypes;
}

export interface VariableWithPartialPath {
  pathSuffix: string;
  lastValue: string;
  datatype: VariableDataTypes;
}

// Objects of this interface represent variables which are offered for usage in i.e. autosuggestions in the frontend
export interface SelectableVariable extends VariableWithValueInfo {
  name: string;
}

export interface WorkinstructionStepOutputVariablePath extends Pick<SelectableVariable, 'datatype'> {
  pathSuffix: string | null;
}

export enum VariableDataTypes {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  UNKNOWN = 'unknown',
}
