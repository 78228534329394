function randomArrayElement(ary) {
  // eslint-disable-next-line no-bitwise
  return ary[0 | (ary.length * Math.random())];
}

// TODO: replace usage of generateRandomId() by uid() and remove it
export default function generateRandomId() {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const res: string[] = [];
  for (let i = 0; i < 10; i++) {
    res.push(randomArrayElement(chars));
  }
  return res.join('');
}
