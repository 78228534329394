import { Connector } from 'services/types/Connector';

import { RootState } from '../types';

export const getConnectorsOnCurrentPage = (store: RootState) =>
  store.connectors.currentListItemsIds.map((id) => store.connectors.connectorsById[id]);

export const getListConfigs = (store: RootState) => store.connectors.listConfigs;

export const getConnectorWithId =
  (connectorId?: string) =>
  (store: RootState): Connector | undefined =>
    connectorId ? store.connectors.connectorsById[connectorId] : undefined;
