import {
  updateFilteringFactory,
  updatePaginationFactory,
  updateSortingFactory,
} from '@redux/common/ListConfig/actions';
import { Property } from '@workerbase/types/ListConfig';
import { SkillPOST, SkillPUT } from '@workerbase/types/api/skills';

import { PaginationMeta } from '@workerbase/types/Response';
import { Skill } from 'services/types/Skill';

import { BaseAction } from '../common/actions';

export enum SkillsActions {
  GET_SKILLS_REQUEST = '@@skills/GET_SKILLS_REQUEST',
  GET_SKILLS_SUCCESS = '@@skills/GET_SKILLS_SUCCESS',
  GET_SKILLS_FAILURE = '@@skills/GET_SKILLS_FAILURE',
  GET_SKILL_BY_ID_REQUEST = '@@skills/GET_SKILL_BY_ID_REQUEST',
  GET_SKILL_BY_ID_SUCCESS = '@@skills/GET_SKILL_BY_ID_SUCCESS',
  GET_SKILL_BY_ID_FAILURE = '@@skills/GET_SKILL_BY_ID_FAILURE',
  SAVE_MANY_SKILLS = '@@skills/SAVE_MANY_SKILLS',
  CREATE_SKILL_REQUEST = '@@skills//CREATE_SKILL_REQUEST',
  UPDATE_SKILL_BY_ID_REQUEST = '@@skills//UPDATE_SKILL_BY_ID_REQUEST',
  DELETE_SKILL_BY_ID_REQUEST = '@@skills/DELETE_SKILL_BY_ID_REQUEST',
  DELETE_SKILL_BY_ID_SUCCESS = '@@skills/DELETE_SKILL_BY_ID_SUCCESS',
  DELETE_SKILL_BY_ID_FAILURE = '@@skills/DELETE_SKILL_BY_ID_FAILURE',
  // PAGINATION
  UPDATE_PAGINATION = '@@skills/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@skills/UPDATE_SORTING',
  UPDATE_FILTERING = '@@skills/UPDATE_FILTERING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@skills/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@skills/UPDATE_LIST_CONFIG',
}

// GET SKILLS
export const getSkillsRequestAction = (forceLoading = false): BaseAction => ({
  type: SkillsActions.GET_SKILLS_REQUEST,
  payload: { forceLoading },
});

export const getSkillsSuccess = (data: Skill[], meta: PaginationMeta): BaseAction => ({
  type: SkillsActions.GET_SKILLS_SUCCESS,
  payload: {
    skills: data,
    meta,
  },
});

export const getSkillsError = (errorMessage: string): BaseAction => ({
  type: SkillsActions.GET_SKILLS_FAILURE,
  payload: {
    errorMessage,
  },
});

// GET_SKILL_BY_ID
export const getSkillByIdRequestAction = (skillId: string): BaseAction => ({
  type: SkillsActions.GET_SKILL_BY_ID_REQUEST,
  payload: { skillId },
});

export const getSkillByIdSuccess = (data: Skill): BaseAction => ({
  type: SkillsActions.GET_SKILL_BY_ID_SUCCESS,
  payload: {
    skill: data,
  },
});

export const getSkillByIdError = (errorMessage: string): BaseAction => ({
  type: SkillsActions.GET_SKILL_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export const saveManySkills = (data: Skill[]): BaseAction => ({
  type: SkillsActions.SAVE_MANY_SKILLS,
  payload: {
    skills: data,
  },
});

// CREATE_SKILL
export interface CreateSkillRequestAction {
  type: string;
  payload: {
    skill: SkillPOST;
  };
}
export const createSkillRequest = (skill: SkillPOST): CreateSkillRequestAction => ({
  type: SkillsActions.CREATE_SKILL_REQUEST,
  payload: { skill },
});

// UPDATE_SKILL_BY_ID
export interface UpdateSkillByIdRequestAction {
  type: string;
  payload: {
    skill: SkillPUT;
    skillId: string;
  };
}
export const updateSkillByIdRequest = (skillId: string, skill: SkillPUT): UpdateSkillByIdRequestAction => ({
  type: SkillsActions.UPDATE_SKILL_BY_ID_REQUEST,
  payload: { skill, skillId },
});

// DELETE_SKILL_BY_ID
export interface DeleteSkillByIdRequestAction {
  type: string;
  payload: {
    skillId: string;
  };
}
export const deleteSkillByIdRequest = (skillId: string): DeleteSkillByIdRequestAction => ({
  type: SkillsActions.DELETE_SKILL_BY_ID_REQUEST,
  payload: { skillId },
});

export const deleteSkillByIdSuccess = (skillId: string): DeleteSkillByIdRequestAction => ({
  type: SkillsActions.DELETE_SKILL_BY_ID_SUCCESS,
  payload: {
    skillId,
  },
});

export const deleteSkillByIdError = (errorMessage: string): BaseAction => ({
  type: SkillsActions.DELETE_SKILL_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(SkillsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(SkillsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(SkillsActions.UPDATE_FILTERING);

export const updateListConfigProperties = (newProperties: Property[]) => ({
  type: SkillsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});
