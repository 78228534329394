import React from 'react';
import { Redirect } from 'react-router';

import { useSelector } from 'react-redux';
import { getIsAuthenticated } from '@redux/Login';
import { RootState } from '@redux/types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
interface LoggedOutRouteProps extends Record<string, any> {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
  component: React.ComponentType<any>;
}

const LoggedOutRoute: React.FC<LoggedOutRouteProps> = ({
  component: Component,
  path,
  computedMatch,
  ...otherProps
}) => {
  const isAuthenticated = useSelector<RootState, boolean>(getIsAuthenticated);

  if (isAuthenticated === true) {
    return <Redirect to="/" />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading -- allow props spreading for these special Router route
  return <Component match={computedMatch} {...otherProps} />;
};

export default LoggedOutRoute;
