import { Prop, Schema } from '@nestjs/mongoose';
import { IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoLoading extends StepBase {
  type: StepTypes.INFO_LOADING;

  @Prop({
    required: true,
    default: '0',
  })
  @IsString()
  taskAppearingDuration: number;
}
