import _ from 'lodash';
import { User } from 'services/types/User';

import { RootState } from '../types';

export const getListConfigs = (store: RootState) => store.users.listConfigs;

export const getTestRunDataByFunctionId =
  (functionId?: string) =>
  (store: RootState): string =>
    functionId ? _.get(store, `users.testRunDataByFunctionId.${functionId}`, '') : '';

export const getUserWithId =
  (userId?: string) =>
  (store: RootState): User | undefined =>
    userId ? store.users.usersById[userId] : undefined;
