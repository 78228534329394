import { Prop, Schema } from '@nestjs/mongoose';
import { IsString } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { StepBase } from './base/StepBase';

@Schema()
export class InfoWebview extends StepBase {
  type: StepTypes.INFO_WEBVIEW;

  @Prop()
  @IsString()
  url?: string;
}
