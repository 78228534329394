import { styled } from '@mui/material';
import { MENTIONS_CORE_CLASSNAME } from './constants';
import { colors, inputSizes } from '../../stylesheet';

const FIELD_LINE_HEIGHT = 20;
const DROPDOWN_ITEM_HEIGHT = 32;
const DROPDOWN_VISIBLE_ITEMS_COUNT = 10;
const DROPDOWN_MAX_WIDTH = 500;

export const getMultilineFieldMinHeight = (minRows: number) =>
  Number.parseInt(inputSizes.normal.height, 10) - FIELD_LINE_HEIGHT + FIELD_LINE_HEIGHT * minRows;

// for styling details see https://github.com/signavio/react-mentions#styling
export const MentionsInputContainer = styled('div')<{
  multiline: boolean;
  minRows: number;
  error?: boolean;
  disabled?: boolean;
}>(({ theme, multiline, minRows, error, disabled }) => ({
  '*': {
    boxSizing: 'border-box',
  },
  [`.${MENTIONS_CORE_CLASSNAME}`]: {
    position: 'relative',
    cursor: 'text',
    display: 'block',
    backgroundColor: '#FFFFFF',

    minWidth: '190px',
    maxWidth: '100%',

    fontSize: '16px',
    lineHeight: `${FIELD_LINE_HEIGHT}px`,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)',

    '&__suggestions': {
      zIndex: '10!important',
      '&__list': {
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        fontSize: '10pt',
        borderRadius: '4px',
        overflow: 'auto',
        maxHeight: `${DROPDOWN_ITEM_HEIGHT * DROPDOWN_VISIBLE_ITEMS_COUNT}px`,
        maxWidth: `${DROPDOWN_MAX_WIDTH}px`,
      },
      '&__item': {
        padding: '5px 10px',
        minHeight: `${DROPDOWN_ITEM_HEIGHT}px`,
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        '&__display': {
          maxWidth: '100%',
          wordWrap: 'break-word',
        },
        '&--focused': {
          backgroundColor: colors.B150,
        },
      },
    },

    '&__highlighter, &__input': {
      padding: '9px 12px',
      lineHeight: `${FIELD_LINE_HEIGHT}px!important`,
    },

    '&__highlighter': {
      // mention item
      '& strong': {
        position: 'relative',
        zIndex: 1,
        color: colors.B500,
        pointerEvents: 'none',
        backgroundColor: 'white',
      },
    },

    '&__control': {
      ...(multiline
        ? {
            minHeight: `${getMultilineFieldMinHeight(minRows)}px`,
          }
        : {}),
    },

    '&__input': {
      border: `1px solid ${error && !disabled ? theme.palette.error.main : 'rgb(205, 210, 224)'}`,
      borderRadius: '6px',
      outline: 'none!important',
      bottom: 0, // to support minHeight for parent container
      '::placeholder': {
        color: theme.palette.grey[400],
      },
      '&:hover': {
        border: `1px solid ${theme.palette.grey[400]}`,
      },
      ...(disabled
        ? {
            backgroundColor: theme.palette.grey[50],
          }
        : {
            '&:focus': {
              border: `1px solid ${colors.B300}`,
            },
          }),
    },
  },
}));
