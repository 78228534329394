import { StepTypes } from '../StepTypes.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import { Step } from '../../../../MongoDB/Workinstruction/Step';
import { StepCore } from '../../../../MongoDB/Workinstruction/Steps/base/StepCore';
import { ModuleStep } from '../../../../MongoDB/Workinstruction/Steps/ModuleStep';

export const RENDERED_STEP_TYPES = Object.values(StepTypes).filter((type) => ![StepTypes.MODULE].includes(type));

export type RenderedStepTypes = (typeof RENDERED_STEP_TYPES)[number];

export type RenderedStep = Exclude<Step, ModuleStep>;

/**
 * Usually workinstruction steps are rendered, which means that the step will be shown to the user, when executed.
 * There are though exceptions, one is the module step.
 *
 * Rendered and non-rendered steps have very different properties, so this
 * guard was introduced in order to differentiate easier between rendered and non-rendered steps in the code.
 */
export const isRendered = (step: StepCore): step is RenderedStep =>
  RENDERED_STEP_TYPES.includes(step.type as RenderedStepTypes);
