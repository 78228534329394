import { Sorting } from '@workerbase/types/ListConfig';
import { normalizeFunction, normalizeFunctionLog } from 'services/normalizers/functions';
import {
  FunctionGET,
  FunctionLog,
  FunctionLogGET,
  FunctionPOST,
  FunctionPUT,
  FunctionType,
  GetFunctionsParams,
} from '@workerbase/types/api/functions';
import { PaginatedQueryParams, PaginatedResponse } from '@workerbase/types/api/pagination';
import { api } from './api';

export const FUNCTIONS_ENDPOINT = '/api/v1/functions';

type GetFunctions = (options: {
  projectId?: string;
  page?: number;
  perPage?: number;
  sorting?: Sorting;
  filtering?: string;
  aggregateLinks?: boolean;
}) => Promise<PaginatedResponse<FunctionType[]>>;

export const getFunctions: GetFunctions = async ({
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  aggregateLinks,
}) => {
  const params: GetFunctionsParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    project: projectId,
    aggregateLinks,
  };

  const {
    data: { data, ...rest },
  } = await api.get<PaginatedResponse<FunctionGET[]>>(FUNCTIONS_ENDPOINT, { params });

  return { data: data.map(normalizeFunction), ...rest };
};

export const getFunctionById = async (functionId: string): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.get<{ data: FunctionGET }>(`${FUNCTIONS_ENDPOINT}/${functionId}`);

  return normalizeFunction(data);
};

export const createFunction = async (func: FunctionPOST): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.post<{ data: FunctionGET }>(FUNCTIONS_ENDPOINT, func);

  return normalizeFunction(data);
};

export const deleteFunctionById = async (functionId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${FUNCTIONS_ENDPOINT}/${functionId}`);

  return true;
};

export const updateFunctionById = async (functionId: string, func: FunctionPUT): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.put<{ data: FunctionGET }>(`${FUNCTIONS_ENDPOINT}/${functionId}`, func);

  return normalizeFunction(data);
};

export const deployFunctionWithId = async (functionId: string): Promise<boolean> => {
  await api.post<unknown>(`${FUNCTIONS_ENDPOINT}/${functionId}/deploy`);

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const invokeFunctionWithId = async (functionId: string, payload: Record<string, any>): Promise<boolean> => {
  await api.post<unknown>(`${FUNCTIONS_ENDPOINT}/${functionId}/invoke`, payload);

  return true;
};

type GetFunctionLogs = (
  functionId: string,
  page: number,
  perPage: number,
  sorting?: Sorting,
  filtering?: string,
) => Promise<PaginatedResponse<FunctionLog[]>>;

export const getFunctionLogs: GetFunctionLogs = async (functionId, page = 1, perPage = 20, sorting, filtering) => {
  const params: PaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
  };

  const {
    data: { data, ...rest },
  } = await api.get<PaginatedResponse<FunctionLogGET[]>>(`${FUNCTIONS_ENDPOINT}/${functionId}/history`, { params });

  return { data: data.map(normalizeFunctionLog), ...rest };
};
