import { ShiftPlanGET, ShiftPlanPOST, ShiftPlanPUT } from '@workerbase/types/api/shiftPlans';
import { Property } from '@workerbase/types/ListConfig';
import { PaginationMeta } from '@workerbase/types/Response';
import { BaseAction } from '../common/actions';
import { updatePaginationFactory } from '../common/ListConfig/actions';

export enum ShiftPlansActions {
  GET_SHIFT_PLANS_REQUEST = '@@shiftPlans/GET_SHIFT_PLANS_REQUEST',
  GET_SHIFT_PLANS_SUCCESS = '@@shiftPlans/GET_SHIFT_PLANS_SUCCESS',
  GET_SHIFT_PLANS_FAILURE = '@@shiftPlans/GET_SHIFT_PLANS_FAILURE',
  GET_ALL_SHIFT_PLANS_REQUEST = '@@shiftPlans/GET_ALL_SHIFT_PLANS_REQUEST',
  GET_ALL_SHIFT_PLANS_SUCCESS = '@@shiftPlans/GET_ALL_SHIFT_PLANS_SUCCESS',
  GET_ALL_SHIFT_PLANS_FAILURE = '@@shiftPlans/GET_ALL_SHIFT_PLANS_FAILURE',
  DELETE_SHIFT_PLAN_BY_ID_REQUEST = '@@shiftPlans/DELETE_SHIFT_PLAN_BY_ID_REQUEST',
  DELETE_SHIFT_PLAN_BY_ID_SUCCESS = '@@shiftPlans/DELETE_SHIFT_PLAN_BY_ID_SUCCESS',
  DELETE_SHIFT_PLAN_BY_ID_FAILURE = '@@shiftPlans/DELETE_SHIFT_PLAN_BY_ID_FAILURE',
  CREATE_SHIFT_PLAN_REQUEST = '@@shiftPlans/CREATE_SHIFT_PLAN_REQUEST',
  UPDATE_SHIFT_PLAN_BY_ID_REQUEST = '@@shiftPlans/UPDATE_SHIFT_PLAN_BY_ID_REQUEST',
  GET_LIST_CONFIG_REQUEST = '@@shiftPlans/GET_LIST_CONFIG_REQUEST',
  UPDATE_PAGINATION = '@@shiftPlans/UPDATE_PAGINATION',
  UPDATE_LIST_CONFIG = '@@shiftPlans/UPDATE_LIST_CONFIG',
  UPDATE_LISTCONFIG_PROPERTIES = '@@shiftPlans/UPDATE_LISTCONFIG_PROPERTIES',
}

export const getShiftPlansRequestAction = (): BaseAction => ({
  type: ShiftPlansActions.GET_SHIFT_PLANS_REQUEST,
});

export const getShiftPlansSuccess = (shiftPlans: ShiftPlanGET[], meta: PaginationMeta): BaseAction => ({
  type: ShiftPlansActions.GET_SHIFT_PLANS_SUCCESS,
  payload: {
    shiftPlans,
    meta,
  },
});

export const getShiftPlansError = (errorMessage: string): BaseAction => ({
  type: ShiftPlansActions.GET_SHIFT_PLANS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const requestAllShiftPlans = (): BaseAction => ({
  type: ShiftPlansActions.GET_ALL_SHIFT_PLANS_REQUEST,
});

export const getAllShiftPlansSuccessAction = (shiftPlans: ShiftPlanGET[]): BaseAction => ({
  type: ShiftPlansActions.GET_ALL_SHIFT_PLANS_SUCCESS,
  payload: {
    shiftPlans,
  },
});

export const getAllShiftPlansErrorAction = (errorMessage: string): BaseAction => ({
  type: ShiftPlansActions.GET_SHIFT_PLANS_FAILURE,
  payload: {
    errorMessage,
  },
});

export interface DeleteShiftPlanByIdRequestAction {
  type: string;
  payload: {
    shiftPlanId: string;
  };
}
export const deleteShiftPlanByIdRequest = (shiftPlanId: string): DeleteShiftPlanByIdRequestAction => ({
  type: ShiftPlansActions.DELETE_SHIFT_PLAN_BY_ID_REQUEST,
  payload: { shiftPlanId },
});

export const deleteShiftPlanByIdSuccess = (shiftPlanId: string): DeleteShiftPlanByIdRequestAction => ({
  type: ShiftPlansActions.DELETE_SHIFT_PLAN_BY_ID_SUCCESS,
  payload: {
    shiftPlanId,
  },
});

export const deleteShiftPlanByIdError = (errorMessage: string): BaseAction => ({
  type: ShiftPlansActions.DELETE_SHIFT_PLAN_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

export interface CreateShiftPlanRequestAction {
  type: string;
  payload: {
    shiftPlan: ShiftPlanPOST;
  };
}
export const createShiftPlanRequest = (shiftPlan: ShiftPlanPOST): CreateShiftPlanRequestAction => ({
  type: ShiftPlansActions.CREATE_SHIFT_PLAN_REQUEST,
  payload: { shiftPlan },
});

export interface UpdateShiftPlanByIdRequestAction {
  type: string;
  payload: {
    shiftPlan: ShiftPlanPUT;
    shiftPlanId: string;
  };
}
export const updateShiftPlanByIdRequest = (
  shiftPlanId: string,
  shiftPlan: ShiftPlanPUT,
): UpdateShiftPlanByIdRequestAction => ({
  type: ShiftPlansActions.UPDATE_SHIFT_PLAN_BY_ID_REQUEST,
  payload: { shiftPlan, shiftPlanId },
});

export interface GetShiftPlanListConfigAction {
  type: string;
  payload: {
    shiftPlanId: string;
  };
}
export const getShiftPlanListConfigRequest = (shiftPlanId: string): GetShiftPlanListConfigAction => ({
  type: ShiftPlansActions.GET_LIST_CONFIG_REQUEST,
  payload: { shiftPlanId },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(ShiftPlansActions.UPDATE_PAGINATION);

export const updateListConfigProperties = (newProperties: Property[]) => ({
  type: ShiftPlansActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});
