import { Property, Sorting } from '@workerbase/types/ListConfig';

import { BaseAction } from '../common/actions';

export enum DatabaseItemsActions {
  UPDATE_ITEMS_LISTCONFIG_PROPERTIES = '@@databaseItems/UPDATE_ITEMS_LISTCONFIG_PROPERTIES',
  UPDATE_ITEMS_UPDATE_SORTING = '@@databaseItems/UPDATE_SORTING',
  UPDATE_LIST_CONFIG = '@@databaseItems/UPDATE_LIST_CONFIG',
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateItemsProperties = (resourceId: string, properties: Property[]) => ({
  type: DatabaseItemsActions.UPDATE_ITEMS_LISTCONFIG_PROPERTIES,
  payload: { resourceId, properties },
});

export interface UpdateDatabaseItemsSortingActionPayload {
  resourceId: string;
  sorting: Sorting;
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const getUpdateDatabaseItemsSortingAction = (payload: UpdateDatabaseItemsSortingActionPayload): BaseAction => ({
  type: DatabaseItemsActions.UPDATE_ITEMS_UPDATE_SORTING,
  payload,
});
