import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { mqttSubscribe, mqttUnsubscribe } from '@redux/Mqtt';
import { shouldDisplayLinks } from '@workerbase/types/ListConfig';
import { MqttTopics } from '@workerbase/types/MQTT/MqttTopics';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createConnector,
  deleteConnectorById,
  getConnectorById,
  getConnectors,
  updateConnectorById,
} from 'services/networking/connectors';
import { Connector } from 'services/types/Connector';
import { PaginationMeta } from '@workerbase/types/Response';
import { handleRequestError } from '../common';
import {
  ConnectorsActions,
  CreateConnectorRequestAction,
  deleteConnectorByIdError,
  deleteConnectorByIdSuccess,
  EventsSubscribeAction,
  getConnectorByIdError,
  getConnectorByIdSuccess,
  getConnectorsError,
  getConnectorsSuccess,
  UpdateConnectorByIdRequestAction,
} from './actions';
import { getListConfigs } from './selectors';

export function* getConnectorsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const projectId = action.payload.projectId;

    const response = yield call(getConnectors, {
      projectId,
      page: listConfigs.pagination.currentPage,
      perPage: listConfigs.pagination.itemsPerPage,
      sorting: listConfigs.sorting,
      filtering: listConfigs.filtering.searchTerms,
      aggregateLinks: shouldDisplayLinks(listConfigs),
      selectedTabKey: listConfigs.tabs?.selectedTabKey,
      categoryKey: listConfigs.tabs?.categoryKey,
    });

    const connectors: Connector[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getConnectorsSuccess(connectors, meta));
  } catch (error) {
    yield put(getConnectorsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getConnectorByIdRequestSaga(action) {
  try {
    const connector: Connector = yield call(getConnectorById, action.payload.connectorId);

    yield put(getConnectorByIdSuccess(connector));
  } catch (error) {
    yield put(getConnectorByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createConnectorRequestSaga(action: CreateConnectorRequestAction) {
  try {
    const connector: Connector = yield call(createConnector, action.payload.connector);
    yield put(getConnectorByIdSuccess(connector));
    yield put(push(`/projects/${connector.projectId}/connectors/${connector.id}/edit`));
    yield call(toast.success, 'Connector created');
  } catch (error) {
    yield put(getConnectorByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deleteConnectorByIdRequestSaga(action) {
  try {
    yield call(deleteConnectorById, action.payload.connectorId);
    yield call(toast.success, 'Connector deleted');
    yield put(deleteConnectorByIdSuccess(action.payload.connectorId));
  } catch (error) {
    yield put(deleteConnectorByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* updateConnectorByIdRequestSaga(action: UpdateConnectorByIdRequestAction) {
  try {
    const connector: Connector = yield call(updateConnectorById, action.payload.connectorId, action.payload.connector);
    yield put(getConnectorByIdSuccess(connector));
    yield put(push(`/projects/${connector.projectId}/connectors`));
    yield call(toast.success, 'Connector updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* subscribeToMqttSaga(action: EventsSubscribeAction) {
  try {
    yield put(
      mqttSubscribe(
        MqttTopics.EXTERNAL_EVENT.replace('+', action.payload.connectorId),
        ConnectorsActions.HANDLE_INCOMING_MQTT_EVENT,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* unsubscribeToMqttSaga(action: EventsSubscribeAction) {
  try {
    yield put(
      mqttUnsubscribe(
        MqttTopics.EXTERNAL_EVENT.replace('+', action.payload.connectorId),
        ConnectorsActions.HANDLE_INCOMING_MQTT_EVENT,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* connectorsSagas() {
  yield takeLatest(ConnectorsActions.GET_CONNECTORS_REQUEST, getConnectorsRequestSaga);
  yield takeLatest(ConnectorsActions.GET_CONNECTOR_BY_ID_REQUEST, getConnectorByIdRequestSaga);
  yield takeLatest(ConnectorsActions.CREATE_CONNECTOR_REQUEST, createConnectorRequestSaga);
  yield takeLatest(ConnectorsActions.DELETE_CONNECTOR_BY_ID_REQUEST, deleteConnectorByIdRequestSaga);
  yield takeLatest(ConnectorsActions.UPDATE_CONNECTOR_BY_ID_REQUEST, updateConnectorByIdRequestSaga);
  yield takeLatest(ConnectorsActions.MQTT_SUBSCRIBE_EVENTS, subscribeToMqttSaga);
  yield takeLatest(ConnectorsActions.MQTT_UNSUBSCRIBE_EVENTS, unsubscribeToMqttSaga);
  yield takeLatest(ConnectorsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(ConnectorsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
