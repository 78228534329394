import { ContentBlock, ContentState, convertFromRaw, convertToRaw, RawDraftContentState } from 'draft-js';
import { Options, stateToHTML, RenderConfig } from 'draft-js-export-html';
import { Utils } from './Utils';

const stateToHTMLOptions: Options = {
  inlineStyles: {
    UNDERLINE: { element: 'span', style: { textDecoration: 'underline' } },
  },
  blockStyleFn: (block: ContentBlock): RenderConfig | undefined => {
    let alignment;

    block.findStyleRanges(
      (e) => {
        if (e.hasStyle('left')) {
          alignment = 'left';
        }
        if (e.hasStyle('center')) {
          alignment = 'center';
        }
        if (e.hasStyle('right')) {
          alignment = 'right';
        }
        return false;
      },
      () => null,
    );

    return alignment ? { style: { textAlign: alignment } } : undefined;
  },
};

export class RichTextConverter {
  static isRichText(text: string): boolean {
    try {
      const rawDraftContentState = JSON.parse(text);
      return 'blocks' in rawDraftContentState && 'entityMap' in rawDraftContentState;
    } catch (err) {
      return false;
    }
  }

  /**
   * Takes stringified draftJs raw result or plain text string
   */
  static convertStringToContentState = (value: string): ContentState => {
    try {
      const valueRaw: RawDraftContentState = JSON.parse(Utils.escapeNewLines(value));
      return convertFromRaw(valueRaw);
    } catch (err) {
      return ContentState.createFromText(value || '');
    }
  };

  static convertContentStateToString = (contentState: ContentState): string => {
    const contentRaw: RawDraftContentState = convertToRaw(contentState);
    return JSON.stringify(contentRaw);
  };

  /**
   * Takes stringified draftJs raw result or plain text string
   * Returns stringified html
   */
  static convertRichTextToHtml = (value: string): string => {
    const contentState = RichTextConverter.convertStringToContentState(value);
    return stateToHTML(contentState, stateToHTMLOptions).replaceAll(/><br></g, '>&nbsp<'); // format empty line;
  };

  /**
   * Takes stringified draftJs raw result or plain text string
   * Returns plain text
   */
  static convertRichTextToPlainText = (value: string): string => {
    const contentState = RichTextConverter.convertStringToContentState(value);
    return contentState.getPlainText();
  };
}
