import { takeLatest } from 'redux-saga/effects';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';

import { DatabaseItemsActions } from './actions';

export default function* databasesSagas() {
  yield takeLatest(DatabaseItemsActions.UPDATE_ITEMS_UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(DatabaseItemsActions.UPDATE_ITEMS_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
