import { Skill } from 'services/types/Skill';

import { RootState } from '../types';

export const getSkills =
  (skillIds?: string[]) =>
  (store: RootState): Skill[] => {
    if (skillIds) {
      return Object.values(store.skills.skillsById).filter((role) => skillIds.includes(role.id));
    }

    return store.skills.skillsById ? Object.values(store.skills.skillsById) : [];
  };

export const getSkillsOnCurrentPage = (store: RootState) =>
  store.skills.currentListItemsIds.map((id) => store.skills.skillsById[id]);

export const getListConfigs = (store: RootState) => store.skills.listConfigs;

export const getSkillWithId =
  (skillId?: string) =>
  (store: RootState): Skill | undefined =>
    skillId ? store.skills.skillsById[skillId] : undefined;

export const getIsLoading = (store: RootState) => store.skills.isLoading;
