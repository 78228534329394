import { ValidationArguments } from 'class-validator';

export class ClassValidatorOptions {
  public static getEnumErrorMessageOptions(enumType: any) {
    return {
      message: (validationArguments: ValidationArguments) =>
        `${validationArguments.property} has to be one of ${Object.values(enumType).join(' | ')}`,
    };
  }
}
