import { ListType } from '@workerbase/types/ListConfig';

import { getListConfigs as getConnectorLogsListConfig } from '@redux/ConnectorLogs/selectors';
import { getListConfigs as getConnectorsListConfig } from '@redux/Connectors/selectors';
import { getListConfigs as getDatabaseItemsListConfig } from '@redux/DatabaseItems/selectors';
import { getListConfigs as getDatabasesListConfig } from '@redux/Databases/selectors';
import { getListConfigs as getDevicesListConfig } from '@redux/Devices/selectors';
import { getListConfigs as getFunctionLogsListConfig } from '@redux/FunctionLogs/selectors';
import { getListConfigs as getFunctionsListConfig } from '@redux/Functions/selectors';
import { getListConfigs as getProjectsListConfig } from '@redux/Projects/selectors';
import { getListConfigs as getRolesListConfig } from '@redux/Roles/selectors';
import { getListConfigs as getRulesListConfig } from '@redux/Rules/selectors';
import { getListConfigs as getTasksListConfig } from '@redux/Tasks/selectors';
import { getListConfigs as getUserListConfig } from '@redux/Users/selectors';
import { getListConfigs as getWorkinstructionsListConfig } from '@redux/Workinstructions/selectors';
import { getListConfigs as getSkillsListConfig } from '@redux/Skills/selectors';
import { getListConfigs as getProjectLogsListConfig } from '@redux/ProjectLogs/selectors';

import { ConnectorLogsActions } from '@redux/ConnectorLogs/actions';
import { ConnectorsActions } from '@redux/Connectors/actions';
import { DatabasesActions } from '@redux/Databases/actions';
import { DatabaseItemsActions } from '@redux/DatabaseItems/actions';
import { DevicesActions } from '@redux/Devices/actions';
import { FunctionLogsActions } from '@redux/FunctionLogs/actions';
import { FunctionsActions } from '@redux/Functions/actions';
import { ProjectsActions } from '@redux/Projects/actions';
import { RolesActions } from '@redux/Roles/actions';
import { RulesActions } from '@redux/Rules/actions';
import { TasksActions } from '@redux/Tasks/actions';
import { UsersActions } from '@redux/Users/actions';
import { WorkinstructionsActions } from '@redux/Workinstructions/actions';
import { SkillsActions } from '@redux/Skills/actions';
import { ProjectLogsActions } from '@redux/ProjectLogs/actions';

export const listTypeToGetListConfigSelectorMapping = Object.freeze({
  [ListType.CONNECTOR_LOGS]: getConnectorLogsListConfig,
  [ListType.CONNECTORS]: getConnectorsListConfig,
  [ListType.DATABASE_ITEMS]: getDatabaseItemsListConfig,
  [ListType.DATABASES]: getDatabasesListConfig,
  [ListType.DEVICES]: getDevicesListConfig,
  [ListType.FUNCTION_LOGS]: getFunctionLogsListConfig,
  [ListType.FUNCTIONS]: getFunctionsListConfig,
  [ListType.PROJECTS]: getProjectsListConfig,
  [ListType.PROJECT_LOGS]: getProjectLogsListConfig,
  [ListType.ROLES]: getRolesListConfig,
  [ListType.RULES]: getRulesListConfig,
  [ListType.SKILLS]: getSkillsListConfig,
  [ListType.TASKS]: getTasksListConfig,
  [ListType.WORKINSTRUCTION]: getWorkinstructionsListConfig,
  [ListType.USERS]: getUserListConfig,
});

export const listTypeToUpdateListConfigActionMapping = Object.freeze({
  [ListType.CONNECTOR_LOGS]: ConnectorLogsActions.UPDATE_LIST_CONFIG,
  [ListType.CONNECTORS]: ConnectorsActions.UPDATE_LIST_CONFIG,
  [ListType.DATABASES]: DatabasesActions.UPDATE_LIST_CONFIG,
  [ListType.DATABASE_ITEMS]: DatabaseItemsActions.UPDATE_LIST_CONFIG,
  [ListType.DEVICES]: DevicesActions.UPDATE_LIST_CONFIG,
  [ListType.FUNCTION_LOGS]: FunctionLogsActions.UPDATE_LIST_CONFIG,
  [ListType.FUNCTIONS]: FunctionsActions.UPDATE_LIST_CONFIG,
  [ListType.PROJECTS]: ProjectsActions.UPDATE_LIST_CONFIG,
  [ListType.PROJECT_LOGS]: ProjectLogsActions.UPDATE_LIST_CONFIG,
  [ListType.ROLES]: RolesActions.UPDATE_LIST_CONFIG,
  [ListType.RULES]: RulesActions.UPDATE_LIST_CONFIG,
  [ListType.TASKS]: TasksActions.UPDATE_LIST_CONFIG,
  [ListType.USERS]: UsersActions.UPDATE_LIST_CONFIG,
  [ListType.WORKINSTRUCTION]: WorkinstructionsActions.UPDATE_LIST_CONFIG,
  [ListType.SKILLS]: SkillsActions.UPDATE_LIST_CONFIG,
});
