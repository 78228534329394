/* eslint-disable react/jsx-props-no-spreading --- needed in this file */
import React, { FC, PropsWithChildren } from 'react';
import { FormLabel as MuiFormLabel, FormLabelProps as MuiFormLabelProps } from '@mui/material';

type WuiFormLabelProps = Omit<MuiFormLabelProps, 'htmlFor'> & {
  htmlFor: string;
};

export const WuiFormLabel: PropsWithChildren<FC<WuiFormLabelProps>> = ({
  htmlFor,
  children,
  ...otherProps
}: WuiFormLabelProps) => (
  <MuiFormLabel
    htmlFor={htmlFor}
    sx={{
      color: 'text.secondary',
      mb: 1,
      fontSize: '14px',
      fontWeight: 600,
    }}
    {...otherProps}
  >
    {children}
  </MuiFormLabel>
);
