import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getUser } from '@redux/Login';
import { RootState } from '@redux/types';

import ErrorBoundary from './ErrorBoundary';

const mapStateToProps = (state: RootState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
