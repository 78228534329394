import { ListOptionsSource } from '../ListOptionsSource.enum';
// TODO: replace imports from MongoDB with domain interfaces - WB-4087
import { DatabaseViewMapping } from '../../../../MongoDB/DatabaseViewMapping';
import { InputValueListOption, InputValueList as InputValueListStep } from '../../../../MongoDB/Workinstruction/Steps';

export { InputValueListOption };

export interface InputValueListWithOptions
  extends Omit<InputValueListStep, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.OPTIONS;
  options: InputValueListOption[];
  isVar: false;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
  mapping: undefined;
}
export interface InputValueListFromVariable
  extends Omit<InputValueListStep, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.VARIABLE;
  options: string;
  isVar: true;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
  mapping: undefined;
}
export interface InputValueListFromConnector
  extends Omit<InputValueListStep, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.CONNECTOR;
  loadOptionsFromUrl: string;
  loadFromUrl: true;
  isVar: false;
  options: undefined;
  mapping: undefined;
}

export interface InputValueListFromDatabase
  extends Omit<InputValueListStep, 'loadOptionsFromUrl' | 'loadFromUrl' | 'isVar' | 'options' | 'mapping'> {
  source: ListOptionsSource.DATABASE;
  mapping: DatabaseViewMapping;
  options: undefined;
  isVar: false;
  loadFromUrl: false;
  loadOptionsFromUrl: undefined;
}

export type InputValueList =
  | InputValueListWithOptions
  | InputValueListFromVariable
  | InputValueListFromConnector
  | InputValueListFromDatabase;
