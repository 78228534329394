import { Devices } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';
import { arrayOfAll } from 'utils/arrayOfAll';
import { WiSidebarFormValues } from './components/SidebarForm/types';
import { WiStepsFormValues } from './components/StepForm/types';
import { WiMyWorkFormValues } from './components/MyWorkForm/types';
import { WiGeneralFormValues } from './components/GeneralForm/types';
import { WiReasonsFormValues } from './components/ReasonsForm/types';

export enum WiWorkflowType {
  PRIMARY = 'primary', // when showAsApp is `false` OR both showAsApp and visibleWhenInProgress are `true`
  SECONDARY = 'secondary', // when showAsApp is `true`, but visibleWhenInProgress is `false`
}

export interface WiFormValues {
  id: string;
  projectId: string;
  initialStepId: string;
  devicesCompatibility: Record<Devices, boolean>;

  tabs: {
    general: WiGeneralFormValues;
    steps: WiStepsFormValues;
    sidebar: WiSidebarFormValues;
    reasons: WiReasonsFormValues;
    myWork: WiMyWorkFormValues;
  };
}

export type WiFormTabs = keyof WiFormValues['tabs'];

export const wiFormTabsUiOrder = arrayOfAll<WiFormTabs>()(['general', 'steps', 'sidebar', 'reasons', 'myWork']);
