export enum StepActionTypes {
  CLOSE = 'close',
  FINISH = 'finish',
  SUSPEND = 'suspend',
  STEP = 'step',
  TASK = 'task',
  WORKINSTRUCTIONS = 'workinstructions',
  APP = 'app',
  LOAD_NEXT_STEP = 'loadNextStep',
  LOAD_NEXT_ACTION = 'loadNextAction',
}
