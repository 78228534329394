import { LeanDocument } from 'mongoose';

import { RuleDocument } from './MongoDB/Rule';

export type Rule = LeanDocument<RuleDocument>;

export enum NotifyLocationTypes {
  ALL = 'All',
  EVENT_ORIGIN = 'EventOrigin',
  DEFINED_LOCATION = 'DefinedLocation',
  VARIABLE = 'Variable',
}

export enum RuleTypes {
  INTERNAL_EVENT = 'internalEvent',
  EXTERNAL_EVENT = 'externalEvent',
  RULES = 'rules',
}

export interface Variable {
  id: string;
  text: string;
}
export interface AdditionalVariables {
  [key: string]: Variable | string;
}

export enum RuleDatabaseActions {
  CREATE_OR_UPDATE = 'CREATE_OR_UPDATE',
  IGNORE = 'IGNORE',
  DELETE = 'DELETE',
}

export enum RuleDatabaseItemFieldActions {
  IGNORE = 'IGNORE',
  REMOVE = 'REMOVE',
  REPLACE = 'REPLACE',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}
