import React, { FC } from 'react';
import { MenuList, MenuListProps } from '@mui/material';
import { WuiMenuItem, WuiMenuItemProps } from '@uiKit/WuiMenuItem/WuiMenu';

export interface WuiMenuListCustomProps {
  items: WuiMenuItemProps[];
  /**
   * Callback fired when an item is clicked. Can be used to simple close the menu e.g if menu is used in a popover or similar.
   */
  onItemClicked?: (key: string) => void;
}

export const WuiMenuList: FC<WuiMenuListCustomProps & MenuListProps> = ({ items, sx, onItemClicked, ...props }) => (
  <MenuList
    sx={{
      minWidth: '220px',
      backgroundColor: 'background.paper',
      boxShadow: 1,
      ...sx,
    }}
    {...props}
  >
    {items.map(({ key, label, icon, divider, sx: itemSx, onClick, ...itemProps }, index) => (
      <WuiMenuItem
        key={key}
        label={label}
        icon={icon}
        divider={divider ?? index < items.length - 1}
        onClick={(e) => {
          onClick?.(e);
          onItemClicked?.(key);
        }}
        {...itemProps}
      />
    ))}
  </MenuList>
);
