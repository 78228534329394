export enum Units {
  PIECES = 'Pieces',
  MILLIMETRES = 'Millimeters',
  CENTIMETRES = 'Centimeters',
  METERS = 'Meters',
  SQUAREMETERS = 'Square meters',
  MILLIGRAMS = 'Milligrams',
  GRAMS = 'Grams',
  KILOGRAMS = 'Kilograms',
  TONNES = 'Tonnes',
  KGPCM = 'Kilograms per cubic meter',
  MILLILITERS = 'Milliliters',
  LITERS = 'Liters',
  CUBICMETERS = 'Cubic meters',
  PASCAL = 'Pascal',
  BAR = 'Bar',
  JOULES = 'Joules',
  KILOWATT_HOURS = 'Kilowatt-hours',
  WATTS = 'Watts',
  NEWTON_METERS = 'Newton-meters',
  AMPERE = 'Ampere',
  VOLTS = 'Volts',
  SECONDS = 'Seconds',
  MINUTES = 'Minutes',
  HOURS = 'Hours',
}
