import { GraphQLResponseBody, ListArgs } from 'services/types/GraphQL';
import { api } from 'services/networking/api';
import { argsObjectToGraphqlQuery, argsObjectToVartiableDefinition } from './argsObjectToGraphqlQuery';
import { fieldListToGraphqlQuery } from './fieldListToGraphqlQuery';

const GRAPHQL_ENDPOINT = '/graphql';

export const makeGraphqlListRequest = <ResponseData>(
  modelName: string,
  fields: string[],
  listArgs: ListArgs,
  /** Gets precise count from MongoDB instead of Approximate from ElasticSearch */
  preciseCount: boolean,
) => {
  const args: ListArgs = { ...listArgs, preciseCount };
  const query = `
    query ListQuery (${argsObjectToVartiableDefinition(modelName, args)}) {
      ${modelName}(${argsObjectToGraphqlQuery(args)}) {
          edges {
              ${fieldListToGraphqlQuery(fields)}
          }
        pageInfo {
            page
            perpage
            sort
            order
            maxpage
            totalItems
        } 
      }
    }
    `;

  return api.post<GraphQLResponseBody<ResponseData>>(GRAPHQL_ENDPOINT, { query, variables: args });
};

export const makeGraphqlGroupRequest = <ResponseData>(modelName: string, args: ListArgs) => {
  const query = `
    query ListGroupQuery (${argsObjectToVartiableDefinition(modelName, args)}) {
      ${modelName}(${argsObjectToGraphqlQuery(args)}) {
        groupedEdges {
            _id
            value
        }
      }
    }
    `;

  return api.post<GraphQLResponseBody<ResponseData>>(GRAPHQL_ENDPOINT, { query, variables: args });
};
