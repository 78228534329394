import { LoginPOSTResponse } from '@workerbase/types/api/auth';

export const mapSSOQueryParamsToUser = (query: URLSearchParams): LoginPOSTResponse => ({
  user: JSON.parse(query.get('user') ?? '{}'),
  isOnPremise: query.get('isOnPremise') === 'true',
  isWorkbenchEnabled: query.get('isWorkbenchEnabled') === 'true',
  isMyWorkEnabled: query.get('isMyWorkEnabled') === 'true',
  isConnectedWorkerEnabled: query.get('isConnectedWorkerEnabled') === 'true',
  isServerlessEnabled: query.get('isServerlessEnabled') === 'true',
  loginToken: query.get('loginToken') ?? '',
  refreshToken: query.get('refreshToken') ?? '',
  tokenTTLInMs: Number.parseInt(query.get('tokenTTLInMs') ?? '0', 10),
  isAiEnabled: Boolean(query.get('isAiEnabled')),
  isGDriveEnabled: Boolean(query.get('isGDriveEnabled')),
});
