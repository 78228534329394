/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsDefined, IsOptional, IsString, ValidateNested } from 'class-validator';
import {
  StepAction,
  StepActionBase,
  StepActionBaseSchema,
  StepActionDiscriminator,
  StepActionClassByActionType,
} from './StepAction';

@Schema({ _id: false })
export class BaseButtonStyles {
  @Prop()
  @IsString()
  backgroundColor: string;

  @Prop()
  @IsString()
  textColor: string;
}

@Schema({ _id: false })
export class BaseButton {
  @Prop()
  @IsString()
  text: string;

  @Prop({
    type: StepActionBaseSchema,
    discriminators: StepActionDiscriminator,
  })
  @ValidateNested()
  @IsDefined()
  @Type(() => StepActionBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: Object.entries(StepActionClassByActionType).map(([actionType, action]) => ({
        name: actionType,
        value: action,
      })),
    },
  })
  action: StepAction;

  @Prop({ type: SchemaFactory.createForClass(BaseButtonStyles) })
  @IsOptional()
  @ValidateNested()
  @Type(() => BaseButtonStyles)
  styles?: BaseButtonStyles;
}
