/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { BaseButton, BaseButtonStyles } from './BaseButton';
import { StepsIconTypes } from '../../WorkinstructionStep/StepsIconTypes';

@Schema({ _id: false })
export class StepButtonStyles extends BaseButtonStyles {}

@Schema({ _id: false })
export class StepButton extends BaseButton {
  @Prop({ required: true })
  @IsNumber()
  index: number;

  @Prop({
    type: String,
    enum: Object.values(StepsIconTypes),
  })
  @IsString()
  @IsOptional()
  icon?: StepsIconTypes;

  @Prop()
  @IsString()
  @IsOptional()
  id?: string;

  /**
   * @deprecated use text property instead
   */
  @Prop()
  @IsString()
  @IsOptional()
  title?: string;
}

export const StepButtonSchema = SchemaFactory.createForClass(StepButton);
