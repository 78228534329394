import { Prop, Schema } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsString, ValidateIf, ValidateNested } from 'class-validator';
import { StepAction, StepActionBase, StepActionBaseSchema } from '../../StepAction';

@Schema({ _id: false })
export class InputListBaseOption {
  @Prop()
  @IsString()
  id: string;

  @Prop()
  @IsString()
  text: string;

  @Prop({ type: StepActionBaseSchema })
  @ValidateNested()
  @Type(() => StepActionBase)
  @ValidateIf((object, value) => value !== null)
  action: StepAction | null;
}
