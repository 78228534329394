import { InitialStepObjectId } from '@workerbase/types/MongoDB/Rule';
import { TaskAnnouncement } from '@workerbase/types/Task';

export enum NotificationType {
  ONLY_AVAILABLE = 'ONLY_AVAILABLE',
  INCLUDE_BUSY_USERS_WITH_LOWER_PRIORITY_TASKS = 'INCLUDE_BUSY_USERS_WITH_LOWER_PRIORITY_TASKS',
  INCLUDE_BUSY_USERS_WITH_SAME_PRIORITY_TASKS = 'INCLUDE_BUSY_USERS_WITH_SAME_PRIORITY_TASKS',
  ALL_USERS = 'ALL_USERS',
}

export interface Task {
  headline: string;
  title: string;
  description: string;
  priority: string; // not number because it can hold a variable $variable
  type: NotificationType;
  announcement?: TaskAnnouncement;
  rejectReasons: string[];
}

export interface TaskAdvancedSettings {
  taskExternalId: string;
  announcementDelay: string | null;
  notificationForce: boolean;
  suspendTaskWhenNoUserAvailable: boolean;
  suspendTaskWhenNoUserIsAssignable: boolean;
  reannouncePeriod: string | null;
  ttl: string | null;
  autostart: boolean;
  dueInSeconds: string | null;
  initialStepObjectId: InitialStepObjectId | null;
}
