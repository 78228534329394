import { Prop, Schema } from '@nestjs/mongoose';
import { IsEnum, IsString } from 'class-validator';
import { ClassValidatorOptions } from '../../utils/ClassValidatorOptions';
import { InputBarcodeRegexType } from '../../../domain/workinstruction/step/InputBarcodeRegexType.enum';
import { InputBarcodeOperators } from '../../../domain/workinstruction/step/InputBarcodeOperators.constant';

@Schema()
export class InputBarcodeRegex {
  @Prop()
  @IsString()
  id: string;

  @Prop({
    type: String,
    enum: Object.values(InputBarcodeRegexType),
    default: InputBarcodeRegexType.INCLUDE,
  })
  @IsEnum(InputBarcodeRegexType, ClassValidatorOptions.getEnumErrorMessageOptions(InputBarcodeRegexType))
  type: InputBarcodeRegexType;

  @Prop()
  @IsString()
  value: string;

  @Prop({ enum: InputBarcodeOperators, type: String })
  @IsEnum(InputBarcodeOperators, ClassValidatorOptions.getEnumErrorMessageOptions(InputBarcodeOperators))
  operator: (typeof InputBarcodeOperators)[number];
}
