import { AnyAction } from 'redux';

import { Property, Sorting, ListConfigs } from '@workerbase/types/ListConfig';
import { SortOrder } from '@workerbase/types/global';

import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updateSorting, updateListConfig } from '../common/ListConfig/reducers';

import { DatabasesActions } from './actions';

export type DatabasesState = Readonly<{
  listConfigs: ListConfigs & {
    propertiesByDatabaseId: {
      [key: string]: Property[];
    };
  };
  currentListItemsIds: string[];
}>;

export const initialState: DatabasesState = {
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    propertiesByDatabaseId: {},
    sorting: {
      selector: 'name',
      sortDirection: SortOrder.ASC,
    },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
};

const reducer = (state: DatabasesState = initialState, action: AnyAction): DatabasesState => {
  switch (action.type) {
    case DatabasesActions.UPDATE_SORTING: {
      const payload = action.payload as Sorting;
      return updateSorting(state, payload);
    }
    case DatabasesActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
