// eslint-disable-next-line max-classes-per-file
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { Type } from 'class-transformer';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';
import { StepTypes } from '../../../domain/workinstruction/step';
import { MaterialsList } from './base';
import { InfoPhotoStyles } from './base/InfoPhotoStyles';
import { StepBase } from './base/StepBase';

@Schema({ _id: false })
export class LayoutScrewFittingBit {
  @Prop()
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;
}
@Schema()
export class LayoutScrewFitting extends StepBase {
  type: StepTypes.LAYOUT_SCREW_FITTING;

  @Prop({ required: true })
  @IsString()
  mediaId: string;

  @Prop()
  @IsBoolean()
  isVar: boolean;

  @Prop({ type: SchemaFactory.createForClass(InfoPhotoStyles) })
  @ValidateNested()
  @Type(() => InfoPhotoStyles)
  styles: InfoPhotoStyles;

  @Prop()
  @IsString()
  description: string;

  @Prop({ type: SchemaFactory.createForClass(MaterialsList) })
  @ValidateNested()
  @Type(() => MaterialsList)
  materialsList: MaterialsList;

  @Prop({ type: SchemaFactory.createForClass(LayoutScrewFittingBit) })
  @IsOptional()
  @ValidateNested()
  @Type(() => LayoutScrewFittingBit)
  bit?: LayoutScrewFittingBit;

  @Prop()
  @IsString()
  count: string; // string representation of integer number value (eg '12') or a variable name (eg. '$varName)

  // string representation of integer number value (eg '12') or a variable name (eg. '$varName)
  @Prop()
  @IsString()
  torque: string; // string representation of integer number value (eg '12') or a variable name (eg. '$varName)
}
