import React, { FC, useState } from 'react';
import { UploadFile as UploadFileIcon, Delete as DeleteIcon, Replay as ReplayIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { uploadMedia, uploadMediaFromGDrive } from 'services/networking/media';
import { formatFileSize } from 'utils/formatFileSize';
import { useApiRequest } from 'hooks/useRequest';
import { WuiFileInputValue, FileUploadStatus, WuiFileInputFile, FileOrigin, ComputerFile, GDriveFile } from '../types';

export interface WuiFileInputItemProps {
  file: WuiFileInputFile | WuiFileInputValue;
  onSuccess: (displayFile: WuiFileInputValue) => void;
  onDelete: (mediaId: string) => void;
  disableDeleteButton?: boolean;
}

const FILE_STATUS_CONFIG = {
  [FileUploadStatus.Loading]: {
    label: 'Loading',
    actionIcon: <CircularProgress size={24} />,
    color: 'primary.main',
  },
  [FileUploadStatus.Success]: {
    label: 'Success',
    actionIcon: <DeleteIcon fontSize="small" />,
    color: 'success.main',
  },
  [FileUploadStatus.Error]: {
    label: 'Error',
    actionIcon: <ReplayIcon fontSize="small" />,
    color: 'error.main',
  },
} as const;

export const WuiFileInputItem: FC<WuiFileInputItemProps> = ({
  file,
  onSuccess,
  onDelete,
  disableDeleteButton = false,
}) => {
  const [status, setStatus] = useState<FileUploadStatus>(FileUploadStatus.Success);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const uploadMediaRequest = useApiRequest(uploadMedia, {
    params: [(file as ComputerFile).file],
    ready: (file as WuiFileInputFile)?.origin === FileOrigin.COMPUTER,
    onBefore: () => {
      setStatus(FileUploadStatus.Loading);
    },
    onError: () => {
      setStatus(FileUploadStatus.Error);
    },
    onSuccess: (mediaId) => {
      setStatus(FileUploadStatus.Success);
      onSuccess({
        id: mediaId,
        name: (file as WuiFileInputFile).file.name,
        type: (file as WuiFileInputFile).file.type,
        size: (file as WuiFileInputFile).file.size,
      });
    },
  });

  const uploadMediaFromGDriveRequest = useApiRequest(uploadMediaFromGDrive, {
    params: [(file as GDriveFile).file?.id, (file as GDriveFile).accessToken],
    ready: (file as GDriveFile)?.origin === FileOrigin.GOOGLE_DRIVE,
    onBefore: () => {
      setStatus(FileUploadStatus.Loading);
    },
    onError: () => {
      setStatus(FileUploadStatus.Error);
    },
    onSuccess: (mediaId) => {
      setStatus(FileUploadStatus.Success);
      onSuccess({
        id: mediaId,
        name: (file as WuiFileInputFile).file.name,
        type: (file as WuiFileInputFile).file.type,
        size: (file as WuiFileInputFile).file.size,
      });
    },
  });

  const onActionClick = async () => {
    switch (status) {
      case FileUploadStatus.Success:
        if ('id' in file) {
          onDelete(file.id);
        }
        break;
      case FileUploadStatus.Error:
        switch ((file as WuiFileInputFile).origin) {
          case FileOrigin.COMPUTER:
            uploadMediaRequest.refresh();
            break;
          case FileOrigin.GOOGLE_DRIVE:
            uploadMediaFromGDriveRequest.refresh();
            break;
        }
        break;
      case FileUploadStatus.Loading:
      default: {
        // Do nothing
      }
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        boxSizing: 'border-box',
        width: '100%',
        height: '100px',
        px: 2,
        borderColor: 'divider',
      }}
      key={(file as WuiFileInputValue).id}
    >
      <UploadFileIcon sx={{ color: status === FileUploadStatus.Error ? 'error.main' : 'primary.main', ml: 1, mr: 2 }} />
      <Stack>
        <Typography lineHeight="21px">{(file as WuiFileInputValue).name}</Typography>
        <Stack direction="row" gap={1}>
          {(file as WuiFileInputValue).size && (
            <Typography color="grey.500" variant="body2">
              {formatFileSize((file as WuiFileInputValue).size || 0)}
            </Typography>
          )}
          <Typography color="grey.500" variant="body2">
            •
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography color="grey.500" variant="body2" mr="3px">
              {FILE_STATUS_CONFIG[status].label}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {status === FileUploadStatus.Error && (
        <IconButton
          sx={{
            ml: 'auto',
            backgroundColor: 'grey.50',
            pointerEvents: 'auto',
          }}
          onClick={onActionClick}
        >
          {FILE_STATUS_CONFIG[status].actionIcon}
        </IconButton>
      )}
      <IconButton
        sx={{
          ml: 'auto',
          backgroundColor: status === FileUploadStatus.Loading ? 'transparent' : 'grey.50',
          pointerEvents: status === FileUploadStatus.Loading ? 'none' : 'auto',
        }}
        onClick={onActionClick}
        disabled={disableDeleteButton}
      >
        {FILE_STATUS_CONFIG[status].actionIcon}
      </IconButton>
    </Stack>
  );
};
