/* eslint-disable react-hooks/exhaustive-deps */
import { NoteBody, NoteResponse } from '@workerbase/types/api/notes';
import { IConnectedTo } from '@workerbase/types/domain/common';
import { useApiRequest } from 'hooks/useRequest';
import { useState } from 'react';
import { createNote, deleteNote, getResourceNotes, updateNote } from 'services/networking/notes';

export const useNotes = (resource: IConnectedTo) => {
  const [notes, setNotes] = useState<NoteResponse[]>();
  const getNotesRequest = useApiRequest(getResourceNotes, {
    manual: true,
    onSuccess: (responseNotes) => {
      setNotes(responseNotes);
    },
  });

  const createNoteRequest = useApiRequest(createNote, {
    manual: true,
    onSuccess: () => {
      getNotesRequest.run(resource);
    },
  });

  const updateNoteRequest = useApiRequest(updateNote, {
    manual: true,
    onSuccess: () => {
      getNotesRequest.run(resource);
    },
  });

  const deleteNoteRequest = useApiRequest(deleteNote, {
    manual: true,
    onSuccess: () => {
      getNotesRequest.run(resource);
    },
  });

  return {
    notes,
    setNotes,
    getNotes: () => getNotesRequest.run(resource),
    createNote: (data: NoteBody) => {
      setNotes(undefined);
      createNoteRequest.run(data);
    },
    updateNote: (noteId: string, data: NoteBody) => {
      setNotes(undefined);
      updateNoteRequest.run(noteId, data);
    },
    deleteNote: (noteId: string) => {
      setNotes(undefined);
      deleteNoteRequest.run(noteId);
    },
  };
};
