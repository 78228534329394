import _, { isNil } from 'lodash';

import { WorkinstructionOld } from '@workerbase/types/Workinstruction';
import { WorkinstructionStepsMapper } from '@workerbase/utils/WorkinstructionStepsMapper';
import { WorkinstructionGET } from '@workerbase/types/api/workinstructions';

import { normalizeUser } from 'services/normalizers/users';
import { TaskGET, Task, TaskField } from '../types/Task';
import { normalizeWorkinstruction } from './workinstruction';

export function normalizeTask(task: TaskGET, additionalPropertiesPaths?: string[]): Task;
export function normalizeTask(
  task: Partial<TaskGET> & { _id: string },
  additionalPropertiesPaths?: string[],
): Partial<Task> & { id: string };
export function normalizeTask(task: Partial<TaskGET>, additionalPropertiesPaths?: string[]): Partial<Task> {
  const normalizedTask = {
    id: task._id,
    createdAt: task.meta?.createdAt,
    updatedAt: task.meta?.updatedAt,
    projectId: task.project,
    priority: task.priority,
    status: task.status,
    ruleId: task.ruleId,
    delegationLevel: task.delegationLevel,
    title: task.title,
    userId: typeof task.user === 'string' ? task.user : task.user?._id,
    user: typeof task.user === 'string' || isNil(task.user) ? undefined : normalizeUser(task.user),
    description: task.description,
    done: task.done,
    headline: task.headline,
    // We are not using the payload in the app
    payload: {
      workinstruction: task.payload?.workinstructions
        ? normalizeWorkinstruction(
            WorkinstructionStepsMapper.mapToArray(
              _.cloneDeep(task.payload.workinstructions) as unknown as WorkinstructionOld,
            ) as unknown as WorkinstructionGET,
          )
        : undefined,
    },
    additionalProperties: {},
  };

  if (additionalPropertiesPaths) {
    additionalPropertiesPaths.forEach((path) => {
      const additionalPropertyValue = _.get(task, path.split('.'));
      normalizedTask.additionalProperties[path] =
        additionalPropertyValue || additionalPropertyValue === 0 ? additionalPropertyValue : 'N/A';
    });
  }

  return normalizedTask;
}

// TODO: set return type
export function normalizeTaskFields(taskFields: TaskField[]) {
  return taskFields.map(({ name, path, type, sortable }) => ({
    name,
    type,
    sortable,
    selector: path || name,
  }));
}
