import { Prop, Schema } from '@nestjs/mongoose';
import { IsBoolean, IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator';
import mongoose from 'mongoose';
import { ModelNames } from '../../../ModelNames';
import { ClassValidatorOptions } from '../../../utils/ClassValidatorOptions';
import { ListOptionsSource } from '../../../../domain/workinstruction/step/ListOptionsSource.enum';
import { InputListBaseOption } from './InputListBaseOption';

export type ListOptions<T extends InputListBaseOption> = T[] | string /* variable name */;

@Schema()
export class OptionsList<T extends InputListBaseOption> {
  @Prop({
    type: String,
    enum: Object.values(ListOptionsSource),
    default: ListOptionsSource.OPTIONS,
  })
  @IsEnum(ListOptionsSource, ClassValidatorOptions.getEnumErrorMessageOptions(ListOptionsSource))
  source: ListOptionsSource;

  @Prop({ type: mongoose.Schema.Types.Mixed })
  @ValidateNested()
  options?: ListOptions<T>;

  @Prop({
    type: mongoose.Schema.Types.ObjectId,
    ref: ModelNames.DatabaseViewMapping,
  })
  @IsString()
  @IsOptional() // used only if source === ListOptionsSource.DATABASE
  mapping?: string;

  /** @deprecated - use source as a discriminator key */
  @Prop()
  @IsBoolean()
  isVar: boolean;

  /** @deprecated - use source as a discriminator key */
  @Prop()
  @IsBoolean()
  loadFromUrl: boolean;

  @Prop({ type: String })
  @IsString()
  @IsOptional()
  loadOptionsFromUrl?: string;
}
